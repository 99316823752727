import React from 'react';
import DatePicker from 'react-datepicker';

export function ScheduleInspection(
  scheduleBooking: (e: React.FormEvent<HTMLFormElement>) => void,
  fromDate: any,
  setFromDate: React.Dispatch<any>,
  datePickerMinDate: Date,
  toDate: any,
  setToDate: React.Dispatch<any>,
  acceptLoading: boolean,
  acceptBooking: () => void
): React.ReactNode {
  return (
    <>
      <form className='mt-5' onSubmit={scheduleBooking}>
        <p className='fs-14'>Please select a preffered date for inspection</p>

        <div className='form-group mb-3'>
          <label className='form-label fs-14'>Option 1</label>
          <div className='input-group'>
            <span className='input-group-text input-group-text-0'>
              <i className={`iconly-Calendar icli fs-4`}></i>
            </span>

            <div className='form-control form-control-0   border-start-0'>
              <DatePicker
                selected={fromDate}
                onChange={(date: any) => setFromDate(date)}
                placeholderText='dd-mm-yyyy'
                minDate={datePickerMinDate}
                className='form-control form-control-0  border-0'
                showYearDropdown
                showMonthDropdown
                showTimeSelect
                dateFormat='dd-MM-yyyy'
              />
            </div>
          </div>
        </div>

        <div className='form-group mb-3'>
          <label className='form-label fs-14'>Option 2</label>
          <div className='input-group'>
            <span className='input-group-text input-group-text-0'>
              <i className={`iconly-Calendar icli fs-4`}></i>
            </span>

            <div className='form-control form-control-0   border-start-0'>
              <DatePicker
                selected={toDate}
                onChange={(date: any) => setToDate(date)}
                placeholderText='dd-mm-yyyy'
                minDate={datePickerMinDate}
                className='form-control form-control-0  border-0'
                showYearDropdown
                showMonthDropdown
                showTimeSelect
                dateFormat='dd-MM-yyyy'
              />
            </div>
          </div>
        </div>

        <div className='d-grid'>
          <button
            className='btn btn-primary rounded-pill px-4 py-3'
            disabled={acceptLoading}
          >
            {acceptLoading ? (
              <span className='spinner-border spinner-border-sm'></span>
            ) : (
              'Confirm'
            )}
          </button>
        </div>
      </form>
      <div className='d-grid mt-3'>
        <button
          className='btn btn-secondary rounded-pill px-4 py-3'
          disabled={acceptLoading}
          onClick={acceptBooking}
        >
          {acceptLoading ? (
            <span className='spinner-border spinner-border-sm'></span>
          ) : (
            'Skip Inspection'
          )}
        </button>
      </div>
    </>
  );
}
