import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
// import {FaWhatsapp} from 'react-icons/fa';

const WhatsappIcon = () => {
  const [shake, setShake] = useState(false);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/2349118008008', '_blank');
  };

  useEffect(() => {
    const shakeTimer = setTimeout(() => {
      setShake(true);

      const removeShake = setTimeout(() => setShake(false), 1000);
      return () => clearTimeout(removeShake);
    }, 5000);

    return () => clearTimeout(shakeTimer);
  }, []);
  return (
    <Button
      onClick={handleWhatsAppClick}
      variant='success'
      className={shake ? 'shake-animation ' : ''}
      style={{
        position: 'fixed',
        bottom: '180px',
        height: '60px',
        width: '60px',
        right: '40px',
        borderRadius: '50%',
        padding: '15px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: '20000',
      }}
    >
      {/* <FaWhatsapp size={24} color='white' /> */}
      <i className='fa-brands fa-whatsapp fs-28 text-white'></i>
    </Button>
  );
};

export default WhatsappIcon;
