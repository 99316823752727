import {useEffect, useState} from 'react';
import {useSearchParams, useNavigate, useLocation} from 'react-router-dom';
import {OnboardingLayout} from '@layouts/OnboardingLayout';
// import circle_check_img from '../../assets/images/green_check.svg';
import {toast} from 'react-toastify';

import {reActivateAccount, verifyEmail} from '@services/krent.service';
import {addCookie} from '@utils/cookies';
import Logo from '@shared/Logo';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {PrimaryButton} from '@shared/buttons';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [activationLoading, setActivationLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const id = searchParams.get('id');
  useEffect(() => {
    if (!id) {
      navigate('/', {replace: true, state: {from: location}});
    }

    setLoading(true);
    verifyEmail(id)
      .then((res) => {
        // console.log(res,'from use');
        // console.log(res.data,'from use');
        if (res?.success) {
          addCookie('1Q_SPA', res['token'], 24000);
          if (res?.role === 'user') {
            navigate('/explore-listings', {
              replace: true,
              state: {from: location, verified: true},
            });
          }
          if (res?.role === 'agent' || res?.role === 'landlord') {
            navigate('/overview', {
              replace: true,
              state: {from: location, verified: true},
            });
          }
        } else {
          // console.log(res, 'from else');
          setError(true);
          toast.error('Verification failed,Kindly try again');
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setErrorMessage(err);
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location, navigate, searchParams]);

  const handleActivation = () => {
    setActivationLoading(true);
    reActivateAccount(id)
      .then((res) => {
        console.log(res);
        // console.log('Success hyy');
        toast.success('Activation link sent to email');
      })
      .catch((err) => {
        console.log(err);
        // console.log('Failure hyy');
        toast.error(err);
        setErrorMessage(err);
        // toast.error('Error occured,kindly try again');
      })
      .finally(() => {
        setActivationLoading(false);
      });
  };

  return (
    <OnboardingLayout>
      <div className='container'>
        <div className='col'>
          <h3
            className='text-md-start fw-bolder mb-5'
            onClick={() => navigate('/')}
          >
            <Logo />
          </h3>
          <div className='col'>
            {loading ? (
              <p>Loading...</p>
            ) : (
              error && (
                // <div>
                //   <p>Verification Successful</p>
                //   <img src={circle_check_img} alt='circle_check' />
                //   <p className='text-success'></p>
                // </div>
                <>
                  <div className='d-flex align-items-center gap-4 mb-5'>
                    <p>Verification Failed</p>
                    <p> {errorMessage} </p>
                    <i className='fa-light fa-circle-exclamation text-danger fa-3x'></i>
                    <ToastContainer limit={1} />
                  </div>

                  <PrimaryButton
                    click={() => handleActivation()}
                    type='primary'
                    text='Resend Activation'
                    loading={activationLoading}
                  />
                  <PrimaryButton
                    click={() => navigate('/')}
                    type='primary'
                    text='Back Home'
                    loading={activationLoading}
                  />
                </>
              )
            )}
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};
