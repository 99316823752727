import {v4 as uuidv4} from 'uuid';

export const saleFaqData = (
  total: string = '0',
  expensive: string = '0',
  cheap: string = '0',
  average: string = '0',
  type: any,
  kind: string,
  location: string
) => {
  const averageString = average;
  const averageToNum = parseFloat(averageString.replace(/,/g, ''));
  const newAverage = averageToNum.toFixed(2);
  let formattedAverage = parseFloat(newAverage).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (type === 'shortlet') {
    return [
      {
        question: `What is the average price of  ${type} ${
          kind ? kind : 'property'
        }  ${location ? 'in' : ''} ${location ? `${location}?` : ''} `,
        answer: `The average price of ${type} ${kind ? kind : 'property'}   ${
          location ? 'in' : ''
        } ${location ? location : ''} is ₦${formattedAverage}.`,
        id: uuidv4(),
      },

      {
        question: `What is the price of the most expensive ${type} ${
          kind ? kind : 'property'
        }   ${location ? 'in' : ''} ${location ? location : ''} ?`,
        answer: `The price of the most expensive  ${type} ${
          kind ? kind : 'property'
        }   ${location ? 'in' : ''} ${
          location ? location : ''
        }  is ₦${expensive}.`,
        id: uuidv4(),
      },

      {
        question: `What is the price of the cheapest  ${type} ${
          kind ? kind : 'property'
        }  ${location ? 'in' : ''} ${location ? location : ''} ?`,
        answer: `The price of the cheapest ${type} ${
          kind ? kind : 'property'
        }   ${location ? 'in' : ''} ${location ? location : ''}  is ₦${cheap}.`,
        id: uuidv4(),
      },
      {
        question: `How many ${type} ${kind ? kind : 'property'}   ${
          location ? 'in' : ''
        } ${location ? location : ''} are available ?`,
        answer: `There are ${total} available  ${type} ${
          kind ? kind : 'property'
        }${kind && parseInt(total) > 1 ? 's' : ''}   ${location ? 'in' : ''} ${
          location ? location : ''
        } `,
        id: uuidv4(),
      },
    ];
  }

  if (kind === 'commercial') {
    return [
      {
        question: `What is the average price of commercial property for ${type}`,
        answer: `The average price of  ${kind} property for  ${type}  ${
          location ? 'in' : ''
        } ${location ? location : ''} is ₦${formattedAverage}.`,
        id: uuidv4(),
      },

      {
        question: `What is the price of the most expensive ${kind} property for ${type}    ${
          location ? 'in' : ''
        } ${location ? location : ''} ?`,
        answer: `The price of the most expensive  ${kind} property for  ${type}   ${
          location ? 'in' : ''
        } ${location ? location : ''}  is ₦${expensive}.`,
        id: uuidv4(),
      },

      {
        question: `What is the price of the cheapest  ${kind} property for ${type}   ${
          location ? 'in' : ''
        } ${location ? location : ''} ?`,
        answer: `The price of the cheapest  ${kind} property for ${type}   ${
          location ? 'in' : ''
        } ${location ? location : ''}  is ₦${cheap}.`,
        id: uuidv4(),
      },
      {
        question: `How many  ${kind} property for ${type}   ${
          location ? 'in' : ''
        } ${location ? location : ''} are available ?`,
        answer: `There are ${total} available   ${kind} property for ${type}  ${
          location ? 'in' : ''
        } ${location ? location : ''} `,
        id: uuidv4(),
      },
    ];
  }

  return [
    {
      question: `What is the average price of ${
        kind ? kind : 'property'
      } for ${type} ${location ? 'in' : ''} ${location ? `${location}?` : ''} `,
      answer: `The average price of ${kind ? kind : 'property'} for ${type}  ${
        location ? 'in' : ''
      } ${location ? location : ''} is ₦${formattedAverage}.`,
      id: uuidv4(),
    },

    {
      question: `What is the price of the most expensive ${
        kind ? kind : 'property'
      } for ${type}  ${location ? 'in' : ''} ${location ? location : ''} ?`,
      answer: `The price of the most expensive ${
        kind ? kind : 'property'
      } for ${type}  ${location ? 'in' : ''} ${
        location ? location : ''
      }  is ₦${expensive}.`,
      id: uuidv4(),
    },

    {
      question: `What is the price of the cheapest ${
        kind ? kind : 'property'
      } for ${type} ${location ? 'in' : ''} ${location ? location : ''} ?`,
      answer: `The price of the cheapest ${
        kind ? kind : 'property'
      } For ${type}  ${location ? 'in' : ''} ${
        location ? location : ''
      }  is ₦${cheap}.`,
      id: uuidv4(),
    },
    {
      question: `How many ${kind ? kind : 'property'} for ${type}  ${
        location ? 'in' : ''
      } ${location ? location : ''} are available ?`,
      answer: `There are ${total} available ${kind ? kind : 'property'}${
        kind && parseInt(total) > 1 ? 's' : ''
      } for ${type}  ${location ? 'in' : ''} ${location ? location : ''} `,
      id: uuidv4(),
    },
  ];
};
