import React from 'react';
// import logow from '../../assets/images/wkrent.png';
import logow from '../../assets/images/klogofull.png';
// import logow from '../../assets/images/kchrisw.png';
import logob from '../../assets/images/knewlogo.png';
// import logob from '../../assets/images/Klogob.png';
// import logob from '../../assets/images/kchris.png';

const Logo = ({png}: any) => {
  return (
    <span
      style={{width: '100px', display: 'inline-flex', alignItems: 'center'}}
    >
      <img
        src={png ? logow : logob}
        alt='Krent'
        style={{height: '100%', width: '100%', objectFit: 'cover'}}
        title='Krent'
      />
    </span>
  );
};

export default Logo;
