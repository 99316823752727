import {Accordion} from 'react-bootstrap';
import {saleFaqData} from './FaqData';
import {getAverage, getCheapest, getMostExpensive} from './helper';

const Faq = ({total, properties, type, kind, location}: any) => {
  const expensive = getMostExpensive(properties);
  const cheapest = getCheapest(properties);
  const averagePrice = getAverage(properties);

  const data = saleFaqData(
    total,
    expensive,
    cheapest,
    averagePrice,
    type,
    kind,
    location
  );

  return (
    <section className='  mb-5'>
      <div className='container'>
        <div className='mb-2'>
          <h4 className='fw-bold '>
            <span>Frequently </span> Asked Questions
          </h4>
        </div>

        <div className='row'>
          <div className=' col-md-8'>
            <Accordion defaultActiveKey={data[0].id}>
              {data.map((i: any, ind: number) => {
                return (
                  <Accordion.Item eventKey={i.id} key={ind}>
                    <Accordion.Header className='fw-bold text-primary'>
                      {i.question}
                    </Accordion.Header>
                    <Accordion.Body>{i.answer}</Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
