import {PaymentMethod, WalletResponse} from '@interfaces/wallet.interface';
import {getWallet, requestDeposit} from '@services/krent.service';

// import {Modal} from '@shared/controls/Modal';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

export type Bank = {
  value: string;
  label: string;
};

type Prop = {
  walletId: string;
  transactionId: string;
};

export const RequestDeposit = ({walletId, transactionId}: Prop) => {
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState<PaymentMethod | null>(null);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  // fetch wallet function
  const fetchWallet = () => {
    setLoading(true);

    let abortController = new AbortController();
    if (walletId) {
      getWallet(walletId, abortController.signal)
        .then((res: WalletResponse) => {
          setWallet(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  // fetch wallet function
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    let abortController = new AbortController();

    requestDeposit(
      {id: walletId, data: {transactionId, pin}},
      abortController.signal
    )
      .then((res: any) => {
        setSuccess(res.message);
        // setWallet(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.errors[0].message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 6000);
    }
    if (success) {
      setTimeout(() => {
        setSuccess('');
      }, 6000);
    }
  }, [error, success]);

  useEffect(() => {
    fetchWallet();
  }, [walletId]);

  return (
    <>
      <div className='container mt-3 pb-5'>
        <h4 className=''> Account Details</h4>
        <p className='mb-4'>
          Input your transaction pin to complete this process.Dont have a
          transaction Pin? set it <Link to='/settings'>here</Link>
        </p>
        {wallet?.paymentMethod ? (
          <div>
            <div className='d-md-flex gap-4'>
              <div>
                <h6>Account Name</h6>
                <p>{wallet?.paymentMethod?.accountName}</p>
              </div>
              <div>
                <h6>Account Number</h6>
                <p>{wallet?.paymentMethod?.accountNumber}</p>
              </div>
            </div>

            <div className='d-md-flex gap-4 mt-4'>
              <div>
                <h6>Account Type</h6>
                <p>{wallet?.paymentMethod?.accountType}</p>
              </div>
              <div>
                <h6>Bank</h6>
                <p>{wallet?.paymentMethod?.bankName}</p>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='mb-3 mt-3'>
                <label htmlFor='pin' className='form-label'>
                  Transaction Pin
                </label>
                <input
                  type='password'
                  className='form-control'
                  id='pin'
                  value={pin}
                  onChange={(e: any) => setPin(e.target.value)}
                  required
                />
                <p className='fs-12 text-danger'>{error}</p>
                <p className='fs-12 text-success'>{success && success}</p>
              </div>

              <button type='submit' className='btn btn-primary'>
                {loading ? (
                  <span className='spinner-border spinner-border-sm'></span>
                ) : (
                  'Request Deposit'
                )}
              </button>
            </form>
          </div>
        ) : (
          <p>
            You are yet to link your bank account with your wallet. link your
            account <Link to='/wallet'>Here</Link>
          </p>
        )}
      </div>
    </>
  );
};
