import {useSessionStorage} from '../../../hooks/';
import {Link, NavLink} from 'react-router-dom';
// import logo from '../../../assets/images/logo.png';
import Logo from '@shared/Logo';
import image from '../../../assets/images/profile-pic.png';
import {removeCookie} from '@utils/cookies';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  getUnreadUserNotifications,
  getUserNotifications,
  logout,
  markReadNotification,
} from '@services/krent.service';
import {Fragment, useEffect, useState} from 'react';
import {ROLES} from '@utils/constants';
import NotificationBellDropdown from 'components/Notification';
import {NotificationsInterfaceResponse} from '@interfaces/notifications.interface';
import {BaseApiResponse} from '@interfaces/app.interface';

export const DashboardSideBar = () => {
  const [user, setUser] = useSessionStorage('user', {});
  const [notifications, setNotifications] = useState<
    NotificationsInterfaceResponse[]
  >([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleGetNotifications = () => {
    let abortController = new AbortController();

    getUserNotifications(user?._id, abortController.signal)
      .then((res: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
        setNotifications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getUnreadUserNotifications(user?._id, abortController.signal)
      .then((res: any) => {
        setNotificationsCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMarkReadNotification = (id: string) => {
    let abortController = new AbortController();
    markReadNotification(id, abortController.signal)
      .then(() => {
        getUserNotifications(user?._id, abortController.signal)
          .then((res: BaseApiResponse<NotificationsInterfaceResponse[]>) => {
            setNotifications(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        if (user.role === 'agent' || user.role === 'landlord') {
          navigate('/requests');
        }
        getUnreadUserNotifications(user?._id, abortController.signal)
          .then((res: any) => {
            setNotificationsCount(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = () => {
    let abortController = new AbortController();
    logout(abortController.signal)
      .then(() => {
        setUser({});
        window && window.sessionStorage.clear();
        removeCookie('1Q_SPA');
        navigate('/', {replace: true, state: {from: location}});
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    handleGetNotifications();
  }, [user]);

  return (
    <div className='d-flex flex-column flex-shrink-0 '>
      {/* <div className='position-relative '> */}
      <div className=' px-2 pt-3 pt-md-5 mb-auto"'>
        {/* <div className=' px-2 py-3 py-md-5 mb-md-5'> */}
        <div className='row d-flex align-items-center mb-3'>
          <div className='d-flex flex-row justify-content-between align-items-center fs-24 px-4 w-100'>
            {/* Logo and Notification Bell */}
            <div className='d-flex align-items-center justify-content-between col-lg-12'>
              <Link className='navbar-brand fw-bolder align-middle' to='/'>
                <Logo png two />
              </Link>
              <div className='d-flex align-items-center ml-3'>
                <NotificationBellDropdown
                  count={notificationsCount}
                  notifications={notifications}
                  markRead={handleMarkReadNotification}
                />
              </div>
            </div>
            {/* Hamburger Menu - Hidden on Desktop */}
            <div className='col-6 d-flex text-end justify-content-end d-lg-none'>
              <button
                className='navbar-toggler border-0 cursor-pointer'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarNavAltMarku'
                aria-controls='navbarNavAltMarku'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='fa fa-bars fs-2 mr-3'></span>
              </button>
            </div>
          </div>
        </div>

        {/* <div className=' d-md-block'> */}
        {/* <div
          className={`collapse d-md-block justify-content-end fs-14 fw-600`}
          id='navbarNavAltMarku'
        > */}
        <div
          className={`collapse d-lg-block justify-content-end fs-14 fw-600`}
          id='navbarNavAltMarku'
        >
          <div className='container pe-1'>
            <div className='d-flex d-md-block d-lg-flex gap-2 px-3 py-3 bg-secondary border-0 rounded-4 justify-content-center'>
              <div className='align-self-center'>
                <img
                  src={user?.photo === 'default.jpg' ? image : user?.photo}
                  alt='Avatar'
                  style={{objectFit: 'cover'}}
                  height={55}
                  width={55}
                  className='rounded-circle'
                />
              </div>
              <div className='align-self-center'>
                <p className='fs-14 mb-0 lh-1 text-capitalize'>
                  {user?.name ||
                    user?.prefferedName ||
                    `${user?.firstname} ${user?.lastname}`}
                </p>
                {/* <span className='fs-12'>{user?.email}</span> */}
              </div>
            </div>
          </div>

          <div className='row mt-2 p-3'>
            {user?.role === ROLES.LANDLORD || user?.role === ROLES.AGENT ? (
              <Fragment>
                <NavLink
                  to='/overview/'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle '
                >
                  <i className='iconly-Home icli mr-1 ml-1 fs-22'></i>
                  <span>Overview</span>
                </NavLink>

                <h6 className='fs-12 text-secondary my-3'>
                  PROPERTY MANAGEMENT
                </h6>

                <NavLink
                  to='/wallet'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Wallet icli mr-1 ml-1 fs-22'></i>
                  <span>Wallet</span>
                </NavLink>
                <NavLink
                  to='/listings'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Paper icli mr-1 ml-1 fs-22'></i>
                  <span>Listings</span>
                </NavLink>
                <NavLink
                  to='/requests'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Star icli mr-1 ml-1 fs-22'></i>Requests
                </NavLink>
                <NavLink
                  to='/messaging'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Message icli mr-1 ml-1 fs-22'></i>
                  Messaging
                </NavLink>
              </Fragment>
            ) : (
              <Fragment>
                <NavLink
                  to='/explore-listings/'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle'
                >
                  <i className='iconly-Home icli mr-1 ml-1 fs-22'></i>
                  <span>Explore</span>
                </NavLink>

                <h6 className='fs-12 text-secondary my-3'>
                  PROPERTY MANAGEMENT
                </h6>
                {/* <NavLink
                  to='/my-properties/'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Paper icli mr-1 ml-1 fs-22'></i>
                  <span>My Properties</span>
                </NavLink> */}
                <NavLink
                  to='/my-bookings'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Star icli mr-1 ml-1 fs-22'></i>
                  <span>My Bookings</span>
                </NavLink>
                {/* <NavLink
                  to='/invoice'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Star icli mr-1 ml-1 fs-22'></i>
                  <span>Invoice &amp; Payments</span>
                </NavLink> */}
                <NavLink
                  to='/messaging/'
                  className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
                >
                  <i className='iconly-Message icli mr-1 ml-1 fs-22'></i>
                  <span>Messaging</span>
                </NavLink>
              </Fragment>
            )}

            <h6 className='fs-12 text-secondary my-3'>ACCOUNT MANAGEMENT</h6>
            {user.role === 'user' && (
              <NavLink
                to='/wallet'
                className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
              >
                <i className='iconly-Wallet icli mr-1 ml-1 fs-22'></i>
                <span>Wallet</span>
              </NavLink>
            )}
            <NavLink
              to='/profile'
              className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
            >
              <i className='fal fa-user mr-1 ml-1 fs-18'></i>My Profile
            </NavLink>
            <NavLink
              to='/settings'
              className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2'
            >
              <i className='iconly-Setting icli mr-1 ml-1 fs-22'></i>Settings
            </NavLink>
            {/* <span
              onClick={handleLogout}
              // position-absolute bottom-0
              className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2 cursor-pointer d-md-none'
            > */}
            {/* <span
              onClick={handleLogout}
              // position-absolute bottom-0
              className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2 cursor-pointer d-md-none'
            > */}
            <span
              onClick={handleLogout}
              // position-absolute bottom-0
              className='sidebar-link text-white px-3 border-round d-flex align-middle mb-2 cursor-pointer d-lg-none'
            >
              <i className='iconly-Login icli mr-1 ml-1 fs-22 '></i>
              Log Out
            </span>
          </div>
        </div>
      </div>
      {/* <button
        onClick={handleLogout}
        // position-absolute bottom-0
        className='w-100 d-none d-md-flex py-md-4 px-md-5 fs-18 d-flex flex-row justify-content-start align-items-center gap-2 bg-secondary  text-white position-absolute bottom-0'
      > */}
      <div className=' d-flex flex-row justify-content-between align-items-center position-fixed bottom-0 bg-secondary col-12 col-lg-3 col-xl-2'>
        {/* <div className=' d-flex flex-row justify-content-between align-items-center position-fixed bottom-0 bg-secondary col-12 col-lg-3 col-xl-2'> */}
        <button
          onClick={handleLogout}
          className='py-md-4 px-md-5 fs-18 d-flex flex-row justify-content-start align-items-center gap-2 bg-secondary text-white'
        >
          <i className='iconly-Login icli fs-22'></i>
          <span className='fs-14'>Log Out</span>
        </button>
      </div>
    </div>
  );
};
