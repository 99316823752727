import { useEffect, useState } from 'react';

import { DashboardLayout } from '@layouts/DashboardLayout';
// import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestViewCard } from '@shared/cards';
import {
  // acceptTransaction,
  getPropertyTransactions,
} from '@services/krent.service';
import ktoast from '@services/toast.service';
import { useSessionStorage } from '../../hooks';

const AcceptTransaction = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any>();
  const [user] = useSessionStorage('user', {});
  // const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/messaging');

    const abortController = new AbortController();
    setLoading(true);
    getPropertyTransactions(user?.id, abortController.signal)
      .then((resp: any) => {
        setTransactions(resp.data);
      })
      .catch(() => {
        ktoast.error('Failed to load Transasctions');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, id]);

  // const acceptRequest = () => {
  //   acceptTransaction(id!)
  //     .then((resp: any) => {
  //       console.log(resp.data);

  //       ktoast.success('Transasctions Accepted');
  //     })
  //     .catch((err) => {
  //       console.log(err);

  //       ktoast.error('Failed');
  //     });
  // };

  if (loading) return <h3>Loading...</h3>;
  const req = transactions?.filter((transaction: any) => transaction.id === id);

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Request</h3>
        <p className='text-secondary fs-14'>
          Respond to a request on your property
        </p>
      </div>
      <div>{req && <RequestViewCard req={req[0]} />}</div>
    </DashboardLayout>
  );
};

export default AcceptTransaction;
