import {useState} from 'react';
import img from '../assets/images/businessWoman.png';
import {addToNewsletter} from '@services/zenter.service';
const NewsLetter = () => {
  const [email, setEmail] = useState('');

  // const handleSubmit = (e: any) => {
  //   e.preventDafault();
  //   addToNewsletter(email);
  // };

  return (
    <div
      className=' d-flex mt-md-5 align-items-center gap-3 justify-content-center '
      style={{maxHeight: '306px'}}
    >
      <div className='d-flex flex-column '>
        <div>
          <h4 className='fs-28 fw-bold font-display text-white'>
            Stay up-to-date on everything Krent
          </h4>
          <p className='fs-12 font-onest text-white text-start'>
            Be the first to get the latest updates from krent
          </p>
        </div>
        <div className=' col-10 mt-4'>
          <form>
            {/* <form onSubmit={handleSubmit}> */}
            <div className='input-group input-group-flush input-group-merge '>
              <input
                className='form-control list-search border-1 bg-transparent'
                type='email'
                name='email'
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                placeholder='Enter email address'
              />

              <button
                type='button'
                className='btn bg-white fs-12 rounded-right-pill'
                onClick={(e) => {
                  e.preventDefault();
                  addToNewsletter(email);
                  setEmail('');
                }}
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        className='d-none d-md-inline-block'
        style={{marginTop: '-155px', height: '460px'}}
      >
        <img src={img} alt='' style={{height: '460px'}} />
      </div>
    </div>
  );
};
export default NewsLetter;
