// import {Link} from 'react-router-dom';
import {Link} from 'react-router-dom';
import img from '../assets/about.jpg';
const AboutHome = () => {
  return (
    <div className='container'>
      <div className='container d-flex flex-column justify-content-center gap-3 gap-md-5  '>
        <div className='container d-flex flex-column flex-md-row gap-3 gap-md-5  justify-content-center'>
          <div className='col-12 col-md-6'>
            <img src={img} alt='about krent' className='w-100' />
            <div className='d-flex justify-content-center align-items-center col-12 col-md-10  '></div>
          </div>
          <div className='col-12 col-md-6'>
            <h4 className=' col-12 col-md-8 fs-20 lh-28'>
              With Krent, real estate is made easy, safe and efficient.
            </h4>
            <p
              className='text-light-black  text-start col-12'
              style={{maxWidth: '500px'}}
            >
              Krent is a real estate and property listing website that offers a
              one-stop platform for buyers, renters, and sellers in Nigeria. We
              simplify the search experience for Nigeria property seekers by
              providing them access to a wide selection of properties, including
              flats, apartments, houses, and more, all tailored to meet their
              needs. Krent allows you to browse through verified properties,
              book your dream short-let, connect with trusted Nigerian property
              owners and agents, ensuring smooth and secure property
              transactions. For sellers, our platform serves as a marketplace,
              helping you showcase your properties to a wide audience of
              potential buyers. Whether you're interested in real estate in
              Nigeria or seeking a reputable real estate company in Lagos, Krent
              has you covered.
            </p>
            <div className='d-none d-md-flex col-12 gap-3 mt-3'>
              <div className=' p-3 border  shadow col-12 col-md-5 rounded'>
                <p className='fw-bold text-primary'>
                  Innovation and Excellence
                </p>
                <p className='fs-14'>
                  Leading the way with cutting-edge technology and real estate
                  expertise.
                </p>
              </div>
              <div className=' p-3 border shadow col-12 col-md-5 rounded'>
                <p className='fw-bold text-primary'>
                  Seamless Property Transactions
                </p>
                <p className='fs-14'>
                  Experience a smooth journey from renting to buying, guided by
                  our advanced platform.
                </p>
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-center col-12 col-md-10'>
              <Link to='/about' className='btn btn-primary mt-4 mt-md-5 '>
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutHome;
