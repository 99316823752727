import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

type Props = {
  id: string;
  property: string;
  cost: number;
  duration: string;
  listed: Date;
  intent?: string;
  status: string;
  stay?: number;
  propertyType?: string;
  setModalType?: (type: string) => void;
  // setId: (type: string) => void;
  // setShowModal: () => void;
  // loadRequest?: () => void;
  from?: string;
  cardType: 'listing' | 'request';
};

export const RequestCard = ({
  id,
  property,
  cost,
  // duration,
  listed,
  status,
  stay,
  from,
  intent,
  // setModalType,
  // setId,
  // setShowModal,
  // loadRequest,
  cardType,
}: Props) => {
  const navigate = useNavigate();

  return (
    <tr
      className='table-group-divider align-middle'
      style={{borderTopColor: '#8F8FB147'}}
    >
      <td className='fw-400 p-3 text-wrap' style={{maxWidth: 250}}>
        {property}
      </td>
      <td className='fw-400 p-3'>
        <span className='fw-bold'>NGN</span> {cost?.toLocaleString()}{' '}
      </td>
      <td className='fw-400 p-3 text-capitalize'>{from}</td>
      <td className='fw-400 p-3'>
        {new Date(listed?.toLocaleString()).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </td>
      <td className='fw-400 py-3'>
        <div className='d-flex justify-content-center align-items-center gap-2 rounded-pill border border-2 py-2 px-0 fs-12'>
          <i
            className={`fas fa-circle ${
              status === 'completed'
                ? 'text-success'
                : status === 'cancelled'
                ? 'text-danger'
                : 'text-warning'
            }`}
          />
          <span className='m-0 text-capitalize'>{status?.toLowerCase()}</span>
        </div>
      </td>
      {cardType === 'request' && stay && intent !== 'sale' && (
        <td className='fw-400 p-3'>
          {stay}
          {`${stay! > 1 ? ' Nights' : ' Night'}`}
        </td>
      )}
      <td className='fw-400 p-3'>
        {/* <div className='dropdown'>
          <i
            className='fas fa-ellipsis-vertical fs-18'
            data-bs-toggle='dropdown'
            aria-expanded='false'
            style={{cursor: 'pointer'}}
          />
          <ul className='dropdown-menu fs-14 border-round px-3'>
            <li>
              <a className='dropdown-item' href='#!' onClick={loadRequest}>
                View
              </a>
            </li>
          </ul>
        </div> */}
        <span
          className='text-primary fw-bold cursor-pointer'
          onClick={() => navigate(`/message/${id}`)}
        >
          View
        </span>
      </td>
    </tr>
  );
};

export const RequestCardMobile = ({
  id,
  property,
  cost,
  // duration,
  listed,
  status,
  stay,
  intent,
  from,
  propertyType,
}: // loadRequest,
// setModalType,
// setId,
// setShowModal,
// cardType,
Props) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const navigate = useNavigate();
  return (
    <>
      <div
        className='card border border-0 border-round p-4 my-4'
        style={{
          color: '#0F0C3D',
        }}
      >
        <div className='row my-4'>
          <div className='col-9'>
            <h6 className='h-card-color'>PROPERTY</h6>
            <p className='m-0'>{property}</p>
          </div>
          <div className='col-3'>
            {/* <div className='dropdown'>
              <i
                className='fas fa-ellipsis-vertical fs-18'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{ cursor: 'pointer' }}
              />
              <ul className='dropdown-menu fs-14 border-round px-3'>
                <li> */}

            {/* </li>
              </ul>
            </div> */}
            <div className=' position-relative'>
              <span onClick={() => setIsOpen(!isOpen)}>
                <i
                  className='fas fa-ellipsis-vertical fs-18'
                  style={{cursor: 'pointer'}}
                ></i>
              </span>
              {isOpen && (
                // <ul className='position-absolute ' style={{ bottom: '-35px' }}>
                <ul className='position-absolute card p-2 dropdown-menu fs-14 border-round '>
                  <li
                    className='text-link no-decoration dropdown-item cursor-pointer'
                    onClick={() => navigate(`/message/${id}`)}
                  >
                    view
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-6'>
            <h6 className='h-card-color'>COST PER NIGHT</h6>
            <p className='m-0'>
              <span className='fw-bold'>NGN</span> {cost?.toLocaleString()}
            </p>
          </div>
          {stay && intent !== 'sale' && (
            <div className='col-6'>
              <h6 className='h-card-color'>STAY DURATION</h6>
              <p className='m-0'>
                {stay}
                {`${stay > 1 ? ' Nights' : ' Night'}`}
              </p>
            </div>
          )}
        </div>
        {from ? (
          <div className='col-12 mb-4'>
            <h6 className='h-card-color'>REQUEST FROM</h6>
            <p className='text-capitalize m-0'>{from}</p>
          </div>
        ) : (
          <div className='col-12 mb-4'>
            <h6 className='h-card-color'>PROPERTY TYPE</h6>
            <p className='text-capitalize m-0'>{propertyType}</p>
          </div>
        )}
        <div className='col-12 mb-4'>
          <h6 className='h-card-color'>DATE ADDED</h6>
          <p className='m-0'>{listed.toString()}</p>
        </div>

        <div className='col-12 mb-4'>
          <div
            className='col-8 d-flex flex-row justify-content-center align-items-center gap-2 p-2'
            style={{
              border: '1px solid #B1B7D6',
              borderRadius: '22px',
            }}
          >
            {/* <i
              className={`fas fa-circle ${
                status ? 'text-success' : 'text-danger'
              }`}
              style={{
              	color: `${
              		status === 'listed'
              			? '#3DDC97'
              			: status === 'review'
              			? '#EAB735'
              			: '#C6C6C6'
              	}`,
              }}
            ></i> */}
            <i
              className={`fas fa-circle ${
                status === 'completed'
                  ? 'text-success'
                  : status === 'cancelled'
                  ? 'text-danger'
                  : 'text-warning'
              }`}
            />
            <p className='m-0 text-capitalize'>
              {status ? 'Published' : 'Pending'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
