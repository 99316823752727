type Props = {
  title: string;
  count: number;
  value?: number;
};

export const OverviewCard: React.FC<Props> = ({
  title,
  count,
}: // value,
Props) => {
  return (
    <div className='card border-0 border-round px-4 pt-4 shadow-sm'>
      <h6 className='fs-14 mt-1'>{title}</h6>
      <h3 className='fw-bold'>{count} Listings</h3>
      <p className='fs-12 mt-2'>
        {/* <span className='text-success fw-bold'>
					NGN {value.toLocaleString()}{' '}
				</span>
				generated in the last 30 days */}
      </p>
    </div>
  );
};
