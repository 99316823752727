// import {PrimaryButton} from '@shared/buttons';
// import NewModal from '@shared/controls/NewModal';
// import {InputComponent} from '@shared/forms';
import Tippy from '@tippyjs/react';
import {Fragment} from 'react';

type Prop = {
  transaction?: any;
  user?: any;
  setShowAddBankModal?: any;
  setOpenIncident: (arg: boolean) => void;
};

export const Completed = ({
  transaction,
  user,
  setShowAddBankModal,
  setOpenIncident,
}: Prop) => {
  // const [openModal, setOpenModal] = useState(false);
  // const [incident, setIncident] = useState('');

  // const handleSubmit = () => {
  //   console.log('hello');
  // };

  return (
    <Fragment>
      {transaction?.renter?.id === user?.id ? (
        <>
          <button
            className='btn btn-link fs-12'
            onClick={() => {
              setOpenIncident(true);
            }}
          >
            Report a problem
          </button>
          <div className='alert alert-success' role='alert'>
            <p className='fs-14'>Your payment was successful.</p>
          </div>
          <div className='alert alert-primary' role='alert'>
            <p className='fs-14'>
              The Krent legal team will be notified, and you will be provided
              with the link to the legal document to review and sign within the
              next 48 hours.
            </p>
          </div>
        </>
      ) : (
        <div className='mb-3 '>
          <div className='alert alert-success' role='alert'>
            <p className='fs-14'>Payment has been completed.</p>
          </div>

          <div className='alert alert-primary' role='alert'>
            <p className='fs-14'>
              The Krent legal team will be notified, and you will be provided
              with the link to the legal document to review and sign within the
              next 48 hours.
            </p>
          </div>
          {!transaction?.listing?.landlordAccountDetails && (
            <div>
              {' '}
              <h6 className='fs-10 fw-bold m-0'>
                Add Landlord's Account Details
              </h6>
              <button
                className='btn btn-primary'
                onClick={() => setShowAddBankModal(true)}
              >
                Add Account
              </button>
              <Tippy content='Due to your affiliation as an agent with this property,we will therefore transfer the due amount to the landlord and remit your commission to your account.'>
                <p className='fs-12 mt-1 ml-1 text-primary'>
                  why am i doing this?
                </p>
              </Tippy>
            </div>
          )}
          {/* <form onSubmit={handleLegalDocSubmission}>
                          <input
                            type='file'
                            hidden
                            accept='.pdf'
                            name='legalDocument'
                            id='legalDocument'
                            onChange={handleLegalDoc}
                          />
                          <label
                            className='d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer'
                            htmlFor='legalDocument'
                          >
                            <i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
                            <small className='m-0 fw-600'>
                              Upload legal agreement
                            </small>
                          </label>
                          <small className='fs-12 m-0 text-mute text-danger d-inline-block mb-3'>
                            Only PDF file is allowed. Please ensure you have
                            signed your part of the agreement before upload.
                          </small>
        
                          {legalDocumentUrl && (
                            <iframe
                              src={legalDocumentUrl}
                              width='100%'
                              height='200px'
                              title='PDF Preview'
                            ></iframe>
                          )}
                          <button
                            className='btn btn-primary w-100'
                            type='submit'
                            disabled={!legalDocument}
                          >
                            Upload
                          </button>
                        </form> */}
        </div>
      )}
    </Fragment>
  );
};
