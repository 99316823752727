import {PropertyListResponse} from '@interfaces/properties.interface';
import React from 'react';
import {Link} from 'react-router-dom';
import defaultImage from '../../../assets/images/property-card.png';

export const PropertCardHorizontal: React.FC<PropertyListResponse> = (
  p: PropertyListResponse
) => {
  return (
    <div className='col'>
      <div className='card  border-light shadow-sm h-100'>
        <Link
          to={`/property/${p.slug}`}
          state={{property: p, id: p.slug}}
          className='stretched-link'
        ></Link>
        <div className='row g-0 h-md-100'>
          <div className='col-md-4 h-md-100 '>
            <img
              src={p.coverImage ? `${p.coverImage}` : defaultImage}
              className='img-fluid rounded-start  h-100-md'
              alt={p.slug}
              title={p.slug}
            />
          </div>
          <div className='col-md-8'>
            <div className='card-body'>
              <h5 className='card-title'>{p.title}</h5>
              <p className='fs-12 text-muted'>
                {p.summary.slice(0, 100)}
                {p.summary.length > 100 ? '...' : '.'}
              </p>
              <p className='fs-12 text-muted'>{p.location.city}</p>
              <p className='card-text'>
                <small className='text-muted'>
                  Listed by {p.user.preferredName}
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
