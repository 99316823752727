import React from 'react';
import {BaseLayout} from '@layouts/BaseLayout';

import PageHero from '@shared/InnerPageHero';
import GetMeta from '@pages/SeoPages/GetMeta';

const Privacy = () => {
  return (
    <BaseLayout>
      <GetMeta
        description='Read our Privacy Policy to understand how we collect, use, and protect your personal information when you use Krent. Your privacy is our top priority.'
        title={`Privacy | Krent Inc`}
        canonicalValue={'privacy'}
      />
      <section className='mb-5' style={{marginTop: '-2rem'}}>
        <PageHero img='/assets/privacy.jpeg' currentPage='Privacy' />
      </section>
      <div className='container-fluid  mb-5'>
        <div className='container'>
          <div className='text-center mb-5'>
            <h1 className='fw-bolder '>Privacy</h1>
            <div className='d-flex justify-content-center row'>
              <p className=' col-md-9 col-lg-7'>
                This privacy policy outlines how Krent collects, uses, and
                protects the personal information of its users. We are committed
                to providing a secure and trustworthy environment for those who
                use our website, and we take the protection of personal data
                very seriously.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className='mb-5 pb-5'>
        <div className='container'>
          <div className='  d-flex flex-column  justify-content-center col-md-9 mx-auto'>
            <h4 className='fw-bold'>Collection of Personal Information</h4>
            <p>
              We collect personal information from users when they create an
              account, list a property, search for a property, or submit a query
              through our website. This information may include your name, email
              address, phone number, physical address, and payment information.
            </p>
            <h4 className='fw-bold'>Use of Personal Information</h4>
            <p>
              The personal information we collect is used to provide a better
              user experience and to improve the quality of our services. We may
              use this information to contact you in response to your queries,
              to process payments, and to provide you with relevant updates and
              news. We may also use your information to improve our website and
              to enhance the features and services we offer.
            </p>
            <h4 className='fw-bold'>Protection of Personal Information</h4>
            <p>
              We take the protection of your personal information seriously and
              have implemented appropriate technical and organizational measures
              to safeguard against unauthorized access, use, or disclosure.
              However, it is important to note that no data transmission over
              the internet can be guaranteed to be 100% secure, and we cannot
              guarantee the security of your personal information.
            </p>
            <h4 className='fw-bold'>Sharing of Personal Information</h4>
            <p>
              We do not sell or rent your personal information to third parties
              for their marketing purposes. We may, however, share your
              information with our service providers, such as payment processors
              or marketing agencies, for the purpose of providing the services
              you have requested. We may also be required to disclose your
              information to comply with applicable laws, regulations, or legal
              requests.
            </p>
            <h4 className='fw-bold'>Changes to Privacy Policy</h4>
            <p>
              We reserve the right to modify this privacy policy at any time,
              and will post the updated policy on our website. Your continued
              use of the website after such changes constitutes your acceptance
              of the revised policy.
            </p>
            <h4 className='fw-bold'>Data Retention</h4>
            <p>
              We retain personal information for as long as necessary to provide
              the services you have requested and to comply with applicable laws
              and regulations. After this period, we will delete or anonymize
              your personal information, unless we are required to retain it for
              longer in accordance with legal obligations.
            </p>
            <h4 className='fw-bold'>Your Data Rights</h4>
            <p>
              You have the right to access, modify, or delete your personal
              information at any time. You can request access to your personal
              information by contacting us at hello@krent.space . We will
              respond to your request within a reasonable timeframe.
            </p>
            <h4 className='fw-bold'>Grievances</h4>
            <p>
              If you believe that we have not addressed your concerns
              adequately, you may file a grievance with the appropriate
              regulatory authority.
            </p>
            <p>
              This privacy policy is subject to the laws of the Federal Republic
              of Nigeria. Any disputes arising out of this policy will be
              resolved in accordance with the laws of the Federal Republic of
              Nigeria.
            </p>
            <p>
              By using our website, you consent to the collection, use, and
              disclosure of your personal information as described in this
              privacy policy.
            </p>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default Privacy;
