import { StoryObject } from '@interfaces/properties.interface';
import React from 'react';

type Props = {
  item: [string, StoryObject[]];
  setDes: (value: string) => void;
  calculateStory: () => void;
  destinationRef: React.MutableRefObject<HTMLInputElement | null>;
};

const NeighbourStory = ({
  item,
  setDes,
  calculateStory,
  destinationRef,
}: Props) => {
  return (
    <div className='col-12 mt-3'>
      <div className=' fw-bold '>
        {item[0].replace(/_/g, ' ').toUpperCase() + 'S'}
      </div>

      <div className='col-12'>
        <div>
          {item[1]?.map((i: any, index: number) => {
            return (
              <div
                className='row d-flex align-items-center col-auto  px-3 py-3 bg-white border-0 rounded-4 gap-2 mt-3 cursor-pointer story-card shadow-sm'
                onClick={() => {
                  setDes(
                    `${i.geometry.location.lat},
                                    ${i.geometry.location.lng}`
                  );

                  calculateStory();
                  destinationRef!.current!.value = '';
                }}
                key={i.place_id + index}
              >
                <div className=' col-1 p-0 p-md-2 mr-2'>
                  <img
                    src={i.icon}
                    alt={i.name}
                    height={40}
                    width={40}
                    // className='rounded-circle'
                  />
                </div>
                <div className='col-9 '>
                  <p className='fs-14 fw-bold mb-0   text-capitalize'>
                    {i.name}
                  </p>
                  {/* <p className='fs-12 m-0'>
                    <span className='fw-semibold'>Status :</span>{' '}
                    {i.formatted_address}
                  </p> */}
                  {/* <p className='fs-12 m-0'>
                    <span className='fw-semibold'>Rating :</span>{' '}
                    {i.rating === 0 ? ' No rating yet' : i.rating}
                  </p> */}
                  {/* <span className='fs-12'>
                                    {i.formatted_address}
                                  </span> */}
                  {/* <p>
                                    {call2(`${i.geometry.location.lat},
                                    ${i.geometry.location.lng}`)}
                                  </p> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NeighbourStory;
