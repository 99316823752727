import {Fragment, useEffect, useState} from 'react';

import {DashboardLayout} from '@layouts/index';

// import {Modal} from '@shared/controls/Modal';

import {
  RequestCard,
  RequestCardMobile,
  //  RequestViewCard
} from '@shared/cards';

// import { requests } from './data';

import notification from '../../assets/images/notifications.png';
import {
  // acceptTransaction,
  getPropertyTransactions,
} from '@services/krent.service';
import {useSessionStorage} from '../../hooks';
import ktoast from '@services/toast.service';
import {differenceInDays} from 'date-fns';
export const Requests = () => {
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('shortlet');
  const [intent, setIntent] = useState<string>('shortlet');
  const [search, setSearch] = useState<string>('');
  // const [id, setId] = useState<string>();
  const [user] = useSessionStorage('user', {});
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  // const [request, setRequest] = useState<any>({});

  const tabList: string[] = ['shortlet', 'rent', 'sale', 'iBuying'];

  const headings: string[] = [
    'PROPERTY',
    'COST PER NIGHT',
    'REQUEST FROM',
    'DATE OF REQUEST',
    'STATUS',
    'STAY DURATION',
    'ACTIONS',
  ];
  const headings2: string[] = [
    'PROPERTY',
    'COST PER NIGHT',
    'REQUEST FROM',
    'DATE OF REQUEST',
    'STATUS',
    'ACTIONS',
  ];

  // const filterRequests = (requests: any[], tab: string) => {
  // 	return requests.filter((request: any) => request.type === tab);
  // };

  const filteredTransaction = transactions?.filter(
    (transaction) => transaction?.listing?.intent === intent
  );

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);

    getPropertyTransactions(user?.id, abortController.signal)
      .then((resp: any) => {
        setTransactions(resp.data);
      })
      .catch(() => {
        ktoast.error('Failed to load Transasctions');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]);
  // const acceptRequest = (id: any) => {
  //   acceptTransaction(id!)
  //     .then((resp: any) => {
  //       console.log(resp.data);

  //       ktoast.success('Transasctions Accepted');
  //     })
  //     .catch((err) => {
  //       console.log(err);

  //       ktoast.error(err.errors[0].message);
  //     });
  // };
  // const showRequest = (req: any) => {
  //   setRequest(req);
  //   setShowModal(true);
  // };

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Requests</h3>
        <p className='text-secondary fs-14'>
          Manage all requests on your property
        </p>
      </div>

      <div className='row'>
        <div className='col-12'>
          {loading ? (
            <p>Loading</p>
          ) : transactions && transactions.length > 0 ? (
            <Fragment>
              <div className='card border-0 border-round shadow-sm d-none d-md-flex'>
                <div className='row'>
                  {tabList.map((tabItem, i) => (
                    <div
                      className='col-2 text-center d-flex flex-column justify-content-end btn-el'
                      onClick={() => {
                        setTab(tabItem);
                        setIntent(
                          tabItem === 'shortlet'
                            ? 'shortlet'
                            : tabItem === 'rent'
                            ? 'long lease'
                            : tabItem === 'sale'
                            ? 'sale'
                            : 'iBuying'
                        );
                      }}
                      key={i}
                    >
                      <p
                        className={
                          tab === tabItem
                            ? 'fw-bold text-primary'
                            : 'light-text'
                        }
                        style={{
                          paddingBottom: '5px',
                          borderBottom: `${
                            tab === tabItem
                              ? '2px solid #1073CE'
                              : '2px solid #fff'
                          }`,
                        }}
                      >
                        {tabItem === 'iBuying'
                          ? 'i Buying'
                          : tabItem.replace(/\b[a-z]/g, (x) => x.toUpperCase())}
                      </p>
                    </div>
                  ))}

                  <div className='col-4 p-3 d-flex flex-row justify-content-center align-items-center gap-2'>
                    <input
                      type='text'
                      className='form-control border-none'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    {/* <i className='iconly-Search icli fs-22 mr-2' /> */}
                  </div>
                </div>
                <table className='table table-borderless fs-14 d-none d-md-table'>
                  <thead
                    className='w-100'
                    style={{backgroundColor: '#F7F8FF', color: '#0E1956'}}
                  >
                    {intent === 'sale' || intent === 'iBuying' ? (
                      <tr>
                        {headings2.map((heading, index) => (
                          <th className='p-3 fs-12' key={index} scope='col'>
                            {heading}
                          </th>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {headings.map((heading, index) => (
                          <th className='p-3 fs-12' key={index} scope='col'>
                            {heading}
                          </th>
                        ))}
                      </tr>
                    )}
                  </thead>
                  <tbody
                    className='table-group-divider'
                    style={{
                      color: '#0F0C3D',
                    }}
                  >
                    {filteredTransaction.length > 0 ? (
                      filteredTransaction.map((request) => {
                        const stayDuration = differenceInDays(
                          new Date(request?.departure_date),
                          new Date(request?.arrival_date)
                        );

                        return (
                          <RequestCard
                            id={request.id}
                            key={request.id}
                            property={request?.listing?.title}
                            propertyType={request?.listing?.intent}
                            cost={request?.listing?.price}
                            duration={'3'}
                            intent={intent}
                            listed={request?.createdAt}
                            status={request?.status}
                            stay={stayDuration}
                            from={
                              request?.renter?.name ||
                              request?.renter?.prefferedName ||
                              `${request?.renter?.firstname} ${request?.renter?.lastname}`
                            }
                            // setShowModal={() => showRequest(request)}
                            // loadRequest={() => showRequest(request)}
                            // setId={setId}
                            cardType='request'
                          />
                        );
                      })
                    ) : (
                      <p className='p-5 fw-bold'>No Request</p>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='d-block d-md-none p-1'>
                <div className='col-12 mb-3'>
                  <input
                    type='text'
                    className='form-control border-round-xl bg-purple p-2 px-4 mb-2'
                    placeholder='Search...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <select
                    className='form-select border-round-xl bg-purple p-2 px-4 mb-2'
                    onChange={(e) => {
                      setTab(e.target.value);
                      setIntent(e.target.value);
                    }}
                  >
                    <option defaultValue={tab}>Select</option>
                    <option value='shortlet'>Shortlet</option>
                    <option value='long lease'>Rental</option>
                    <option value='sale'>Sale</option>
                  </select>
                </div>
                <div className='col-12'>
                  {transactions
                    ?.filter((trans) => trans?.listing?.intent === intent)
                    .map((request) => {
                      const stayDuration = differenceInDays(
                        new Date(request?.departure_date),
                        new Date(request?.arrival_date)
                      );
                      return (
                        <RequestCardMobile
                          id={request.id}
                          key={request.id}
                          property={request?.listing?.title}
                          propertyType={request?.listing?.intent}
                          cost={request?.listing?.price}
                          duration={'3'}
                          intent={intent}
                          listed={request?.createdAt}
                          status={request?.status}
                          stay={stayDuration}
                          from={request?.renter?.name}
                          // setShowModal={() => showRequest(request)}
                          // loadRequest={() => showRequest(request)}
                          // setId={setId}
                          cardType='request'
                        />
                      );
                    })}
                </div>
              </div>
            </Fragment>
          ) : (
            <div className='card border-0 border-round shadow-sm p-5 text-center d-flex align-items-center'>
              <h6 className='fs-18 mt-1 fw-bold mb-4'>No requests yet</h6>
              <img src={notification} alt='No transactions' width={200} />
              <p className='fs-14 light-text'>
                Any request you receive here would show up here.
                <br />
                You don't have any requests right now
              </p>
            </div>
          )}
        </div>
      </div>

      {/* <Modal show={showModal} onClose={() => setShowModal(false)}>
        {request.id && <RequestViewCard req={request} accept={() => {}} />}
      </Modal> */}
    </DashboardLayout>
  );
};
