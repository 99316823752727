import {Link} from 'react-router-dom';

const PopularLocation = () => {
  return (
    <div className='container popular-location position-relative'>
      <div className='g-container d-flex flex-column justify-content-center gap-5 pt-5 '>
        <div className='container d-flex flex-column align-items-center justify-content-center gap-md-3'>
          <h4 className='font-display text-white fw-600 fs-24'>
            Popular Naija Locations
          </h4>
          <p className='font-onest text-white text-center '>
            Find your dream apartment or home in our most popular locations.
          </p>
        </div>
        {/* Buttons */}
        <div className='container d-flex flex-column flex-md-row gap-4 align-items-center justify-content-center'>
          <Link
            to={{pathname: '/explore/', search: `location.city=abuja`}}
            className='btn btn-outline-location text-decoration-none text-white  border-white d-flex align-items-center justify-content-center'
          >
            Abuja
          </Link>
          <Link
            to={{pathname: '/explore/', search: `location.city=lagos`}}
            className='btn btn-outline-location text-decoration-none text-white  border-white d-flex align-items-center justify-content-center'
          >
            Lagos
          </Link>
          <Link
            to={{pathname: '/explore/', search: `location.city=portharcourt`}}
            className='btn btn-outline-location text-decoration-none text-white  border-white d-flex align-items-center justify-content-center'
          >
            Port-Harcourt
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PopularLocation;
