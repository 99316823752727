import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@shared/buttons';

import { Link } from 'react-router-dom';
import { verifyPayment } from '@services/krent.service';

export const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const reference = searchParams.get('reference');

  useEffect(() => {
    const storageRef = localStorage.getItem('paymentRef');
    const { id, ref } = storageRef ? JSON.parse(storageRef) : null;

    if (reference !== ref) {
      navigate('/');
    }
    verifyPayment(id, ref)
      .then((res) => console.log(res))
      .catch((err) => {
        const error = err.error;
        error && console.log(error[0]?.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='container pt-5 d-flex min-h-100 align-items-center justify-content-center'>
      <div className='row'>
        <div className='col-lg-6 mx-auto'>
          <div className='jumbotron text-center w-100'>
            <p className='lead'>Your Payment was successful</p>
            <hr />
            <Link className='text-link' to='/'>
              <PrimaryButton type='primary' text='Back Home' width='100%' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
