import React from 'react';
import {BaseLayout} from '@layouts/BaseLayout';

import PageHero from '@shared/InnerPageHero';
import GetMeta from '@pages/SeoPages/GetMeta';

const About = () => {
  return (
    <BaseLayout>
      <GetMeta
        description='Learn about our mission, values, and the team behind our property listing site. Discover how we connect buyers, renters, and landlords with the perfect properties and our commitment to providing exceptional service.'
        title={`About Us | Krent Inc`}
        canonicalValue={'about'}
      />
      <section className='mb-5' style={{marginTop: '-2rem'}}>
        <PageHero img='/assets/terms.jpg' currentPage='About' />
      </section>
      <div className='container-fluid  mb-5'>
        <div className='container'>
          <div className='text-center mb-5'>
            <h1 className='fw-bolder '>About Us</h1>
            <div className='d-flex justify-content-center row'>
              <p className=' col-md-9 col-lg-7'>
                Welcome to Krent - Your Partner for Seamless Real Estate
                Transactions!
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className='mb-5 pb-5'>
        <div className='container'>
          <div className='  d-flex flex-column  justify-content-center col-md-9 mx-auto'>
            <p>
              At Krent, we are dedicated to revolutionizing your experience
              while performing real estate transactions. Our cutting-edge
              technology solution is designed to simplify and streamline the
              processes of renting, leasing, buying, and selling properties.
              With a commitment to innovation, efficiency, and customer
              satisfaction, we empower all stakeholders improve transaction
              economics and efficiency.
            </p>
            <h4 className='fw-bold'>Our Mission:</h4>
            <p>
              Our mission is simple yet powerful - we aim to transform the
              property industry by harnessing the potential of technology. We
              believe that property transactions should be straightforward,
              transparent, and swift. Through our proprietary technology, we are
              able to provide seamless solutions that enhance time efficiency,
              reduce costs, and elevate the overall experience for real estate
              market participants.
            </p>

            <h4 className='fw-bold'>What Sets Us Apart:</h4>
            <p>
              At Krent, we stand out from the crowd due to our unwavering
              commitment to innovation and excellence. Our team of experts,
              consisting of tech enthusiasts and real estate professionals,
              collaborates to create solutions that meet the unique needs of the
              modern property market. By integrating advanced technology into
              every aspect of our platform, we are able to offer a comprehensive
              suite of tools that address challenges faced by all stakeholders
              in property transactions.
            </p>

            <p>
              Join Us in Shaping the Future of Property Transactions: Whether
              you're renting/leasing or buying/selling, Krent invites you to
              experience the future of property transactions today. Embrace a
              seamless journey where technology meets real estate and let us
              guide you towards your property goals.
            </p>

            <p>
              Thank you for choosing Krent - where innovation meets real estate
              expertise.
            </p>

            <p>Sincerely,</p>
            <p>The Krent Team</p>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};

export default About;
