import {useNavigate} from 'react-router-dom';

import {NotificationsInterfaceResponse} from '@interfaces/notifications.interface';

export const NotificationCard: React.FC<NotificationsInterfaceResponse> = ({
  title,
  id,
  createdAt,
  read,
}: NotificationsInterfaceResponse) => {
  const navigate = useNavigate();

  return (
    <div className='d-md-flex d-block justify-content-between py-3 px-3 border-bottom'>
      <div className='row'>
        <p className='light-text fs-14 order-md-last text-end text-md-start'>
          {new Date(createdAt.toLocaleString()).toLocaleTimeString('en-Us', {
            hour: 'numeric',
            minute: 'numeric',
          })}
        </p>
        <p className='fs-14 mb-3 mb-md-1 order-md-first'>
          {title} on{' '}
          <span className='fw-bold'>
            {new Date(createdAt.toLocaleString()).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
        </p>
      </div>
      <div className='text-start text-md-end'>
        <span
          onClick={() => navigate('/requests')}
          className={`text-link text-primary ${!read && 'fw-bold'} fs-14`}
          data-id={id}
        >
          {' '}
          View Request{' '}
        </span>
        {/* {newRequest
					? <a href='#!' className='text-link text-primary fw-bold fs-14'> View Request </a>
					: <div className='d-flex gap-3'>
						<a href='#!' className='text-link text-primary fw-bold fs-14'>
							Accept
						</a>
						<a href='#!' className='text-link-secondary fs-14'>
							Reschedule
						</a>
					</div>
				} */}
      </div>
    </div>
  );
};
