import React from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';

const PropertyVideo = ({url, virtualTour, virtualTour2}: any) => {
  const videoLink = url;

  const match = videoLink?.match(/(?:\?v=|&v=|youtu\.be\/|\/shorts\/)(.{11})/);
  const videoId = match ? match[1] : null;

  React.useEffect(() => {
    console.clear();
  }, []);
  const [isOpen, setOpen] = React.useState(false);
  return (
    <section>
      <div className={`full-width ${!virtualTour2 ? 'text-center' : ''} `}>
        {typeof window !== 'undefined' && (
          <ModalVideo
            isOpen={isOpen}
            videoId={videoId}
            onClose={() => setOpen(false)}
          />
        )}
        <a
          className='vid text-decoration-none'
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
          href={url}
        >
          {virtualTour ? (
            <span className=' fw-bold fs-14'>View Virtual Tour</span>
          ) : virtualTour2 ? (
            <span className=' text-primary cursor-pointer  d-block'>
              Click to see a sample
            </span>
          ) : (
            <div className='vid-butn'>
              <span className='icon'>
                <i className='fas fa-play'></i>
              </span>
            </div>
          )}
        </a>
      </div>
    </section>
  );
};

export default PropertyVideo;
