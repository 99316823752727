import {useEffect, useState} from 'react';

// import { PrimaryButton } from '@shared/buttons';

// import { Modal } from '@shared/controls/Modal';
import {ListingFormComponent} from '@shared/forms';

import {
  createProperty,
  getSingleListingProperty,
  // getSingleProperty,
  updateProperty,
} from '@services/krent.service';
// import { useNavigate } from 'react-router';
import ktoast from '@services/toast.service';

import {
  Step1,
  Step2,
  // Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  // ModalStep1,
  // ModalStep2,
} from './steps';
import {useLocation} from 'react-router-dom';
// import { ModalStep1 } from '../Rent/steps';

export const ListSale = () => {
  const [loading, setLoading] = useState<boolean>(false);
  // const [showModal, setShowModal] = useState<boolean>(false);
  const location: any = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editID = searchParams.get('id');

  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(location?.state?.index || 0);
  // const [modalIndex, setModalIndex] = useState<number>(0);
  const [propertyName, setPropertyName] = useState<string>('');
  const [propertyDesc, setPropertyDesc] = useState<string>('');
  const [propertyType, setPropertyType] = useState<any>('');
  const [propertyCategory, setPropertyCategory] = useState<any>();
  // const [spaceType, setSpaceType] = useState<string>('');
  const [safetyFeatures, setSafetyFeatures] = useState<any>([]);
  const [amenities, setAmenities] = useState<any>([]);
  // const [viewingDates, setViewingDates] = useState<any>([]);
  const [guests, setGuests] = useState<number>();
  const [bedrooms, setBedrooms] = useState<number>();
  const [beds, setBeds] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [floors, setFloors] = useState<number>();
  const [garages, setGarages] = useState<number>();
  const [size, setSize] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [apartment, setApartment] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [tourUrl, setTourUrl] = useState<string>();
  const [tourIntrest, setTourIntrest] = useState<boolean>();
  const [affiliation, setAffiliation] = useState<string>();
  const [propertyCreated, setPropertyCreated] = useState<boolean>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [flood_meter, setFlood_meter] = useState<string>();
  const [neighbour_story, setNeighbour_story] = useState<boolean>(false);
  const [geo, setGeo] = useState<[]>();
  const [propertyId, setPropertyId] = useState('');
  const [toilets, setToilets] = useState('');
  // const [serviceChargeDuration, setServiceChargeDuration] = useState<string>();
  // const [serviceCharge, setServiceCharge] = useState<string>('');
  const [roi, setRoi] = useState<number>(0);
  const [deed, setDeed] = useState<string>('');
  const [virtual_tour_date_request, setVirtual_tour_date_request] =
    useState<any>();

  // const navigate = useNavigate();

  const onPrev = () => {
    setIndex(index - 1);
  };

  const onNext = () => {
    setIndex(index + 1);
  };

  // const modalSteps = [
  // 	ModalStep1({ viewingDates, setViewingDates, setModalIndex }),
  // 	ModalStep2({ setShowModal, setModalIndex }),
  // ];
  const handleCreate = () => {
    setLoading(true);
    const payloads = {
      title: propertyName,
      summary: propertyDesc,
      category: propertyCategory,
      kind: propertyType,
      space: '612a183ec1976d6838debb2e',
      address,
      location: {
        apartment,
        city,
        state,
        country,
        geo,
        formatted_address: address,
      },
      price: price,
      currency: 'NGN',
      description: propertyDesc,
      intent: 'sale',
      cycle: 'yearly',
      guests: guests,
      beds: beds,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      virtual_tour_url: tourUrl,
      video_url: videoUrl,
      affiliation,
      amenities,
      neighbour_story,
      flood_meter,
      // service_charge_duration: serviceChargeDuration,
      // service_charge: serviceCharge,
      toilets,
      roi: 0,
      deed,
    };

    if (editID) {
      updateProperty(editID, {
        ...payloads,
        published: false,
        category: propertyCategory._id,
        kind: propertyType._id,
      })
        .then(() => {
          setPropertyId(editID);
          setPropertyCreated(true);
          setShowSuccessModal(true);
        })
        .catch((err) => {
          ktoast.error(err.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    createProperty(payloads)
      .then((res: any) => {
        setPropertyId(res?.data.id);
        setPropertyCreated(true);
        setShowSuccessModal(true);
        // onNext();
      })
      .catch((err) => {
        console.log(err);

        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editID) {
      setEditLoading(true);
      let abortController = new AbortController();
      getSingleListingProperty(editID, abortController.signal)
        .then((res: any) => {
          // setEditData(res.data);
          setPropertyName(res.data?.title);
          setPropertyDesc(res.data?.summary);
          setPropertyType(res.data?.kind);
          setPropertyCategory(res.data.category);
          setAddress(res.data?.location2.formatted_address);
          setApartment(res.data?.location2.apartment);
          setCity(res.data?.location2.city);
          setState(res.data?.location2.state);
          setCountry(res.data?.location2.country);
          setGeo(res.data?.location2.geo);
          setPrice(res.data?.price);
          setPropertyDesc(res.data?.description);
          setGuests(res.data?.guests);
          setBeds(res.data?.beds);
          setBedrooms(res.data?.bedrooms);
          setBathrooms(res.data?.bathrooms);
          setVideoUrl(res.data?.video_url);
          setTourUrl(res.data?.virtual_tour_url);
          setAffiliation(res.data?.affiliation);
          setAmenities(res.data?.amenities);
          setFlood_meter(res.data?.flood_meter);
          setNeighbour_story(res.data?.neighbour_story);

          setToilets(res.data?.toilets);
          setFloors(res.data?.floors);
          setGarages(res.data?.garages);
          setDeed(res.data?.deed);

          setEditLoading(false);
        })
        .catch((err: any) => {
          console.log(err.errors[0].message);
          setEditLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [editID]);
  if (editLoading) {
    return <h3>Loading...</h3>;
  }

  // const showM = () => {
  //   const payload = {
  //     title: propertyName,
  //     summary: propertyDesc,
  //     category: propertyCategory,
  //     kind: propertyType,
  //     space: spaceType,
  //     address: `${apartment}, ${address}, ${city}, ${state}, ${country}`,
  //     price: price,
  //     currency: 'NGN',
  //     description: propertyDesc,
  //     intent: 'sale',
  //     cycle: 'yearly',
  //     guests: guests,
  //     beds: beds,
  //     bedrooms: bedrooms,
  //     bathrooms: bathrooms,
  //     virtual_tour_url: tourUrl,
  //     video_url: videoUrl,
  //   };

  //   console.log(payload);

  //   setLoading(true);

  //   createProperty(payload)
  //     .then(() => {
  //       navigate(
  //         {
  //           pathname: '/listings/',
  //         },
  //         {
  //           state: {
  //             showToast: true,
  //             toastMessage: 'Listing published successfully',
  //           },
  //           replace: true,
  //         }
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setShowModal(false);
  //       ktoast.error(err.errors[0].message);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return (
    <div>
      {/* {showModal && (
        // <Modal show={showModal} onClose={() => setShowModal(false)}>
        // 	<div className='col px-4'>
        // 		<ModalStep1
        // 			viewingDates={viewingDates}
        // 			setViewingDates={setViewingDates}
        // 		/>
        // 	</div>
        // </Modal>
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className='col px-4'>
            <h6 className='fw-bold fs-20'>
              Are you ready to publish this property?
            </h6>
            <p className='light-text'>
              Once you publish this property, Krent will review your listing and
              your listing would go live once it is approved.
            </p>

            <div className='row'>
              <div className='col-6'>
                <PrimaryButton
                  text='Publish'
                  loading={loading}
                  type='primary'
                  click={showM}
                />
              </div>
              <div className='col-6'>
                <PrimaryButton
                  text='Do this later'
                  type='outline'
                  click={() => setShowModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal>setFloors
      )} */}

      <ListingFormComponent index={index}>
        {/* setShowModal={setShowModal} */}
        <Step1
          propertyName={propertyName}
          setPropertyName={setPropertyName}
          propertyDesc={propertyDesc}
          setPropertyDesc={setPropertyDesc}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step2
          propertyType={propertyType}
          editID={editID}
          setPropertyType={setPropertyType}
          propertyCategory={propertyCategory}
          setPropertyCategory={setPropertyCategory}
          onPrev={onPrev}
          onNext={onNext}
          affiliation={affiliation}
          setAffiliation={setAffiliation}
          deed={deed}
          setDeed={setDeed}
        />
        {/* <Step3
          spaceType={spaceType}
          setSpaceType={setSpaceType}
          onPrev={onPrev}
          onNext={onNext}
        /> */}
        <Step4
          guests={guests}
          setGuests={setGuests}
          bedrooms={bedrooms}
          setBedrooms={setBedrooms}
          bathrooms={bathrooms}
          setBathrooms={setBathrooms}
          floors={floors}
          setFloors={setFloors}
          garages={garages}
          setGarages={setGarages}
          beds={beds}
          setBeds={setBeds}
          size={size}
          setSize={setSize}
          onPrev={onPrev}
          onNext={onNext}
          toilets={toilets}
          setToilets={setToilets}
          propertyType={propertyType}
        />
        <Step5
          price={price}
          setPrice={setPrice}
          onPrev={onPrev}
          onNext={onNext}
          roi={roi}
          setRoi={setRoi}
          // serviceCharge={serviceCharge}
          // setServiceCharge={setServiceCharge}
          // serviceChargeDuration={serviceChargeDuration}
          // setServiceChargeDuration={setServiceChargeDuration}
        />
        <Step6
          safetyFeatures={safetyFeatures}
          setSafetyFeatures={setSafetyFeatures}
          amenities={amenities}
          setAmenities={setAmenities}
          propertyType={propertyType}
          onPrev={onPrev}
          onNext={onNext}
        />
        {/* <Step7
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          apartment={apartment}
          setApartment={setApartment}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step8
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          tourUrl={tourUrl}
          setTourUrl={setTourUrl}
          onPrev={onPrev}
          onNext={() => setShowModal(true)}
        /> */}
        <Step7
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          apartment={apartment}
          setApartment={setApartment}
          onPrev={onPrev}
          onNext={onNext}
          handleCreate={handleCreate}
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          setGeo={setGeo}
          setNeighbour_story={setNeighbour_story}
          flood_meter={flood_meter}
          setFlood_meter={setFlood_meter}
          editID={editID}
        ></Step7>
        <Step8
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          tourUrl={tourUrl}
          setTourUrl={setTourUrl}
          setTourIntrest={setTourIntrest}
          tourIntrest={tourIntrest}
          setLoading={setLoading}
          loading={loading}
          onPrev={onPrev}
          propertyId={propertyId}
          propertyCreated={propertyCreated}
          affiliation={affiliation}
          virtualTourDate={virtual_tour_date_request}
          setVirtualTourDate={setVirtual_tour_date_request}
        ></Step8>
      </ListingFormComponent>
    </div>
  );
};
