import {useState} from 'react';
import axios from 'axios';

import {OnboardingLayout} from '@layouts/OnboardingLayout';
import {InputComponent} from '@shared/forms';
import {PrimaryButton} from '@shared/buttons';
import Logo from '@shared/Logo';
import {Link, useNavigate} from 'react-router-dom';

const url = process.env['REACT_APP_API_BASE_URL'];
// const baseURL = 'https://prodkrentnode-yo4us.ondigitalocean.app/api/v1';

export const ReverifyEmail = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(`${url}/auth/resend-verification-mail`, {
        email,
      })
      .then((res) => {
        setSuccess(res.data?.message);
        setTimeout(() => {
          setSuccess('');
          navigate('/sign-in/');
        }, 6000);
      })
      .catch((err) => {
        setError(err.response?.data?.message);
        setTimeout(() => {
          setError('');
        }, 6000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <OnboardingLayout>
      <div className='container'>
        {/* <h3 className='text-md-start fw-bolder mb-5'>krent</h3> */}
        <h3
          className='text-md-start fw-bolder mb-2 text-link text-black mt-3'
          onClick={() => navigate('/')}
        >
          <Logo />
        </h3>
        <h4 className='fw-bolder'>Reverify Your Email Address</h4>
        <p className='text-secondary fs-14 mb-4'>
          Didn’t get a verification email? Enter your email address.
        </p>
        <form className='mt-4 mt-md-3' onSubmit={handleSubmit}>
          <div className='col mb-4'>
            {success && <span className='text-success col-12'>{success}</span>}
            <InputComponent
              label='Email Address'
              placeholder='Enter your email address'
              icon='Message'
              type='email'
              value={email}
              change={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className='col text-center'>
            {error && <span className='text-danger mb-1'>{error}</span>}
            <PrimaryButton
              type='primary'
              text='Resend Verification Link'
              loading={loading}
            />
          </div>
          <div className='col text-end mb-5'>
            <Link className='text-secondary fs-14 text-link' to='/'>
              Back Home
            </Link>
            <Link className='text-secondary fs-14 text-link ml-3' to='/sign-in'>
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};
