import React from 'react';
import {BaseLayout} from '@layouts/BaseLayout';

import PageHero from '@shared/InnerPageHero';
import TermsAndConditionData from '@shared/TermsAndConditionData';
import GetMeta from '@pages/SeoPages/GetMeta';

const TermsAndCondition = () => {
  return (
    <BaseLayout>
      <GetMeta
        description='Review the Terms and Conditions for using our property listing site. Understand the guidelines, user responsibilities, and legal obligations when accessing property listings for sale, rent, and shortlets. Ensure a secure and informed experience.'
        title={`Terms and Conditions | Krent Inc`}
        canonicalValue={'terms'}
      />
      <section className='mb-5' style={{marginTop: '-2rem'}}>
        <PageHero img='/assets/terms.jpg' currentPage='Terms & Condition' />
      </section>
      <TermsAndConditionData />
    </BaseLayout>
  );
};

export default TermsAndCondition;
