import { v4 as uuidv4 } from 'uuid';

export const category = [
  { title: 'Listing a Property', icon: 'fa fa-home' },
  { title: 'Shortlet', icon: 'fa fa-bed' },
  { title: 'Renting a Property', icon: 'fa fa-home' },
  { title: 'Buying a Property', icon: 'fa fa-home' },
  { title: 'Renting out a property', icon: 'fa fa-home' },
  { title: 'General', icon: 'fa fa-book' },
];

export const items = [
  [
    {
      question: 'What is the process for listing my property on your platform?',
      answer:
        'To list your property  on our website, you will need to create a seller account and follow the steps provided to submit your listing.',
      id: uuidv4(),
    },

    {
      question: 'Can I edit my property listing after it is published?',
      answer:
        'Yes but this will trigger another approval process before its published again.',
      id: uuidv4(),
    },
    // {
    //   question: 'How do I manage my property listing?',
    //   answer: 'Go to your seller dashboard and click on the property options.',
    //   id: uuidv4(),
    // },
    {
      question:
        'How long does it take for my property to be approved and published?',
      answer: '12-24 hours if all information is provided is accurate.',
      id: uuidv4(),
    },
    {
      question: 'How do I receive inquiries and bookings for my property?',
      answer:
        'The platform is equipped with a messaging system which will allow you view potential clients and communicate with them.',
      id: uuidv4(),
    },
    {
      question:
        'What is the process for accepting or declining a booking request?',
      answer:
        'Notification will be sent to you via email and on your profile on the platform.',
      id: uuidv4(),
    },
    // {
    //   question: 'Can I offer promotions or discounts on my property listing?',
    //   answer:
    //     'Yes, you can. You can provide details of any promotion or discounts you wish you offer on your property listing in the additional comments/property description section',
    //   id: uuidv4(),
    // },
    {
      question: 'How do I communicate with potential renters/buyers?',
      answer:
        'The platform is equipped with a messaging system which will allow you view potential clients and communicate with them.',
      id: uuidv4(),
    },
    {
      question: 'How do I know if someone is interested in my property?',
      answer:
        'A notification will be sent to you on the platform as well as an email indicating the potential interest in your property.',
      id: uuidv4(),
    },
    {
      question: 'Is there any limit to the number of properties I can list?',
      answer: 'No. There is no limit to the number of properties you can list.',
      id: uuidv4(),
    },
    {
      question: 'Is there a limit to the number of photographs I can upload?',
      answer:
        'Yes, the minimum number of pictures is 5 and the maximum number is 30',
      id: uuidv4(),
    },
    {
      question: 'How do I receive payment for my property?',
      answer:
        'The platform is equipped with a personal wallet for you where you can easily follow the transaction updates and transfer directly to your bank account.',
      id: uuidv4(),
    },
    {
      question: 'What should I include in my property listing?',
      answer:
        'Your property listing should include a detailed description of the property, photos, and information about the location, size, and features of the property.',
      id: uuidv4(),
    },
    {
      question: 'How do I price my property for sale?',
      answer:
        'You can price your property for sale by researching comparable properties in your area.',
      id: uuidv4(),
    },
    {
      question: 'How do I prepare my property for showings?',
      answer:
        'To prepare your property for showings, you may want to declutter, clean, and stage your property to make it more attractive o potential buyers. You may also want to consider making any necessary repairs or updates to improve the propertys value.',
      id: uuidv4(),
    },
  ],
  [
    {
      question: 'What happens if a guest cancels a booking?',
      answer:
        'This depends on the refund policy set by the property owner/agent at the time of booking',
      id: uuidv4(),
    },
    {
      question:
        'What is the cancellation policy for property listings on the platform?',
      answer:
        'This varies from host to host. Cancellation policies are set by the host',
      id: uuidv4(),
    },
  ],

  [
    {
      question: 'What is the typical lease length for a rental property?',
      answer:
        'The typical lease length for a rental property can vary, but is often 12 months.',
      id: uuidv4(),
    },
    {
      question:
        'What is the application process for renting a property on your website?',
      answer:
        'The application process for renting a property on our website will vary depending on the property and the owner/landlord. Contact the listing agent for more information.',
      id: uuidv4(),
    },
    {
      question:
        'What should I do if I want more information about one of the properties?',
      answer:
        'You can indicate interest on the property, message the property owner/agent and request to view the property.',
      id: uuidv4(),
    },
    {
      question:
        'How long does it take for me to get a response after indicating interest in a property?',
      answer: 'Typically, responses are expected within 24 - 48 hours',
      id: uuidv4(),
    },
    {
      question:
        'What is the next step after I complete a viewing and want to take the property?',
      answer:
        'After viewing the property, a message is sent to you to re-confirm your interest in the property. After you have confirmed interest, you sign the legal agreement, then payment.',
      id: uuidv4(),
    },
    {
      question: 'Can I rent a property with a roommate?',
      answer:
        'Yes, you can rent a property with a roommate, but you will both need to meet the requirements set by the owner/landlord and complete the application process together.',
      id: uuidv4(),
    },
    {
      question: 'What is the process for renewing a lease?',
      answer:
        'The process for renewing a lease will vary depending on the property and the owner/landlord. Contact the listing agent for more information.',
      id: uuidv4(),
    },
    {
      question: 'Can I sublet my rental property?',
      answer:
        'Whether or not you can sublet your rental property will depend on the terms of your lease agreement. Check with the listing agent for more information',
      id: uuidv4(),
    },
    {
      question:
        'Are pet-friendly properties available for rent on your website?',
      answer:
        'Yes, we have a number of pet-friendly properties available for rent on our website.',
      id: uuidv4(),
    },
  ],
  [
    {
      question:
        'What types of properties are available for purchase on your website?',
      answer:
        'Our website features listings for various types of properties available for purchase, including apartments, houses, condos, townhouses, and more.',
      id: uuidv4(),
    },
    {
      question:
        'What documents do I need to complete the purchase of a property?',
      answer:
        'The documents required to complete the purchase of a property will vary, but may include a purchase agreement, mortgage documents, and closing statements.',
      id: uuidv4(),
    },
    {
      question: 'What is the closing process like?',
      answer:
        'The closing process will vary, but typically involves signing and finalizing legal and financial documents, transferring ownership of the property, and paying closing costs.',
      id: uuidv4(),
    },
    {
      question: 'What is a home inspection and do I need one?',
      answer:
        'A home inspection is a thorough examination of a property conducted by a professional inspector to identify any potential issues or problems. Whether or not you need a home inspection will depend on your personal preferences and the terms of your purchase agreement.',
      id: uuidv4(),
    },
    {
      question: 'What are closing costs and how much should I expect to pay?',
      answer:
        'Closing costs are fees associated with the purchase of a property and can include things like  lawyer fees, and property taxes. The amount you can expect to pay in closing costs will vary.',
      id: uuidv4(),
    },
  ],

  [
    {
      question: 'How do I determine the rent for my rental property?',
      answer:
        'To determine the rent for your rental property, you should consider factors such as the location of the property, the quality and size of the property, and the current market conditions. You may also want to research comparable properties in the area to determine an appropriate rent amount.',
      id: uuidv4(),
    },
    {
      question: 'How do I determine the rent for my rental property?',
      answer:
        'To determine the rent for your rental property, you should consider factors such as the location of the property, the quality and size of the property, and the current market conditions. You may also want to research comparable properties in the area to determine an appropriate rent amount.',
      id: uuidv4(),
    },
    {
      question: 'What should I include in a rental agreement?',
      answer:
        'A rental agreement should include important details such as the rental rate, security deposit amount, payment due date, and any other terms and conditions of the rental. It should also outline the responsibilities of both the landlord and the tenant, such as maintenance and repair responsibilities, and any restrictions on the use of the property.',
      id: uuidv4(),
    },
    {
      question: 'What are the responsibilities of a landlord?',
      answer:
        'The responsibilities of a landlord typically include maintaining the property, handling repairs, collecting rent, and following local housing laws and regulations. Landlords may also need to handle eviction proceedings if a tenant fails to pay rent or violates the terms of the rental agreement..',
      id: uuidv4(),
    },
    {
      question: 'What are the responsibilities of a tenant?',
      answer:
        'The responsibilities of a tenant typically include paying rent on time, following the terms of the rental agreement, and taking care of the property. Tenants may also need to follow local housing laws and regulations and may be responsible for certain repairs or maintenance tasks, depending on the terms of the rental agreement.',
      id: uuidv4(),
    },
    {
      question:
        'How do I handle maintenance and repairs for my rental property?',
      answer:
        'To handle maintenance and repairs for your rental property, you may need to hire a repair person or contractor, or you may choose to handle the repairs yourself. You may also need to communicate with your tenants about any maintenance issues and keep records of any repairs or upgrades that you make to the property.',
      id: uuidv4(),
    },
    {
      question: 'What are my rights as a landlord?',
      answer:
        'As a landlord, you have the right to collect rent, maintain the property, and enforce the terms of the rental agreement. You also have the right to access the property for maintenance or repair purposes, with proper notice to the tenant. However, you must also respect the privacy and quiet enjoyment of the tenant and follow all local housing laws and regulations.',
      id: uuidv4(),
    },
    {
      question: 'What are the rights of a tenant?',
      answer:
        'As a tenant, you have the right to a safe and livable living space, quiet enjoyment of the property, and privacy. You also have the right to dispute any charges or fees, such as late fees or repair costs, that you believe are unjustified. Tenants also have the right to seek legal remedies if the landlord fails to provide a safe and livable living space or violates the terms of the rental agreement.',
      id: uuidv4(),
    },
    {
      question: 'What happens if a tenant breaches the rental agreement?',
      answer:
        'If a tenant breaches the rental agreement, such as by failing to pay rent or damaging the property, the landlord may choose to pursue legal remedies, such as an eviction. However, the specific steps and procedures will vary depending on local laws and regulations.',
      id: uuidv4(),
    },
    {
      question: 'What happens if a landlord breaches the rental agreement?',
      answer:
        'If a landlord breaches the rental agreement, such as by failing to maintain the property or violating the tenants privacy, the tenant may choose to pursue legal remedies, such as withholding rent or seeking compensation for damages. However, the specific steps and procedures will vary depending on local laws and regulations.',
      id: uuidv4(),
    },
    {
      question: 'How can I make my property stand out to potential tenants?',
      answer:
        'To make your property stand out to potential tenants, you can consider making upgrades or improvements, such as fresh paint, new flooring, or updated appliances. You can also highlight any unique features or amenities that your property offers, such as a spacious backyard, a pool, or proximity to local shopping etc. ',
      id: uuidv4(),
    },
    {
      question: 'What should be included in a rental agreement?',
      answer:
        'A rental agreement should include the names of the landlord and tenant, the address of the property, the rental amount and payment terms, the security deposit amount, the length of the rental term, and the rights and responsibilities of both the landlord and tenant. Other important provisions may include rules for pets, guests, and subleasing, as well as responsibilities for utilities, maintenance, and repairs. ',
      id: uuidv4(),
    },
  ],
  [
    {
      question:
        'What happens when a rental agreement is reached on the platform?',
      answer:
        'Legal agreement is signed on the platform and payment is made for the property',
      id: uuidv4(),
    },
    {
      question:
        'Is there a support team available to assist me with any questions or concerns?',
      answer:
        'Our service agents are available from 9am - 5pm WAT to assist with any queries you may have. Please email us at hello@krent.space or call +234-9118008008',
      id: uuidv4(),
    },
    {
      question:
        'What happens when a rental agreement is reached on the platform?',
      answer:
        'Legal agreement is signed on the platform and payment is made for the property',
      id: uuidv4(),
    },

    {
      question: 'What types of properties are listed on your website?',
      answer:
        'Our website features listings for various types of properties, including apartments, houses, condos, townhouses, and more.',
      id: uuidv4(),
    },
    {
      question: 'How often are new listings added to your website?',
      answer:
        'New listings are added to our website regularly, so be sure to check back often for the latest options.',
      id: uuidv4(),
    },
    {
      question: 'How do I know if a property is still available?',
      answer:
        'You can check the availability of a property by contacting the listing agent directly through our website.',
      id: uuidv4(),
    },

    {
      question: 'Are virtual tours available for all listings on your website?',
      answer:
        'Not all listings on our website may have virtual tours available, but many do. You can check the listing page for information on virtual tours.',
      id: uuidv4(),
    },
    {
      question: 'Can I schedule a viewing for a property I am interested in?',
      answer:
        'Yes, you can schedule a viewing for a property you are interested in by clicking on the request a physical tour on the property listing page',
      id: uuidv4(),
    },
    {
      question: 'Are there any fees for using your website to find a property?',
      answer:
        'There are no fees for using our website to search for properties.',
      id: uuidv4(),
    },
  ],
];
