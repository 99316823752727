import React, { useEffect, useState } from 'react'

// import { InputComponent } from '@shared/forms';
import { CycleToInterval } from '@utils/constants'
import { Modal } from '@shared/controls/Modal'
import { useSessionStorage } from '../../../hooks'
import ktoast from '@services/toast.service'
import { startPropertyTransactions } from '@services/krent.service'
import { Link, useNavigate } from 'react-router-dom'
// import {initializeApp} from 'firebase/app';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  // getFirestore,
  collection,
  // addDoc,
  serverTimestamp,
  Timestamp,
  setDoc,
  doc,
} from 'firebase/firestore'
// import PropertyVideo from '@shared/Video';
import customFetch from '@services/axio'
import { firestore } from '@services/firebase.service'
import { formatDate } from '@utils/helpers'
interface DateRange {
  start: Date
  end: Date
}
type Props = {
  id: string
  owner: string
  currency: string
  price: number
  type: 'long lease' | 'sale' | 'shortlet'
  duration: string
  ownerName?: string
  virtualTour?: string
  cautionFee?: string
}

export const PriceCard: React.FC<Props> = ({
  id,
  owner,
  ownerName,
  price,
  type,
  duration,
  currency,
  virtualTour,
  cautionFee,
}: Props) => {
  const [user] = useSessionStorage('user', {})
  const [loading, setLoading] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<any | null>(null)
  const [dateTo, setDateTo] = useState<any | null>(null)
  // const [dateFrom, setDateFrom] = useState(new Date().toDateString());
  // const [dateTo, setDateTo] = useState(new Date().toDateString());
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalType, setModalType] = useState<string>('login')
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [kyc, setKyc] = useState<any>('')
  const navigate = useNavigate()
  const [normalDays, setNormalDays] = useState(0)
  const [peakDays, setPeakDays] = useState(0)
  const [normalPrice, setNormalPrice] = useState(0)
  const [peakPrice, setPeakPrice] = useState(0)
  const [normalDateRanges, setNormalDateRanges] = useState<DateRange[]>([])
  const [peakDateRanges, setPeakDateRanges] = useState<DateRange[]>([])

  const datePickerMinDate = new Date()
  const messageCollections: any = process.env['REACT_APP_FIREBASE_COLLECTION']

  useEffect(() => {
    if (dateFrom && dateTo) {
      const start = new Date(dateFrom)
      const end = new Date(dateTo)

      let normalDaysCount = 0
      let peakDaysCount = 0
      let currentNormalRange = null
      let currentPeakRange = null
      const normalRanges = []
      const peakRanges = []

      const diffInDays = Math.ceil(
        (end.getTime() - start.getTime()) / (1000 * 3600 * 24),
      )

      for (let i = 0; i < diffInDays; i++) {
        const current = new Date(start)
        current.setDate(start.getDate() + i)

        const month = current.getMonth()
        const day = current.getDate()
        const isPeakDay =
          (month === 11 && day >= 1) || (month === 0 && day <= 15)

        if (isPeakDay) {
          peakDaysCount++
          if (!currentPeakRange) {
            currentPeakRange = {
              start: new Date(current),
              end: new Date(current),
            }
            currentPeakRange.end.setDate(current.getDate() + 1)
          } else {
            currentPeakRange.end = new Date(current)
            currentPeakRange.end.setDate(current.getDate() + 1)
          }
          if (currentNormalRange) {
            normalRanges.push(currentNormalRange)
            currentNormalRange = null
          }
        } else {
          normalDaysCount++
          if (!currentNormalRange) {
            currentNormalRange = {
              start: new Date(current),
              end: new Date(current),
            }
            currentNormalRange.end.setDate(current.getDate() + 1)
          } else {
            currentNormalRange.end = new Date(current)
            currentNormalRange.end.setDate(current.getDate() + 1)
          }
          if (currentPeakRange) {
            peakRanges.push(currentPeakRange)
            currentPeakRange = null
          }
        }
      }

      if (currentNormalRange) normalRanges.push(currentNormalRange)
      if (currentPeakRange) peakRanges.push(currentPeakRange)

      setNormalDateRanges(normalRanges)
      setPeakDateRanges(peakRanges)
      setNormalDays(normalDaysCount)
      setPeakDays(peakDaysCount)
      setNormalPrice(normalDaysCount * price)
      setPeakPrice(peakDaysCount * (price * 1.4))
    } else {
      setNormalDays(0)
      setPeakDays(0)
      setNormalPrice(0)
      setPeakPrice(0)
      setNormalDateRanges([])
      setPeakDateRanges([])
    }
  }, [dateFrom, dateTo, price])

  const formatDateRange = (range: any) => {
    const formatToDateString = (date: Date) => {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }
    return `${formatToDateString(range.start)} - ${formatToDateString(
      range.end,
    )}`
  }

  const formatCurrency = (value: any) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  // Function to calculate min date
  const handleMinDate = (selectedDate: Date | null) => {
    let minDate
    // Calculate a future date (e.g., one day ahead) and set it as the min date
    if (selectedDate) {
      minDate = new Date(selectedDate)
      minDate.setDate(minDate.getDate() + 1)
      return minDate
    }
    minDate = new Date()
    return minDate
  }

  const handleLongLeaseMinDate = (selectedDate: Date | null) => {
    let minDate
    // Calculate a date one year ahead and set it as the min date
    if (selectedDate) {
      minDate = new Date(selectedDate)
      minDate.setFullYear(minDate.getFullYear() + 1)
      return minDate
    }
    // If no date is selected, use the current date as the base and set the min date to one year ahead
    minDate = new Date()
    minDate.setFullYear(minDate.getFullYear() + 1)
    return minDate
  }

  const handleKyc = () => {
    if (kyc.status === 'APPROVED') {
      bookTour()
    } else {
      setModalType('kyc')
      setShowModal(true)
      setLoading(false)
    }
  }

  const bookTour = () => {
    if (user && user?.id) {
      setLoading(true)

      const formattedDateFrom = formatDate(dateFrom)
      const formattedDateTo = formatDate(dateTo)

      startPropertyTransactions({
        listing: id,
        arrival_date: formattedDateFrom,
        departure_date: formattedDateTo,
      })
        .then((d: any) => {
          try {
            setDoc(
              doc(collection(firestore, messageCollections), d?.data?.id),
              {
                meta: {
                  request: {
                    id: d?.data?.id,
                    property: id,
                    request_type: type,
                    arrival_date: dateFrom,
                    departure_date: dateTo,
                  },
                  sent_by: {
                    name:
                      user?.prefferredName ||
                      `${user?.firstname} ${user?.lastname}`,
                    photoUrl: user?.photo,
                  },
                  owner: {
                    name: ownerName,
                  },
                },
                chats: [
                  {
                    from_uid: user?.id,
                    to_uid: owner,
                    type: 'text',
                    value: "I'm interested in your property",
                    createdAt: Timestamp.now(),
                  },
                ],
                read: false,
                members: [owner, user?.id],
                createdAt: serverTimestamp(),
              },
            ).then(() => {
              ktoast.success(
                'Your booking has been logged successfully. The Property owner will be notified of your interest.',
              )
              setSuccess(
                'Your booking has been logged successfully. The Property owner will be notified of your interest.',
              )
              setTimeout(() => {
                navigate(`/message/${d?.data.id}`)
              }, 4000)
            })
          } catch (e) {
            console.error('Error adding document: ', e)
          }
        })
        .catch((err) => {
          ktoast.error(err.errors[0].message)
          setError(err.errors[0].message)
          setTimeout(() => {
            setError('')
          }, 3000)
        })
        .finally(() => {
          setLoading(false)
        })
      // Book
      setTimeout(() => {
        setLoading(false)
      }, 2500)
    } else {
      setShowModal(true)
      setLoading(false)
    }
  }

  const getKycStatus = async () => {
    try {
      const resp = await customFetch.get('/users/kyc')
      setKyc(resp?.data?.data)
    } catch (error:any) {
      setKyc(error?.response?.data?.errors[0]?.message)
    }
  }
  useEffect(() => {
    getKycStatus()
  }, [user])

  return (
    <React.Fragment>
      <div className="border-1 rounded border-purple">
        <div className="d-flex flex-column p-4 h-100">
          <div className="d-flex flex-column">
            <p className="light-text">
              {type === 'long lease'
                ? 'Rent'
                : type === 'shortlet'
                ? 'Shortlet'
                : 'Sale'}{' '}
              Price
            </p>
            <div className=" d-flex d-md-block d-lg-flex   justify-content-between align-items-center mt-neg">
              <p className="fs-12">
                <span className="fs-22 fw-bolder">
                  {currency}
                  {formatCurrency(price)}
                </span>
                {type !== 'sale' && <span> / {CycleToInterval(duration)}</span>}
              </p>
              <p>
                {type === 'sale' && (
                  <span className="badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-custom-royal bg-custom-royal bg-opacity-10 border border-success border-opacity-10 rounded-pill">
                    FOR SALE
                  </span>
                )}
                {type === 'long lease' && (
                  <span className="badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill">
                    RENTAL
                  </span>
                )}
                {type === 'shortlet' && (
                  <span className="badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-pill">
                    SHORT LET
                  </span>
                )}
              </p>
            </div>
          </div>

          <hr />

          <div className="">
            <h5 className="fw-bold mb-2">
              {type === 'shortlet'
                ? 'Reservations'
                : type === 'long lease'
                ? 'Rent Duration'
                : 'Contact Owner'}
            </h5>
            {
              success && <span className="fs-13 text-success">{success}</span>

              // <span className='fs-13 text-danger'>
              //   To book a physical tour or enquire about this property, please
              //   select your tentative dates of check in and check out for the
              //   property owner to confirm if this property will be available
              //   between the choosen dates.
              // </span>'
            }
            {/* {error && <span className='fs-13 text-danger'>{error}</span>}
            <InputComponent
              type='date'
              placeholder='Select tour date'
              icon='Calendar'
              value={dateFrom}
              from={type === 'shortlet' ? 'Check In' : 'From'}
              change={(e) => {
                setDateFrom(e.target.value);
              }}
              min={datePicker}
            />
            <InputComponent
              type='date'
              placeholder='Select tour date'
              icon='Calendar'
              value={dateTo}
              to={type === 'shortlet' ? 'Check Out' : 'To'}
              change={(e) => {
                setDateTo(e.target.value);
              }}
              min={datePicker}
            />
            <div className='d-grid my-4 gap-3'>
              <button
                className='btn btn-primary py-3 rounded-pill'
                disabled={loading}
                onClick={() => {
                  bookTour();
                }}
              >
                {loading ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  'I am interested'
                )}
              </button>
              {virtualTour && (
                <button
                  className='btn btn-outline-primary py-3 rounded-pill fw-bold fs-14'
                  onClick={() => {
                    setModalType('tour');
                    setShowModal(true);
                  }}
                >
                  View Virtual Tour
                </button>
              )}
            </div> */}

            {/* Display the error message */}
            {error && <span className="fs-13 text-danger">{error}</span>}

            {/* Date From Input */}
            {type !== 'sale' && (
              <div className="form-group m-1">
                <label className="form-label fs-14">
                  {type === 'shortlet' ? 'Check In' : 'From'}
                </label>
                <div className="input-group">
                  <span className="input-group-text input-group-text-0">
                    <i className={`iconly-Calendar`}></i>
                  </span>
                  <div className="form-control form-control-0   border-start-0">
                    <DatePicker
                      selected={dateFrom}
                      onChange={(date) => setDateFrom(date)}
                      placeholderText="dd-mm-yyyy"
                      className="form-control form-control-0  border-0"
                      minDate={datePickerMinDate}
                      showYearDropdown
                      showMonthDropdown
                      // showTimeSelect
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Date To Input */}
            {type !== 'sale' && (
              <div className="form-group mb-3">
                <label className="form-label fs-14">
                  {type === 'shortlet' ? 'Check Out' : 'To'}
                </label>
                <div className="input-group">
                  <span className="input-group-text input-group-text-0">
                    <i className={`iconly-Calendar`}></i>
                  </span>
                  <div className="form-control form-control-0   border-start-0">
                    <DatePicker
                      selected={dateTo}
                      onChange={(date: any) => setDateTo(date)}
                      placeholderText="dd-mm-yyyy"
                      className="form-control form-control-0   border-0"
                      minDate={
                        type === 'long lease'
                          ? handleLongLeaseMinDate(dateFrom)
                          : handleMinDate(dateFrom)
                      }
                      showYearDropdown
                      showMonthDropdown
                      disabled={!dateFrom}
                      // showTimeSelect
                      dateFormat="dd-MM-yyyy"
                      // dateFormat='yyyy-MM-dd'
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Button */}
            {type === 'shortlet' && dateFrom && dateTo ? (
              <div className="">
                <div className="d-flex justify-content-between text-center gap-1">
                  <h6 className="text-gray-600 mb-1">Days</h6>
                  <h6 className="text-gray-600 mb-1">Date ranges</h6>
                  <h6 className="text-gray-600 mb-1">Total price</h6>
                </div>
                <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
                  {normalDays > 0 && (
                    <p className="fs-12 m-0">{normalDays} normal days</p>
                  )}
                  <div className="flex flex-col space-y-1">
                    {normalDateRanges.map((range, index) => (
                      <div key={`normal-${index}`} className="fs-12">
                        {formatDateRange(range)}
                      </div>
                    ))}
                  </div>
                  {normalDays > 0 && (
                    <p className="fs-12 m-0">
                      {currency}
                      {formatCurrency(normalPrice)}
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
                  {peakDays > 0 && (
                    <p className="fs-12 m-0">{peakDays} peak days</p>
                  )}
                   <div className="flex flex-col space-y-1">

                   <div className="flex flex-col space-y-1">

                  {peakDateRanges.map((range, index) => (
                    <p key={`peak-${index}`} className="fs-12 m-0">
                      {formatDateRange(range)}
                    </p>
                  ))}
                  </div>
                  </div>
                  {peakDays > 0 && (
                    <p className="fs-12 m-0">
                      {currency}
                      {formatCurrency(peakPrice)}
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
                  <p className="fs-12 m-0">Caution fee</p>

                  <p className="fs-12 m-0">
                    {currency}
                    {cautionFee}
                  </p>
                </div>

                <div className="d-flex justify-content-between my-2 scrollable-range">
                  <h5>Total price</h5>
                  <h5>
                    {currency}
                    {formatCurrency(
                      normalPrice + peakPrice + (cautionFee || '0'),
                    )}
                  </h5>
                </div>
              </div>
            ) : null}
            <div className="d-grid my-4 gap-3">
              <button
                className="btn btn-primary py-3 rounded-pill"
                disabled={
                  loading ||
                  (!dateFrom && type !== 'sale') ||
                  (!dateTo && type !== 'sale') ||
                  (dateFrom && dateTo && dateFrom > dateTo)
                }
                onClick={() => {
                  // setLoading(true);
                  handleKyc()
                }}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  'I am interested'
                )}
              </button>
              {virtualTour && (
                <button
                  onClick={() => {
                    setModalType('tour')
                    setShowModal(true)
                  }}
                  className="btn btn-outline py-3 rounded-pill fw-bold fs-14 text-primary"
                >
                  <i className="fas fa-binoculars mr-1"></i>
                  View Virtual Tour
                </button>
              )}
            </div>

            {type !== 'shortlet' && (
              <p className="text-secondary fs-12">
                Requesting a physical tour will notify the person who listed
                this space. It’s free of charge.
              </p>
            )}
          </div>
        </div>
      </div>

      {modalType === 'login' && (
        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          size={400}
          position="top"
        >
          <div className="row px-4 py-1">
            <h5 className="fw-bolder mb-2">Log In to Continue</h5>
            <p className="fs-14 mb-4">
              You need to login or register to do this
            </p>
            <div className="d-flex gap-2 justify-content-between mb-3">
              <Link
                to={{ pathname: '/sign-in/', search: 'utm_source=modal' }}
                className="btn btn-primary rounded-pill py-3 px-5"
              >
                Log In
              </Link>
              <Link
                to={{ pathname: '/get-started/', search: 'utm_source=modal' }}
                className="btn btn-outline-dark rounded-pill py-3 px-5 fw-bold"
              >
                Register
              </Link>
            </div>
          </div>
        </Modal>
      )}
      {modalType === 'kyc' && (
        <Modal
          show={showModal}
          onClose={() => {
            setShowModal(false)
            setModalType('login')
          }}
          size={400}
          position="top"
        >
          <div className="row px-4 py-1">
            <h5 className="fw-bolder mb-2">{`${
              kyc?.status === 'PENDING' ? 'Pending KYC Approval' : 'Pending KYC'
            }`}</h5>
            <p className="fs-14 mb-4">
              {`${
                kyc?.status === 'PENDING'
                  ? 'Your KYC is pending approval. Please contact Krent on +2349118008008 if you didnt get a response before completing this transaction'
                  : 'You need to complete your KYC before your can complete this transaction.'
              }`}
            </p>
            <div className="d-flex gap-2 justify-content-between mb-3">
              {kyc?.status !== 'PENDING' ? (
                <Link
                  to={{ pathname: '/settings/' }}
                  className="btn btn-primary rounded-pill py-2 px-3"
                >
                  Add Now
                </Link>
              ) : (
                <div></div>
              )}
              <button
                onClick={() => {
                  setModalType('login')
                  setShowModal(false)
                  bookTour()
                }}
                className="btn btn-outline-dark rounded-pill py-2 px-3 fw-bold"
              >
                Continue
              </button>
            </div>
          </div>
        </Modal>
      )}

      {modalType === 'tour' && (
        <Modal
          show={showModal}
          onClose={() => {
            setShowModal(false)
            setTimeout(() => {
              setModalType('login')
            }, 500)
          }}
          size={900}
          position="top"
        >
          {/* <div className='row px-4 py-1'>
            <div className='d-flex flex-column mb-5'>
              <h3 className='fs-5 fw-bold my-4'>Virtual Tour</h3>

              <div className='embed-responsive embed-responsive-16by9'>
                <iframe
                  width='100%'
                  height='380'
                  src={
                    virtualTour
                      ? virtualTour
                      : 'https://my.matterport.com/show/?m=BswfThZ38kh'
                  }
                  title='Virtual Tour'
                  frameBorder={0}
                  allow='vr'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div> */}
          <iframe
            title="Virtual Tour"
            src={virtualTour}
            width="100%"
            height="700px"
            allowFullScreen
          ></iframe>
        </Modal>
      )}
    </React.Fragment>
  )
}
