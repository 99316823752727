import Select from 'react-select';

// import { ImageUploadCard } from '@shared/cards';
// import { PrimaryButton } from '@shared/buttons';
// import { ViewingDateComponent } from '@shared/forms';

// import map from '../../../assets/images/map.png';
import { useEffect, useState } from 'react';
import {
  getPropertyKinds,
  getPropertySpaces,
  getCategories,
  updateProperty,
  deleteProperty,
  addProperImages,
  getPropertyAmenities,
} from '@services/krent.service';
import { v4 as uuidv4 } from 'uuid';
import { PropertyKind, PropertySpace } from '@interfaces/properties.interface';
import { BaseApiResponse, PaginatedResponse } from '@interfaces/app.interface';
import ktoast from '@services/toast.service';
import { Modal } from '@shared/controls/Modal';
import { PrimaryButton } from '@shared/buttons';
import { useNavigate } from 'react-router-dom';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  StandaloneSearchBox,
  // DirectionsRenderer,
} from '@react-google-maps/api';
// import map from '../../../assets/images/map.png';
import updateArray from '@utils/updateArray';
// import { amenitiesData } from '../data';
// export function modalStep1({
// 	viewingDates,
// 	setViewingDates,
// 	setModalIndex,
// }: any) {
// 	const removeViewingDate = (key: number) => {
// 		setViewingDates(
// 			viewingDates.filter((_: any, i: number) => {
// 				console.log(i, key);
// 				return i !== key;
// 			})
// 		);
// 	};

// 	console.log(viewingDates);

// 	return (
// 		<div>
// 			<h6 className='fw-bold fs-20'>One last thing - Set Up Viewing Dates</h6>
// 			<p className='light-text fs-14'>
// 				Set up when the property will be available for tenants to book an
// 				inspection
// 			</p>

// 			{viewingDates}

// 			<div className='col-12'>
// 				<PrimaryButton
// 					icon='plus'
// 					text='Add Day'
// 					type='outline'
// 					color='#1073CE'
// 					click={(e) => {
// 						e.preventDefault();
// 						setViewingDates([
// 							...viewingDates,
// 							<ViewingDateComponent
// 								key={viewingDates.length + 1}
// 								remove={() => removeViewingDate(viewingDates.length)}
// 							/>,
// 						]);
// 					}}
// 				/>
// 			</div>
// 			<div className='col-4'>
// 				<PrimaryButton
// 					text='Save'
// 					type='primary'
// 					click={() => setModalIndex(1)}
// 				/>
// 			</div>
// 		</div>
// 	);
// }

// export function modalStep2({ setShowModal, setModalIndex }: any) {
// 	return (
// 		<div>
// 			<h6 className='fw-bold fs-20'>Are you ready to publish this property?</h6>
// 			<p className='light-text'>
// 				Once you publish this property, Krent will review your listing and your
// 				listing would go live once it is approved.
// 			</p>

// 			<div className='row'>
// 				<div className='col-6'>
// 					<PrimaryButton text='Publish' type='primary' />
// 				</div>
// 				<div className='col-6'>
// 					<PrimaryButton
// 						text='Back'
// 						type='outline'
// 						click={() => setModalIndex(0)}
// 					/>
// 				</div>
// 				<div className='col-12'>
// 					<PrimaryButton
// 						text='Do this later'
// 						type='outline'
// 						click={() => setShowModal(false)}
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

// export function Step1(
// 	{
// 		setPropertyType,
// 		setUserType,
// 		onNext ,
// 		title, setTitle,
// 	}: any) {

// 	const handleOnSubmit = (e: any) => {
// 		console.log(e.target)
// 		e.preventDefault();
// 		onNext()
// 	}

// 	return (
// 		<form className="needs-validation" noValidate onSubmit={handleOnSubmit}>
// 			<div className='row'>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>Who are you?</label>
// 						<select className='form-select rounded-pill py-3 px-4' required onChange={(e) => setUserType(e.target.value)}>
// 							<option value="">Select An Option</option>
// 							<option value='agent'>House Agent</option>
// 							<option value='landlord'>Landlord</option>
// 						</select>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>
// 							What type of property are you trying to sell?
// 						</label>
// 						<select className='form-select rounded-pill py-3 px-4' onChange={(e) => { setPropertyType(e.target.value); }} required>
// 							<option value=''>Select An Option</option>
// 							<option value='apartment'>Apartment</option>
// 							<option value='house'>House</option>
// 							<option value='land'>Land</option>
// 						</select>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>Why are you selling?</label>
// 						<input type="text" className="form-control rounded-pill py-3 px-4" required defaultValue={title} onChange={(e) => setTitle(e.target.value)} placeholder="Why are you selling ? "/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>
// 							Is the property already listed elsewehere for sale?
// 						</label>
// 						<select className='form-select rounded-pill py-3 px-4' required>
// 							<option value="">Select An Option</option>
// 							<option value='yes'>Yes</option>
// 							<option value='no'>No</option>
// 						</select>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>When did you put it on sale?</label>
// 						<input
// 							className='form-control rounded-pill py-3 px-4' required={false}
// 							type='date'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>
// 							Who is your ideal net sales person?
// 						</label>
// 						<select className='form-select rounded-pill py-3 px-4' required>
// 							<option value=''>Select An Option</option>
// 							<option value='apartment'>House Agent</option>
// 							<option value='two'>Landlord</option>
// 							<option value='three'>Three</option>
// 						</select>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-4'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>
// 							When do you want to close the sale?
// 						</label>
// 						<input
// 							className='form-control rounded-pill py-3 px-4' required
// 							type='date'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 mt-2 mb-5'>
// 					<div className='d-flex flex-row align-items-start gap-2'>
// 						<input type='checkbox' className='form-check-input' required />
// 						<p className='fs-14 m-0'>
// 							By proceeding I agree with Krent’s
// 							<span className='text-link'>
// 								Terms of Service &amp; Privacy Policy.
// 							</span>
// 						</p>
// 					</div>
// 				</div>
// 				<div className='col-12 text-end m-2'>
// 					<button className="btn btn-outline btn-dark py-3 px-5" type="submit">Continue</button>
// 				</div>
// 			</div>
// 		</form>
// 	);
// }

export function Step1({
  onNext,
  propertyName,
  setPropertyName,
  propertyDesc,
  setPropertyDesc,
}: any) {
  const [error] = useState<string>('Title must be minimum of 10 characters');
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form className='needs-validation' noValidate onSubmit={handleOnSubmit}>
      <div className='form-group mb-4 '>
        <label className='fw-bold mb-2'>
          What’s the name of your property?
        </label>
        <input
          type='text'
          className='form-control rounded-pill fs-14 py-3 px-4 bg-purple'
          placeholder='Enter property name'
          defaultValue={propertyName}
          onChange={(e) => {
            setPropertyName(e.target.value);
          }}
          required
        />
        {propertyName && propertyName?.length! < 10 ? (
          <span className='text-danger mt-1 ml-3 d-block'>{error}</span>
        ) : (
          ''
        )}
      </div>

      <div className='form-group'>
        <label className='fw-bold mb-2'>Describe your property?</label>
        <textarea
          className='form-control border-round-xl py-3 px-4 fs-14 bg-purple'
          required
          placeholder='Enter a short description of this property'
          rows={6}
          maxLength={500}
          value={propertyDesc}
          onChange={(e) => setPropertyDesc(e.target.value)}
        ></textarea>
      </div>

      <div className='col-12 text-end m-2'>
        <button
          className='btn btn-outline btn-dark py-3 px-5'
          type='submit'
          disabled={propertyName.length < 10}
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step2({
  propertyType,
  setPropertyType,
  propertyCategory,
  setPropertyCategory,
  onNext,
  onPrev,
  affiliation,
  setAffiliation,
}: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [kinds, setKinds] = useState<PropertyKind[]>([]);
  const [categories, setCategories] = useState<any>([]);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);

    getPropertyKinds(abortController.signal)
      .then((resp: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
        setKinds(resp.data);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });

    getCategories(abortController.signal)
      .then((res: any) => {
        setCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const propertyAffiliation = [
    { title: 'Property owner', id: 1 },
    { title: 'Direct agent', id: 2 },
    { title: 'Indirect agent', id: 3 },
  ];
  return (
    <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
      <div className='form-group mb-4'>
        <h4 className='fs-18 fw-bold mb-3'>Property Type</h4>
        <label className='fw-bold mb-2'>What type of property is this?</label>
        <select
          required
          className='form-select rounded-pill py-3 px-4 bg-purple'
          defaultValue={propertyType}
          onChange={(e) => {
            setPropertyType(e.target.value);
          }}
        >
          <option value=''>Select An Option</option>
          {kinds.map((d) => {
            return (
              <option key={d.id} value={d.id}>
                {d.title}
              </option>
            );
          })}
        </select>
      </div>

      <div className='form-group mb-4'>
        <label className='fw-bold mb-2'>What is the property category?</label>
        <select
          required
          className='form-select rounded-pill py-3 px-4 bg-purple'
          defaultValue={propertyCategory}
          onChange={(e) => {
            setPropertyCategory(e.target.value);
          }}
        >
          <option value=''>Select An Option</option>
          {categories.map((d: any) => {
            return (
              <option key={d.id} value={d.id}>
                {d.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className='form-group mb-4'>
        <label className='fw-bold mb-2'>
          What is your affiliation with this property?
        </label>
        <select
          required
          className='form-select rounded-pill py-3 px-4 bg-purple'
          defaultValue={affiliation}
          onChange={(e) => {
            setAffiliation(e.target.value);
          }}
        >
          <option value=''>Select An Option</option>
          {propertyAffiliation.map((d: any) => {
            return (
              <option key={d.id} value={d.title}>
                {d.title}
              </option>
            );
          })}
        </select>
      </div>
      {/* {propertyType && propertyType !== 'Select An Option' && (
				<div className='border border-round bg-purple p-3'>
					{propertyType === 'apartment' && (
						<p className='fs-14 m-0'>
							An apartment is a suite of rooms forming one residence, typically
							in a building containing a number of these.
						</p>
					)}
				</div>
			)} */}

      <div className='col-12 text-end m-2'>
        <button type='button' className='btn-el fw-bold mr-3' onClick={onPrev}>
          Go Back
        </button>
        <button
          disabled={loading}
          className='btn btn-outline btn-dark py-3 px-5'
          type='submit'
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step3({ spaceType, setSpaceType, onNext, onPrev }: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [spaces, setSpaces] = useState<PropertySpace[]>([]);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    getPropertySpaces(abortController.signal)
      .then((resp: BaseApiResponse<PropertySpace[], PaginatedResponse>) => {
        setSpaces(resp.data);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
      <div className='form-group mb-4'>
        <h4 className='fs-18 fw-bold mb-3'>Space Details</h4>
        <label className='fw-bold mb-2'>
          What type of space will you provide to your customers?
        </label>

        <div className='row'>
          {spaces.map((d) => (
            <div key={d.id} className='col-6'>
              <div className='form-check mb-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value={d.id}
                  name='space[]'
                  onChange={(e) => setSpaceType(e.target.value)}
                  checked={spaceType === d.id}
                />
                <label className='form-check-label light-text'>{d.title}</label>
              </div>
            </div>
          ))}
          {/* <div>
						{spaceType && spaceType !== '' && (
							<div className='border border-round bg-purple p-3'>
								
									<p className='fs-14 m-0'>
										An entire space is a suite of rooms forming one residence,
										typically in a building containing a number of these.
									</p>
							</div>
						)}
					</div> */}
        </div>
      </div>

      <div className='col-12 text-end m-2'>
        <button className='btn-el fw-bold mr-3' onClick={onPrev}>
          Go Back
        </button>
        <button
          disabled={loading}
          className='btn btn-outline btn-dark py-3 px-5'
          type='submit'
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step4({
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
  floors,
  setFloors,
  area,
  setArea,
  sellReason,
  setSellReason,
  propertyCondition,
  setPropertyCondition,
  commonAreaCondition,
  setCommonAreaCondition,
  locationCondition,
  setLocationCondition,
  dateBuilt,
  setDateBuilt,
  onNext,
  onPrev,
}: any) {
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };
  return (
    <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
      <h4 className='fs-18 fw-bold mb-3 mb-4'>Additional Space Information</h4>
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Selling Reason?</label>
            <input
              type='text'
              className='form-control rounded-pill  px-4 py-3 bg-purple'
              placeholder='Enter selling reason'
              defaultValue={sellReason}
              onChange={(e) => setSellReason(e.target.value)}
              required
            ></input>
          </div>
        </div>
        <div className='col-12 col-md-6 mb-4 mb-md-0'>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>No Of Floors</label>
            <input
              type='number'
              className='form-control rounded-pill  px-4 py-3 bg-purple'
              placeholder='Enter a number'
              defaultValue={floors}
              onChange={(e) => setFloors(e.target.value)}
              required
            ></input>
          </div>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Size</label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter a dimension'
                defaultValue={area}
                onChange={(e) => setArea(e.target.value)}
                required
              />
              <select className='form-select rounded-pill px-4 py-3 border-left-0 bg-purple w-30'>
                <option>sqm</option>
                {/* <option>m</option> */}
              </select>
            </div>
          </div>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Property Condition</label>
            <div className='input-group'>
              <input
                type='number'
                min={1}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter a number'
                defaultValue={propertyCondition}
                onChange={(e) => setPropertyCondition(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='form-group'>
            <label className='fw-bold mb-2'>Location Condition</label>
            <div className='input-group'>
              <input
                type='number'
                min={1}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter a number'
                defaultValue={locationCondition}
                onChange={(e) => setLocationCondition(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>No Of Bedrooms</label>
            <input
              type='number'
              className='form-control rounded-pill px-4 py-3 bg-purple'
              placeholder='Enter a number'
              defaultValue={bedrooms}
              min={1}
              onChange={(e) => setBedrooms(e.target.value)}
              required
            ></input>
          </div>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>No Of Bathrooms</label>
            <input
              type='number'
              className='form-control rounded-pill px-4 py-3 bg-purple'
              placeholder='Enter a number'
              defaultValue={bathrooms}
              min={1}
              onChange={(e) => setBathrooms(e.target.value)}
              required
            ></input>
          </div>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Common Area Condition</label>
            <div className='input-group'>
              <input
                type='number'
                min={1}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter a number'
                defaultValue={commonAreaCondition}
                onChange={(e) => setCommonAreaCondition(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='form-group'>
            <label className='fw-bold mb-2'>Date Built</label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter a number'
                value={dateBuilt}
                onChange={(e) => setDateBuilt(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 text-end m-2'>
        <button className='btn-el fw-bold mr-3' onClick={onPrev}>
          Go Back
        </button>
        <button className='btn btn-outline btn-dark py-3 px-5' type='submit'>
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step5({
  marketPrice,
  setMarketPrice,
  idealPrice,
  setIdealPrice,
  annualFees,
  setAnnualFees,
  propertyTax,
  setPropertyTax,
  netSalePrice,
  setNetSalePrice,
  onNext,
  onPrev,
}: any) {
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
      <h4 className='fs-18 fw-bold mb-3 mb-4'>Pricing Information</h4>

      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Market Price</label>
            <div className='input-group d-flex flex-row gap-2'>
              <input
                type='number'
                defaultValue={marketPrice}
                min={1}
                onChange={(e) => setMarketPrice(e.target.value)}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter amount'
                required
              ></input>
            </div>
          </div>

          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Annual Fees</label>
            <div className='input-group d-flex flex-row gap-2'>
              <input
                type='number'
                defaultValue={annualFees}
                min={1}
                onChange={(e) => setAnnualFees(e.target.value)}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter amount'
                required
              />
            </div>
          </div>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Net Sale Amount</label>
            <div className='input-group d-flex flex-row gap-2'>
              <input
                type='number'
                defaultValue={netSalePrice}
                min={1}
                onChange={(e) => setNetSalePrice(e.target.value)}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter amount'
                required
              />
            </div>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Ideal Price</label>
            <div className='input-group d-flex flex-row gap-2'>
              <input
                type='number'
                defaultValue={idealPrice}
                min={1}
                onChange={(e) => setIdealPrice(e.target.value)}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter amount'
                required
              ></input>
            </div>
          </div>
          <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>Property Tax</label>
            <div className='input-group d-flex flex-row gap-2'>
              <input
                type='number'
                defaultValue={propertyTax}
                min={1}
                onChange={(e) => setPropertyTax(e.target.value)}
                className='form-control rounded-pill px-4 py-3 bg-purple w-60'
                placeholder='Enter amount'
                required
              ></input>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 text-end m-2'>
        <button className='btn-el fw-bold mr-3' onClick={onPrev}>
          Go Back
        </button>
        <button className='btn btn-outline btn-dark py-3 px-5' type='submit'>
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step6({
  // safetyFeatures,
  // setSafetyFeatures,
  // amenities,
  setAmenities,
  onNext,
  onPrev,
}: any) {
  const [allAmenities, setAllAmenities] = useState<any>();
  // const [amen,setAmen]=useState([])
  // const safetyFeaturesOpt = [
  //   { value: 'smoke', label: 'Smoke Alarm' },
  //   { value: 'first-aid', label: 'First Aid Kit' },
  // ];

  // const amenitiesOpt = [
  //   { value: 'gym', label: 'Gym' },
  //   { value: 'pool', label: 'Pool' },
  //   { value: 'sauna', label: 'Sauna' },
  // ];

  // useEffect(() => {
  //   setAllAmenities(amenitiesData);
  // }, []);

  useEffect(() => {
    const abortController = new AbortController();
    getPropertyAmenities(abortController.signal)
      .then((resp: any) => {
        setAllAmenities(resp.data);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  }, []);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    setAmenities(tempAmenities);
    onNext();
  };
  // const newAmenitiesOpt = allAmenities?.map((item: any) => {
  //   return {
  //     label: item.title,
  //     value: item.offers,
  //   };
  // });

  const tempAmenities: any = [];
  // const updateArray = (newItem: any, oldArray: any) => {
  //   let found = false;
  //   let index = 0;
  //   oldArray.map((item: any, i: number) => {
  //     if (item.title === newItem.title) {
  //       found = true;
  //       index = i;
  //     }
  //   });
  //   if (found) {
  //     oldArray[index] = newItem;
  //   } else {
  //     oldArray.push(newItem);
  //   }
  //   return oldArray;
  // };
  // function updateArray(newItem: any) {
  //   let found = false;
  //   let index = 0;
  //   nAmenities.map((item: any, i: number) => {
  //     if (item.title === newItem.title) {
  //       found = true;
  //       index = i;
  //     }
  //   });
  //   if (found) {
  //     nAmenities[index] = newItem;
  //   } else {
  //     nAmenities.push(newItem);
  //   }
  // }

  return (
    <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
      <h4 className='fs-18 fw-bold mb-4'>
        Let your customers know the features of your property
      </h4>
      {allAmenities?.map((f: any) => {
        const { offers, title, id } = f;
        const opt = offers.map((o: any) => {
          return { label: o, value: o };
        });
        return (
          <div className='form-group mb-4' key={id}>
            <label className='fw-bold mb-2'>{title}</label>
            <Select
              isMulti
              name={title}
              options={opt}
              className='basic-multi-select'
              classNamePrefix='form-select'
              onChange={(e: any) => {
                const newState = { icon: '', offers: [], title: '' };
                let m: any = [];
                for (let i = 0; i < e.length; i++) {
                  m.push(e[i].value);
                }
                const newObj = { ...newState, offers: m, title };

                updateArray(newObj, tempAmenities);
              }}
            />

            {/* <div className='row'>
              {nAmenities &&
                nAmenities.map((item: any, index: number) => (
                  <div className='col-6 col-md-4' key={index}>
                    <div className='border border-round-xl p-2 mt-2 text-center border-label'>
                      {item.label}
                    </div>
                  </div>
                ))}
            </div> */}
          </div>
        );
      })}

      {/* <div className='form-group'>
        <label className='fw-bold mb-2'>Select Amenities</label>
        <Select
          isMulti
          name='amenities'
          options={amenitiesOpt}
          className='basic-multi-select'
          classNamePrefix='form-select'
          onChange={(e) => setAmenities(e)}
        />

        <div className='row'>
          {amenities &&
            amenities.map((item: any) => (
              <div className='col-4 col-md-3'>
                <div className='border border-round-xl p-2 mt-2 text-center border-label'>
                  {item.label}
                </div>
              </div>
            ))}
        </div>
      </div> */}
      <div className='col-12 text-end m-2'>
        <button className='btn-el fw-bold mr-3' onClick={onPrev}>
          Go Back
        </button>
        <button className='btn btn-outline btn-dark py-3 px-5' type='submit'>
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step7({
  state,
  setState,
  apartment,
  setApartment,
  address,
  setAddress,
  city,
  setCity,
  country,
  setCountry,
  onPrev,
  onNext,
  showSuccessModal,
  // loading,
  // setLoading,
  setShowSuccessModal,
  handleCreate,
  setFlood_meter,
  setNeighbour_story,
  flood_meter,
  setGeo,
}: any) {
  // const [story,setStory]=useState<any>({})
  // const handleStoryChange=()=>{

  // }
  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    handleCreate();
  };
  // map start
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
    libraries: ['places'],
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 6.4297,
    lng: 3.4915,
  });
  // map end
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [autoCompleteCity, setAutoCompleteCity] = useState<any>(null);
  const [autoCompleteState, setAutoCompleteState] = useState<any>(null);
  const [story, setStory] = useState<any>();
  const [allStory, setAllStory] = useState<any>({});

  const onLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };
  const onLoadCity = (autocomplete: any) => {
    setAutoCompleteCity(autocomplete);
  };
  const onLoadState = (autocomplete: any) => {
    setAutoCompleteState(autocomplete);
  };
  const onStoryLoad = (autocomplete: any) => {
    setStory(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const newLat = autoComplete?.getPlace()?.geometry?.location?.lat();
      const newLng = autoComplete?.getPlace()?.geometry?.location?.lng();
      const newAdd = autoComplete?.getPlace()?.formatted_address;

      setCenter({ lat: newLat, lng: newLng });
      setAddress(newAdd);
      setGeo([newLat, newLng]);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };
  const onCityChanged = () => {
    if (autoCompleteCity !== null) {
      // console.log(autoCompleteCity.getPlace());

      const locality =
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes('sublocality_level_1')
          )?.long_name ||
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes('neighborhood')
          )?.long_name ||
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) => p.types.includes('locality'))
          ?.long_name ||
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes('administrative_area_level_1')
          )?.long_name;

      setCity(locality);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };
  const onStateChanged = () => {
    if (autoCompleteState !== null) {
      const state = autoCompleteState
        .getPlace()
        .address_components?.find((p: any) =>
          p.types.includes('locality')
        ).long_name;
      setState(state);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };
  const onStoryChanged = () => {
    if (story) {
      const result = story.getPlaces();

      const place = story.getPlaces()[0].types[0];

      // if (story.getPlaces()[0].types.includes('hospital')) {
      let temp: any = [];
      if (story.getPlaces()[0].types.includes(place)) {
        if (result.length > 4) {
          for (let i = 0; i < 4; i++) {
            const {
              business_status,
              formatted_address,
              geometry,
              icon,
              name,
              place_id,
              rating,
            } = result[i];
            temp.push({
              business_status,
              formatted_address,
              geometry,
              icon,
              name,
              place_id,
              rating,
            });
          }
        } else {
          for (let i = 0; i < result.length; i++) {
            const {
              business_status,
              formatted_address,
              geometry,
              icon,
              name,
              place_id,
              rating,
            } = result[i];
            temp.push({
              business_status,
              formatted_address,
              geometry,
              icon,
              name,
              place_id,
              rating,
            });
          }
        }
      }

      const newAllStory: any = {};

      newAllStory[place] = temp;
      setAllStory({ ...allStory, ...newAllStory });
      setNeighbour_story({ ...allStory, ...newAllStory });

      // console.log(story.getPlaces()[0]?.geometry?.location?.lat());
      // console.log(story.getPlaces()[0]?.geometry?.location?.lng());
      // console.log(allStory);
      // console.log(newAllStory);
    }

    // const k = Object.keys(allStory);
    // const x = k.map((i) => {
    //   return allStory[i];
    // });

    // setDetails();
    // console.log(x);
  };

  // console.log(x);
  // console.log(allStory);
  // console.log(details);
  const details = Object.entries(allStory);
  return (
    <>
      <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
        <h4 className='fs-18 fw-bold mb-4'>Location Details</h4>

        <div className='row'>
          <div
            className='col-12 mb-4'
            style={{ height: '15rem', position: 'relative' }}
          >
            {/* <img src={map} alt='' className='w-100 img-fluid' /> */}
            {/* <Gmap coordinate={[4.55207, 8.48268]} height={'15rem'} /> */}
            {isLoaded && (
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                // options={{
                //   zoomControl: false,
                //   streetViewControl: false,
                //   mapTypeControl: false,
                //   fullscreenControl: false,
                // }}
                onLoad={(map) => setMap(map)}
              >
                <Marker position={center} />
              </GoogleMap>
            )}
            <div
              className='me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 cursor-pointer'
              style={{ position: 'absolute', left: '30px', bottom: '15px' }}
            >
              <i
                className='fa fa-location-arrow fs-22 text-primary'
                onClick={() => {
                  map!.panTo(center);
                  map!.setZoom(15);
                }}
              ></i>
            </div>
          </div>
          <div className='col-12 mb-3 mb-md-5'>
            <div className='form-group'>
              <label className='fw-bold mb-2'>Enter Address</label>
              {isLoaded ? (
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type='text'
                    className='form-control border-round-xl bg-purple'
                    placeholder='Enter address'
                    autoComplete='new-password'
                    defaultValue={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  ></input>
                </Autocomplete>
              ) : (
                <input
                  type='text'
                  className='form-control border-round-xl bg-purple'
                  placeholder='Enter address'
                  autoComplete='new-password'
                  defaultValue={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  required
                ></input>
              )}
            </div>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <div className='form-group'>
              <label className='fw-bold mb-2'>Apartment No</label>

              <input
                type='text'
                className='form-control rounded-pill px-4 py-3 bg-purple'
                placeholder='Enter Apartment No'
                defaultValue={apartment}
                onChange={(e) => setApartment(e.target.value)}
                required
              ></input>
            </div>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <div className='form-group'>
              <label className='fw-bold mb-2'>City</label>
              {isLoaded ? (
                <Autocomplete
                  onLoad={onLoadCity}
                  onPlaceChanged={onCityChanged}
                >
                  <input
                    type='text'
                    className='form-control rounded-pill px-4 py-3 bg-purple'
                    placeholder='Enter City'
                    defaultValue={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  ></input>
                </Autocomplete>
              ) : (
                <input
                  type='text'
                  className='form-control rounded-pill px-4 py-3 bg-purple'
                  placeholder='Enter City'
                  defaultValue={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                ></input>
              )}
            </div>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <div className='form-group'>
              <label className='fw-bold mb-2'>State / Province</label>
              {isLoaded ? (
                <Autocomplete
                  onLoad={onLoadState}
                  onPlaceChanged={onStateChanged}
                >
                  <input
                    type='text'
                    className='form-control rounded-pill px-4 py-3 bg-purple'
                    placeholder='Enter State'
                    defaultValue={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    required
                  ></input>
                </Autocomplete>
              ) : (
                <input
                  type='text'
                  className='form-control rounded-pill px-4 py-3 bg-purple'
                  placeholder='Enter State'
                  defaultValue={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                ></input>
              )}
            </div>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <div className='form-group'>
              <label className='fw-bold mb-2'>Country</label>

              <select
                className='form-select rounded-pill px-4 py-3 bg-purple'
                defaultValue={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <option value=''>Select Country</option>
                <option value='Nigeria'>Nigeria</option>
              </select>
            </div>
          </div>
          <div className='col-12 col-md-6 mb-3'>
            <div className='form-group'>
              <label className='fw-bold mb-2'>Flood Risk</label>

              <select
                className='form-select rounded-pill px-4 py-3 bg-purple'
                defaultValue={flood_meter}
                onChange={(e) => {
                  setFlood_meter(e.target.value);
                }}
              >
                <option value=''>Select Flood Risk</option>
                <option value='low'>Low</option>
                <option value='medium'>Medium</option>
                <option value='high'>High</option>
              </select>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          {isLoaded && (
            <div className='col-12 col-md-6 '>
              <div className='form-group'>
                <label className='fw-bold mb-2'>Neighbourhood</label>
                <p className='fs-14 text-danger'>
                  {` Help people see the landmarks,point of interest,popular places
                  (Hospitals, supermarkets, busstops, night clubs etc..)
                  around this property. Type in the name of a popular place near
                  the street this property is located.eg 'hospitals near kofo
                  abayomi street' and select an option that matches your
                  search.Once you see a list with heading appear on the screen, the list you see has been marked and selected. You can go ahead and search for another place.`}
                </p>
                <StandaloneSearchBox
                  onLoad={onStoryLoad}
                  onPlacesChanged={onStoryChanged}
                >
                  <input
                    type='text'
                    placeholder='Eg. lounge near kofo abayomi street'
                    className='form-control rounded-pill px-4 py-3 bg-purple'
                  />
                </StandaloneSearchBox>
              </div>
            </div>
          )}
          {details &&
            details?.map((item: any, index: number) => {
              return (
                <div className='col-6 col-lg-2 mt-3' key={index}>
                  <div className=' fw-bold '>
                    {item[0].replace(/_/g, ' ').toUpperCase().slice(0, 10) +
                      'S'}
                  </div>
                  <div className=' light-text'>
                    {item[1]?.map((i: any) => {
                      return (
                        <div key={i.place_id}>
                          <span>{i.name}</span>;
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className='col-12 text-end m-2'>
          <button className='btn-el fw-bold mr-3' onClick={onPrev}>
            Go Back
          </button>
          <button className='btn btn-outline btn-dark py-3 px-5' type='submit'>
            Continue
          </button>
        </div>
      </form>

      <Modal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        dismissible={false}
      >
        <div className='col px-4'>
          <h6 className='fw-bold fs-20'>
            Property Created Successfully but not Published.
          </h6>

          <p className='light-text'>
            Kindly proceed to add images to your listing.
          </p>

          <div className='row'>
            <div className='col-12'>
              <PrimaryButton
                text='Continue'
                type='primary'
                click={() => {
                  setShowSuccessModal(false);
                  onNext();
                }}
              />
            </div>
            {/* <div className='col-6'>
              <PrimaryButton
                text='Do this later'
                type='outline'
                click={() => {
                 
                }}
              />
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export function Step8({
  videoUrl,
  setVideoUrl,
  tourUrl,
  setTourUrl,
  tourIntrest,
  setTourIntrest,
  onPrev,
  propertyId,
  loading,
  setLoading,
  propertyCreated,
}: any) {
  const [form, setForm]: any = useState({});
  const [coverImage1, setCoverImage1]: any = useState([]);
  const [otherImages1, setOtherImages1]: any = useState([]);
  const [floorPlans1, setFloorPlans1]: any = useState([]);

  const [coverUrlPath, setCoverUrlPath]: any = useState();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTour, setShowTour] = useState<boolean>(false);
  const [modalType, setModalType] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const value = e.target.files;
    const name = e.target.name;

    if (name === 'coverImage') {
      setCoverImage1(value);
      const [file] = value;
      setCoverUrlPath(URL.createObjectURL(file));
    }

    if (name === 'floorPlans') {
      const newPath = [];

      for (let i = 0; i < value.length; i++) {
        newPath.push({ id: `${uuidv4()}`, pic: value[i] });
      }

      setFloorPlans1([...floorPlans1, ...newPath]);
    }

    if (name === 'otherImages') {
      const newPath = [];
      for (let i = 0; i < value.length; i++) {
        newPath.push({ id: `${uuidv4()}`, pic: value[i] });
      }
      setOtherImages1([...otherImages1, ...newPath]);
    }

    setForm({ ...form, [name]: value });
  };

  const handleDelete = (id: string) => {
    const newData = otherImages1.filter((item: any) => item.id !== id);
    const newFloorData = floorPlans1.filter((item: any) => item.id !== id);
    setOtherImages1(newData);
    setFloorPlans1(newFloorData);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  const showM = (id: any) => {
    const formData = new FormData();

    for (let i = 0; i < otherImages1?.length; i++) {
      formData.append('otherImages', otherImages1[i].pic);
    }
    for (let i = 0; i < coverImage1?.length; i++) {
      formData.append('coverImage', coverImage1[i]);
    }
    for (let i = 0; i < floorPlans1?.length; i++) {
      formData.append('floorPlans', floorPlans1[i].pic);
    }

    addProperImages(id, formData)
      .then(() => {
        navigate(
          {
            pathname: '/listings/',
          },
          {
            state: {
              showToast: true,
              toastMessage: 'Listing uploaded successfully',
            },
            replace: true,
          }
        );
      })
      .catch((err) => {
        console.log(err);
        setShowModal(false);
        ktoast.error(
          'Unable to upload images.Please check the sizes and the format'
        );
        // ktoast.error(
        //   err.errors[0].message
        //     ? err.errors[0].message
        //     : 'Unable to upload images'
        // );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteProp = (id: any) => {
    deleteProperty(id)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const updateProp = (id: any, payload: {}) => {
    updateProperty(id, payload)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <form className='needs-validation' onSubmit={handleSubmit} noValidate>
        <div className='mb-4'>
          <h4 className='fs-18 fw-bold'>Let people see your space</h4>
          <p className='light-text d-none d-md-flex'>
            Upload Images of this space (1 Cover Image, upto 10 Other Images,
            upto 3 Floor Plans)
          </p>
        </div>

        <div className='row'>
          {/* {Object.values(selectedFiles).map((_, k) => {
          return <ImageUploadCard key={k} />;
        })} */}
          {/* coverImage */}
          <div className={`col-12 ${coverUrlPath ? 'mb-0' : 'mb-5'}`}>
            <input
              type='file'
              hidden
              accept='image/*'
              name='coverImage'
              id='coverImage'
              onChange={handleChange}
            />
            <label
              className='d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer'
              htmlFor='coverImage'
            >
              <i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
              <p className='m-0 fw-600'>Tap To Add a Cover Image</p>
            </label>
            {coverUrlPath && (
              <img
                src={coverUrlPath}
                alt='house'
                style={{ height: '4rem', width: '4rem', margin: '1rem' }}
              />
            )}
          </div>
          {/* otherImages */}
          <div
            className={`col-12 ${otherImages1.length !== 0 ? 'mb-0' : 'mb-5'}`}
          >
            <input
              type='file'
              hidden
              multiple
              accept='image/*'
              name='otherImages'
              id='otherImages'
              onChange={handleChange}
            />
            <label
              className='d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer'
              htmlFor='otherImages'
            >
              <i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
              <p className='m-0 fw-600'>Tap To Add Other Images</p>
            </label>
            {otherImages1.length < 5 && (
              <span className='text-danger d-block fs-12'>
                Minimum of 5 pictures are required to continue
              </span>
            )}
            {otherImages1 &&
              otherImages1?.map((item: any) => {
                return (
                  <div
                    className='position-relative uploadPic'
                    style={{
                      height: '4rem',
                      width: '4rem',
                      margin: '1rem',
                    }}
                    key={item.id}
                  >
                    <img
                      src={URL.createObjectURL(item.pic)}
                      alt='house'
                      className='h-100 w-100'
                    />
                    <div className='delete-container position-absolute  '>
                      <span
                        className='fa fa-times text-danger  cursor-pointer deletePic'
                        onClick={() => handleDelete(item.id)}
                      ></span>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* floorPlans */}
          <div
            className={`col-12 ${floorPlans1.length !== 0 ? 'mb-5' : 'mb-0'}`}
          >
            <input
              type='file'
              accept='image/*'
              hidden
              multiple
              name='floorPlans'
              id='floorPlans'
              onChange={handleChange}
            />
            <label
              className='d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer'
              htmlFor='floorPlans'
            >
              <i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
              <p className='m-0 fw-600'>Tap To Add Floor Plans</p>
            </label>
            {floorPlans1 &&
              floorPlans1?.map((item: any) => {
                return (
                  <div
                    className='position-relative uploadPic'
                    style={{
                      height: '4rem',
                      width: '4rem',
                      margin: '1rem',
                    }}
                    key={item.id}
                  >
                    <img
                      src={URL.createObjectURL(item.pic)}
                      alt='house'
                      className='h-100 w-100'
                    />
                    <div className='delete-container position-absolute  '>
                      <span
                        className='fa fa-times text-danger  cursor-pointer deletePic'
                        onClick={() => handleDelete(item.id)}
                      ></span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className='col-12 text-end m-2'>
          <button
            className='btn-el fw-bold mr-3'
            onClick={() => {
              onPrev();
              deleteProp(propertyId);
            }}
            disabled={propertyCreated}
          >
            Go Back
          </button>
          <button
            className='btn btn-outline btn-dark py-3 px-5'
            type='submit'
            disabled={otherImages1.length < 5}
          >
            Continue
          </button>
        </div>
      </form>
      {showModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className='col px-4'>
            <h6 className='fw-bold fs-20'>
              Are you ready to publish this property?
            </h6>
            <p className='light-text'>
              Once you publish this property, Krent will review your listing and
              your listing would go live once it is approved.
            </p>
            <p className='light-text'>
              If you have a video tour of this property please provide the link
              in the video tour input field below
            </p>
            <p className='light-text'>
              If you have a virtual tour of this property please provide the
              link in the virtual tour input field below. If you don't and would
              like Krent to take a virtual tour of this property, please check
              the box below.
            </p>
            <div className='row g-0 align-items-center mb-3 mt-5 '>
              <div className='col-12 form-check'>
                <input
                  className='form-check-input cursor-pointer'
                  name='tour intrest'
                  value={tourIntrest}
                  id='tour'
                  type='checkbox'
                  onChange={() => {
                    setTourIntrest((tourIntrest: any) => !tourIntrest);
                  }}
                />
                <label
                  htmlFor='tour'
                  className='form-check-label text-secondary fs-14 m-0'
                >
                  I would love Krent to take a virtual tour of this Listing{' '}
                </label>
                <span
                  className='text-primary cursor-pointer  d-block'
                  onClick={() => {
                    setModalType(true);
                    setShowTour(true);
                    setShowModal(false);
                  }}
                >
                  Click to see a sample
                </span>
              </div>
            </div>
            <div className='row my-5'>
              <div className='col-12 col-md-6 mb-4 mb-md-0'>
                <div className='form-group'>
                  <label className='fw-bold mb-2'>Video Tour (Optional)</label>
                  <input
                    type='text'
                    defaultValue={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    className='form-control rounded-pill px-4 py-3 bg-purple'
                    placeholder='Enter URL'
                  ></input>
                </div>
              </div>

              <div className='col-12 col-md-6'>
                <div className='form-group'>
                  <label className='fw-bold mb-2'>
                    Virtual Tour (Optional)
                  </label>
                  <input
                    type='text'
                    defaultValue={tourUrl}
                    onChange={(e) => {
                      e.preventDefault();
                      setTourUrl(e.target.value);
                    }}
                    className='form-control rounded-pill px-4 py-3 bg-purple'
                    placeholder='Enter URL'
                  ></input>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <PrimaryButton
                  text='Publish'
                  type='primary'
                  loading={loading}
                  click={() => {
                    setLoading(true);
                    showM(propertyId);
                    if (tourUrl || videoUrl || tourIntrest) {
                      updateProp(propertyId, {
                        virtual_tour_url: tourUrl,
                        video_url: videoUrl,
                        tourIntrest: tourIntrest,
                      });
                    }
                  }}
                />
              </div>
              <div className='col-6'>
                <PrimaryButton
                  text='Do this later'
                  type='outline'
                  click={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modalType && (
        <Modal
          show={showTour}
          onClose={() => {
            setShowTour(false);
            setShowModal(true);
            setTimeout(() => {
              setModalType(false);
            }, 500);
          }}
          size={600}
          position='top'
        >
          <div className='row px-4 py-1'>
            <div className='d-flex flex-column mb-5'>
              <h3 className='fs-5 fw-bold my-4'>Virtual Tour</h3>

              <div className='embed-responsive embed-responsive-16by9'>
                <iframe
                  width='100%'
                  height='380'
                  src='https://my.matterport.com/show/?m=BswfThZ38kh'
                  title='Virtual Tour'
                  frameBorder={0}
                  allow='vr'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

// export function Step8({
// 	videoUrl,
// 	setVideoUrl,
// 	tourUrl,
// 	setTourUrl,
// 	onPrev,
// 	onNext,
// }: any) {
// 	const [selectedFiles, setSelectedFiles] = useState<FormData>(new FormData());

// 	const handleOnSubmit = (e: any) => {
// 		console.table(selectedFiles.entries);
// 		e.preventDefault();
// 		onNext();
// 	};

// 	const onImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
// 		if (e.target.files && e.target.files.length > 0) {
// 			let fd = selectedFiles;
// 			fd.append('coverImage', e.target.files[0], e.target.files[0].name);
// 			setSelectedFiles(fd);
// 		}
// 		console.table(Object?.fromEntries(selectedFiles));
// 	};

// 	return (
// 		<form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
// 			<div className='mb-4'>
// 				<h4 className='fs-18 fw-bold'>Let people see your space</h4>
// 				<p className='light-text d-none d-md-flex'>
// 					Upload more Images of this space (5 images minimum)
// 				</p>
// 			</div>

// 			<div className='row'>
// 				{Object.values(selectedFiles).map((_, k) => {
// 					return <ImageUploadCard key={k} />;
// 				})}

// 				<div className='col-12'>
// 					<input
// 						type='file'
// 						id='selectFile'
// 						hidden
// 						onChange={onImageSelected}
// 					/>
// 					<label
// 						className='d-flex flex-row align-items-center justify-content-center border border-round p-2'
// 						htmlFor='selectFile'
// 					>
// 						<i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
// 						<p className='m-0 fw-600'>Tap To Add An Image</p>
// 					</label>
// 				</div>

// 				<div className='row my-5'>
// 					<div className='col-12 col-md-6 mb-4 mb-md-0'>
// 						<div className='form-group'>
// 							<label className='fw-bold mb-2'>Video Tour (Optional)</label>
// 							<input
// 								type='text'
// 								defaultValue={videoUrl}
// 								onChange={(e) => setVideoUrl(e.target.value)}
// 								className='form-control rounded-pill px-4 py-3 bg-purple'
// 								placeholder='Enter URL'
// 								required
// 							></input>
// 						</div>
// 					</div>

// 					<div className='col-12 col-md-6'>
// 						<div className='form-group'>
// 							<label className='fw-bold mb-2'>Virtual Tour (Optional)</label>
// 							<input
// 								type='text'
// 								defaultValue={tourUrl}
// 								onChange={(e) => setTourUrl(e.target.value)}
// 								className='form-control rounded-pill px-4 py-3 bg-purple'
// 								placeholder='Enter URL'
// 								required
// 							></input>
// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='col-12 text-end m-2'>
// 				<button className='btn-el fw-bold mr-3' onClick={onPrev}>
// 					Go Back
// 				</button>
// 				<button className='btn btn-outline btn-dark py-3 px-5' type='submit'>
// 					Continue
// 				</button>
// 			</div>
// 		</form>
// 	);
// }

// export function ApartmentStep1({ onNext, onPrev, loading }: any) {
// 	const handleOnSubmit = (e: any) => {
// 		e.preventDefault();
// 		onNext()
// 	}
// 	return (
// 		<form onSubmit={handleOnSubmit} className="needs-validation" noValidate>
// 			<div className='row'>
// 				<div className='col-12 col-md-3 text-center fs-14 order-md-last'>
// 					<div className='row gap-1'>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#1073CE',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#D9D9D9',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 					</div>
// 					<p className='m-2'>Step 1 / 2</p>
// 				</div>
// 				<div className='col-12 col-md-9'>
// 					<div className='mb-3'>
// 						<p className='m-0 text-primary fs-14'>KRENT IBUYING</p>
// 						<h4 className='fw-bold m-0'>Sell an Apartment</h4>
// 						<p className='m-0 light-text'>
// 							We need a few more details about the property you’re trying to
// 							sell
// 						</p>
// 					</div>
// 				</div>
// 			</div>
// 			<div className='form-group mb-3'>
// 				<label className='fw-bold mb-2'>Apartment Address</label>
// 				<input
// 					type='text'
// 					className='form-control border-round-xl bg-purple'
// 					placeholder='Enter Address'
// 				/>
// 			</div>

// 			<div className='row'>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>What year was it built?</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Year'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What floor is your apartment on?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Floor Number (e.g 1st)'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>Apartment Surface Area</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter number'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							No of Bathrooms / Shower Rooms
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter number'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What’s the general apartment condition
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							Does the apartment have a car Park?
// 						</label>
// 						<select className='form-select border-round-xl bg-purple'>
// 							<option>Select An Option</option>
// 							<option value='apartment'>Yes</option>
// 							<option value='two'>No</option>
// 						</select>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							Number of cars you can park there
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter number'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>When was your building built</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Year'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='col-12 text-end m-2'>
// 			<button type="button" className='btn-el fw-bold mr-3' onClick={onPrev}>
// 					Go Back
// 				</button>
// 					<button disabled={loading} className="btn btn-outline btn-dark py-3 px-5" type="submit">Continue</button>
// 				</div>
// 		</form>
// 	);
// }

// export function ApartmentStep2({ onPrev, onNext, loading}: any) {
// 	const handleOnSubmit = (e: any) => {
// 		e.preventDefault();
// 		onNext()
// 	}

// 	return (
// 		<form  onSubmit={handleOnSubmit} className="needs-validation" noValidate>
// 			<div className='row'>
// 				<div className='col-12 col-md-3 text-center fs-14 order-md-last'>
// 					<div className='row gap-1'>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#1073CE',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#1073CE',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 					</div>

// 					<p className='m-2'>Step 2 / 2</p>
// 				</div>
// 				<div className='col-12 col-md-9'>
// 					<div className='mb-3'>
// 						<p className='m-0 text-primary fs-14'>KRENT IBUYING</p>
// 						<h4 className='fw-bold m-0'>Sell an Apartment</h4>
// 						<p className='m-0 light-text'>
// 							We need a few more details about the property you’re trying to
// 							sell
// 						</p>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='form-group mb-4'>
// 				<div className='row'>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								What are the annual fees on the apartment?
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>

// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								How would you rate the general condition of the common areas?
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								How much is the Property Tax?
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>What is the market size?</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								What is the net sale amount of this property?
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>What is your ideal Price?</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>

// 					<div></div>
// 				</div>
// 			</div>
// 			<div className='col-12 text-end m-2'>
// 				<button type="button" className='btn-el fw-bold mr-3' onClick={onPrev}>
// 					Go Back
// 				</button>
// 				<button disabled={loading} className="btn btn-outline btn-dark py-3 px-5" type="submit">Continue</button>
// 			</div>
// 		</form>
// 	);
// }

// export function HouseStep1({ onPrev, onNext, loading }: any) {

// 	const handleSubmit = (e: any) => {
// 		e.preventDefault();
// 		onNext();
// 	}
// 	return (
// 		<form onSubmit={handleSubmit} className="needs-validation" noValidate>
// 			<div className='row'>
// 				<div className='col-12 col-md-3 text-center fs-14 order-md-last'>
// 					<div className='row gap-1'>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#1073CE',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#D9D9D9',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 					</div>
// 					<p className='m-2'>Step 1 / 2</p>
// 				</div>
// 				<div className='col-12 col-md-9'>
// 					<div className='mb-3'>
// 						<p className='m-0 text-primary fs-14'>KRENT IBUYING</p>
// 						<h4 className='fw-bold m-0'>Sell a House</h4>
// 						<p className='m-0 light-text'>
// 							We need a few more details about the property you’re trying to
// 							sell
// 						</p>
// 					</div>
// 				</div>
// 			</div>
// 			<div className='form-group mb-3'>
// 				<div className='row'>
// 					<div className='col-12'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>House Address</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter Year'
// 							/>
// 						</div>
// 					</div>

// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>What year was it built?</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								How many Levels does your house have?
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter number'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								Is your house Semi Detached?
// 							</label>
// 							<select className='form-select border-round-xl bg-purple'>
// 								<option>Select An Option</option>
// 								<option value='apartment'>Yes</option>
// 								<option value='two'>No</option>
// 							</select>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>Number of rooms</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter number'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								What’s the general apartment condition
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter details'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								What is the living area the house?
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter Number'
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								Select features of the house
// 							</label>
// 							<select className='form-select border-round-xl bg-purple'>
// 								<option>Select An Option</option>
// 								<option value='apartment'>Yes</option>
// 								<option value='two'>No</option>
// 							</select>
// 						</div>
// 					</div>
// 					<div className='col-12 col-md-6'>
// 						<div className='form-group mb-3'>
// 							<label className='fw-bold mb-2'>
// 								When was your building built
// 							</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter Year'
// 							/>
// 						</div>
// 					</div>

// 					<div></div>
// 				</div>
// 			</div>

// 			<div className='row'>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>What year was it built?</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Year'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What floor is your apartment on?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Floor Number (e.g 1st)'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>Apartment Surface Area</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter number'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							No of Bathrooms / Shower Rooms
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter number'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What’s the general apartment condition
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							Does the apartment have a car Park?
// 						</label>
// 						<select className='form-select border-round-xl bg-purple'>
// 							<option>Select An Option</option>
// 							<option value='apartment'>Yes</option>
// 							<option value='two'>No</option>
// 						</select>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							Number of cars you can park there
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter number'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>When was your building built</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Year'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='col-12 text-end m-2'>
// 				<button type="button" className='btn-el fw-bold mr-3' onClick={onPrev}>
// 					Go Back
// 				</button>
// 				<button disabled={loading} className="btn btn-outline btn-dark py-3 px-5" type="submit">Continue</button>
// 			</div>
// 		</form>
// 	);
// }

// export function HouseStep2({ onPrev, onNext , loading }: any) {

// 	const handleSubmit = (e: any) => {
// 		e.preventDefault();
// 		onNext();
// 	}

// 	return (
// 		<form onSubmit={handleSubmit} className="needs-validation" noValidate>
// 			<div className='row'>
// 				<div className='col-12 col-md-3 text-center fs-14 order-md-last'>
// 					<div className='row gap-1'>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#1073CE',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 						<div
// 							className='col w-100'
// 							style={{
// 								backgroundColor: '#1073CE',
// 								height: '7px',
// 								borderRadius: '5px',
// 							}}
// 						></div>
// 					</div>
// 					<p className='m-2'>Step 2 / 2</p>
// 				</div>
// 				<div className='col-12 col-md-9'>
// 					<div className='mb-3'>
// 						<p className='m-0 text-primary fs-14'>KRENT IBUYING</p>
// 						<h4 className='fw-bold m-0'>Sell a House</h4>
// 						<p className='m-0 light-text'>
// 							We need a few more details about the property you’re trying to
// 							sell
// 						</p>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='row my-md-4'>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What are the annual fees on the land?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							How much is the Property Tax?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='row my-md-4'>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What is the net sale amount of this property?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>What is the market size</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='row my-md-4'>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>What is your ideal Price?</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 			</div>
// 			<div className='col-12 text-end m-2'>
// 				<button type="button" className='btn-el fw-bold mr-3' onClick={onPrev}>
// 					Go Back
// 				</button>
// 				<button disabled={loading} className="btn btn-outline btn-dark py-3 px-5" type="submit">Continue</button>
// 			</div>
// 		</form>
// 	);
// }

// export function LandStep1({ onNext, loading, onPrev }: any) {
// 	const handleSubmit = (e: any) => {
// 		e.preventDefault();
// 		onNext();
// 	}

// 	return (
// 		<form onSubmit={handleSubmit} className="needs-validation" noValidate>
// 			<div className='row'>
// 				<div className='col-12'>
// 					<div className='mb-3'>
// 						<p className='m-0 text-primary fs-14'>KRENT IBUYING</p>
// 						<h4 className='fw-bold m-0'>Sell a Land</h4>
// 						<p className='m-0 light-text'>
// 							We need a few more details about the property you’re trying to
// 							sell
// 						</p>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='row my-md-4'>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What is the total area of plot, building included?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							What are the annual fees on the land?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='row my-md-4'>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>
// 							How much is the Property Tax?
// 						</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>What is the market size</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='row my-md-4'>
// 				<div className='col-12 col-md-6 mb-2 mb-md-0'>
// 					<div className='form-group mb-3'>
// 						<label className='fw-bold mb-2'>What is your ideal Price?</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter details'
// 						/>
// 					</div>
// 				</div>
// 			</div>

// 			<div className='col-12 text-end m-2'>
// 				<button type="button" className='btn-el fw-bold mr-3' onClick={onPrev}>
// 					Go Back
// 				</button>
// 				<button disabled={loading} className="btn btn-outline btn-dark py-3 px-5" type="submit">Continue</button>
// 			</div>
// 		</form>
// 	);
// }

// export function step5() {
// 	return (
// 		<form>
// 			<h4 className='fs-18 fw-bold mb-3 mb-4'>Pricing Information</h4>

// 			<div className='form-group mb-4'>
// 				<label className='fw-bold mb-2'>Rent</label>
// 				<div className='input-group'>
// 					<input
// 						type='text'
// 						className='form-control border-round-xl bg-purple w-60'
// 						placeholder='Enter amount'
// 					></input>
// 					<select className='form-select border-round-xl border-left-0 bg-purple w-30'>
// 						<option>NGN</option>
// 					</select>
// 				</div>
// 			</div>

// 			<div className='form-group'>
// 				<label className='fw-bold mb-2'>Rent Duration</label>
// 				<select
// 					className='form-select border-round-xl bg-purple'
// 					onChange={() => {
// 						// setPropertyType(e.target.value);
// 					}}
// 				>
// 					<option value='apartment'>Monthly</option>
// 					<option value='two'>Weekly</option>
// 					<option value='three'>Yearly</option>
// 				</select>
// 			</div>
// 		</form>
// 	);
// }

// export function step6({
// 	safetyFeatures,
// 	setSafetyFeatures,
// 	amenities,
// 	setAmenities,
// }: any) {
// 	const safetyFeaturesOpt = [
// 		{ value: 'smoke', label: 'Smoke Alarm' },
// 		{ value: 'first-aid', label: 'First Aid Kit' },
// 	];

// 	const amenitiesOpt = [
// 		{ value: 'gym', label: 'Gym' },
// 		{ value: 'pool', label: 'Pool' },
// 		{ value: 'sauna', label: 'Sauna' },
// 	];

// 	return (
// 		<form>
// 			<h4 className='fs-18 fw-bold mb-4'>
// 				Let your customers know the features of your property
// 			</h4>

// 			<div className='form-group mb-4'>
// 				<label className='fw-bold mb-2'>Select Safety Features</label>
// 				<Select
// 					isMulti
// 					name='amenities'
// 					options={safetyFeaturesOpt}
// 					className='basic-multi-select'
// 					classNamePrefix='form-select'
// 					onChange={(e) => setSafetyFeatures(e)}
// 				/>

// 				<div className='row'>
// 					{safetyFeatures &&
// 						safetyFeatures.map((item: any) => (
// 							<div className='col-6 col-md-4'>
// 								<div className='border border-round-xl p-2 mt-2 text-center border-label'>
// 									{item.label}
// 								</div>
// 							</div>
// 						))}
// 				</div>
// 			</div>

// 			<div className='form-group'>
// 				<label className='fw-bold mb-2'>Select Amenities</label>
// 				<Select
// 					isMulti
// 					name='amenities'
// 					options={amenitiesOpt}
// 					className='basic-multi-select'
// 					classNamePrefix='form-select'
// 					onChange={(e) => setAmenities(e)}
// 				/>

// 				<div className='row'>
// 					{amenities &&
// 						amenities.map((item: any) => (
// 							<div className='col-4 col-md-3'>
// 								<div className='border border-round-xl p-2 mt-2 text-center border-label'>
// 									{item.label}
// 								</div>
// 							</div>
// 						))}
// 				</div>
// 			</div>
// 		</form>
// 	);
// }

// export function step7() {
// 	return (
// 		<form>
// 			<h4 className='fs-18 fw-bold mb-4'>Location Details</h4>

// 			<div className='row'>
// 				<div className='col-12 mb-4'>
// 					<img src={map} alt='' className='w-100 img-fluid' />
// 				</div>

// 				<div className='col-12 mb-3 mb-md-5'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>Enter Address</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter address'
// 						></input>
// 					</div>
// 				</div>

// 				<div className='col-12 col-md-6 mb-3'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>Apartment No</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter Apartment No'
// 						></input>
// 					</div>
// 				</div>

// 				<div className='col-12 col-md-6 mb-3'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>City</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter City'
// 						></input>
// 					</div>
// 				</div>

// 				<div className='col-12 col-md-6 mb-3'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>State / Province</label>
// 						<input
// 							type='text'
// 							className='form-control border-round-xl bg-purple'
// 							placeholder='Enter address'
// 						></input>
// 					</div>
// 				</div>

// 				<div className='col-12 col-md-6 mb-3'>
// 					<div className='form-group'>
// 						<label className='fw-bold mb-2'>Country</label>

// 						<select
// 							className='form-select border-round-xl bg-purple'
// 							// onChange={(e) => {
// 							// 	setPropertyType(e.target.value);
// 							// }}
// 						>
// 							<option defaultValue={'Select An option'}>Select Country</option>
// 							<option value='apartment'>Apartment</option>
// 							<option value='two'>Two</option>
// 							<option value='three'>Three</option>
// 						</select>
// 					</div>
// 				</div>
// 			</div>
// 		</form>
// 	);
// }

// export function step8() {
// 	return (
// 		<form>
// 			<div className='mb-4'>
// 				<h4 className='fs-18 fw-bold'>Let people see your space</h4>
// 				<p className='light-text d-none d-md-flex'>
// 					Upload more Images of this space (5 images minimum)
// 				</p>
// 			</div>

// 			<div className='row'>
// 				{<ImageUploadCard />}
// 				{<ImageUploadCard />}
// 				{<ImageUploadCard />}
// 				{<ImageUploadCard />}

// 				<div className='col-12'>
// 					<div className='d-flex flex-row align-items-center justify-content-center border border-round p-2'>
// 						<i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
// 						<p className='m-0 fw-600'>Tap To Add An Image</p>
// 					</div>
// 				</div>

// 				<div className='row my-5'>
// 					<div className='col-12 col-md-6 mb-4 mb-md-0'>
// 						<div className='form-group'>
// 							<label className='fw-bold mb-2'>Video Tour (Optional)</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter URL'
// 							></input>
// 						</div>
// 					</div>

// 					<div className='col-12 col-md-6'>
// 						<div className='form-group'>
// 							<label className='fw-bold mb-2'>Virtual Tour (Optional)</label>
// 							<input
// 								type='text'
// 								className='form-control border-round-xl bg-purple'
// 								placeholder='Enter URL'
// 							></input>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</form>
// 	);
// }
