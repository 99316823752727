type Cycle = {
  [key: string]: string;
};

export const CycleToInterval = (cycle: string): string => {
  const cToI: Cycle = {
    yearly: 'year',
    monthly: 'month',
    weekly: 'week',
    daily: 'day',
  };
  return cToI[cycle];
};

export const ROLES = {
  LANDLORD: 'landlord',
  USER: 'user',
  AGENT: 'agent',
};
