import React from 'react';

const SuccessCard = ({ text }: any) => {
  return (
    <div
      style={{
        textAlign: 'center',
        // padding: '40px 0',
        display: 'inline-block',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className='card'
        style={{
          background: 'white',
          padding: '60px',
          // borderRadius: '4px',
          // boxShadow: '0 2px 3px #C8D0D8',
          border: 'none',
          display: 'inline-block',
          margin: '0 auto',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            borderRadius: '200px',
            height: '150px',
            width: '150px',
            background: '#F8FAF5',
            margin: '0 auto',
          }}
        >
          <i
            className='checkmark'
            style={{
              color: '#9ABC66',
              fontSize: '75px',
              lineHeight: '150px',
              marginLeft: '-15px',
            }}
          >
            ✓
          </i>
        </div>
        <h1 style={{ color: '#88B04B' }}>Success</h1>
        <p style={{ color: '#404F5E' }}>
          {/* We received your purchase request;
          <br /> we'll be in touch shortly! */}
          {text}
        </p>
      </div>
    </div>
  );
};

export default SuccessCard;
