import {useState} from 'react';

import {OnboardingLayout} from '@layouts/OnboardingLayout';
import {InputComponent} from '@shared/forms';
import {PrimaryButton} from '@shared/buttons';
import {useSearchParams, useNavigate, Link} from 'react-router-dom';
import axios from 'axios';
import Logo from '@shared/Logo';

export const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token: string | null = searchParams.get('token');

  const apiUrl = process.env['REACT_APP_API_BASE_URL'];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (token && password === confirmPassword) {
      try {
        const response = await axios.put(
          `${apiUrl}/auth/resetpassword/${token}`,
          {
            password,
            passwordConfirm: confirmPassword,
          }
        );

        if (response.data.success) {
          setSuccess('Password Reset Successfully');

          setTimeout(() => {
            setLoading(false);
            setSuccess('');
            navigate('/sign-in', {replace: true});
          }, 3000);
        }
      } catch (error: any) {
        setError(error.response.data.errors[0].message);

        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2500);
      }
    }
  };

  return (
    <OnboardingLayout>
      <div className='container'>
        <Link className=' text-black text-link ' to='/'>
          <Logo />
        </Link>

        <h4 className='fw-bolder'>Change your password</h4>
        <p className='text-secondary fs-14 mb-4'>
          Remember to use a password you can remember but cannot be guessed
          easily.
        </p>
        <form className='mt-4 mt-md-3' onSubmit={handleSubmit}>
          <div className='col mb-4'>
            <InputComponent
              label='New Password'
              placeholder='Enter your new password'
              icon='Lock'
              type='password'
              value={password}
              required
              change={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className='col mb-4'>
            <InputComponent
              label='Confirm New Password'
              placeholder='Confirm your new password'
              icon='Lock'
              type='password'
              value={confirmPassword}
              required
              change={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          <PrimaryButton
            type='primary'
            text='Set New Password'
            loading={loading}
          />
          {success && <span className='text-success col-12'>{success}</span>}
          {error && <span className='text-danger col-12'>{error}</span>}
        </form>
      </div>
    </OnboardingLayout>
  );
};
