import {NewsListResponse} from '@interfaces/news.interface';

export const NewsCard: React.FC<NewsListResponse> = (
  props: NewsListResponse
) => {
  const date = new Date(props?.date);

  // Convert to locale-specific date string
  const localeDateString = date.toLocaleDateString();

  return (
    <div className='col flex-fill'>
      <a href={props.link} target='_blank' rel='noreferrer'>
        <div className='card border-0'>
          <img
            src={props.uagb_featured_image_src.full[0]}
            className='card-img-top rounded-3'
            alt={props.title.rendered}
            style={{height: '18rem', width: '100%', objectFit: 'cover'}}
          />
          <div className='card-body px-0'>
            <p className='text-light-black fs-12'>{localeDateString}</p>
            <h5 className='card-title fs-18 font-display mt-2 fw-500 text-light-black'>
              {props.title.rendered.slice(0, 55)}...
            </h5>

            <p
              className='card-text text-muted fs-12'
              dangerouslySetInnerHTML={{
                __html: props.excerpt.rendered.slice(0, 500),
              }}
            ></p>

            {/* <p className='card-text text-muted fs-12'>{props.excerpt.rendered}</p> */}
            <span className='fs-12 mt-3 d-inline-block fw-600 text-decoration-none text-primary text-uppercase'>
              Read More
            </span>
            {/* <a
              href={props.link}
              target='_blank'
              rel='noreferrer'
              className='fs-12 fw-600 text-decoration-none text-black text-uppercase'
            >
              Read More
            </a> */}
          </div>
        </div>
      </a>
    </div>
  );
};
