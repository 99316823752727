import {Amenities} from "@interfaces/requests.interface";

const updateArray = (
  newItem: Amenities,
  setTempAmenities: (arg: any) => void
) => {
  setTempAmenities((oldArray: Amenities[]) => {
    let found = false;
    let index = 0;
    // eslint-disable-next-line
    oldArray.map((item: any, i: number) => {
      if (item.title === newItem.title) {
        found = true;
        index = i;
      }
    });
    if (found) {
      oldArray[index] = newItem;
    } else {
      oldArray.push(newItem);
    }
    return [...oldArray];
  });
};
export default updateArray;
