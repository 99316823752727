import {useState} from 'react';
import {Link} from 'react-router-dom';

import {OnboardingLayout} from '@layouts/OnboardingLayout';
import {InputComponent} from '@shared/forms';
import {PrimaryButton} from '@shared/buttons';
import {useNavigate} from 'react-router-dom';

import {getProfile, login} from '@services/krent.service';
import {addCookie} from '@utils/cookies';
import Logo from '@shared/Logo';

export const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const [error, setError] = useState('');
  // const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const abortController = new AbortController();

  // console.log(location);
  // location?.state?.from?.pathname ||
  // const from =  '/explore-listings/';
  // const from =  '/overview/';

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    login(email, password)
      .then((res) => {
        // console.log(res);
        // setAuth(true);
        // console.log(auth);
        if (res['success'] === true) {
          addCookie('1Q_SPA', res['token'], 24000);

          getProfile(abortController.signal)
            .then((d: any) => {
              if (d?.data?.role === 'landlord' || d?.data?.role === 'agent') {
                // console.log(d['data']['role']);
                navigate('/overview', {replace: true});
              } else if (d?.data?.role === 'user') {
                navigate('/explore-listings', {replace: true});
              } else {
                navigate('/', {replace: true});
              }
            })
            .catch((err) => {
              setError(err);
            });
        }
      })
      .catch((err) => {
        setError(err);

        setTimeout(() => {
          setError('');
        }, 3000);
      })
      .finally(() => {
        // Memory Leak Fix ASAP
        setLoading(false);
      });
  };

  return (
    <OnboardingLayout>
      <div className='container'>
        <Link className=' text-black text-link ' to='/'>
          {/* <h3 className='text-md-start fw-bolder mb-5'>krent</h3> */}
          <Logo />
        </Link>

        <h4 className='fw-bolder mt-3'>Welcome Back</h4>
        <p className='text-secondary fs-14 mb-4'>
          Login with your credentials to continue
        </p>

        <form className='mt-4 mt-md-3' onSubmit={handleSubmit}>
          {error && <span className='text-danger'>{error}</span>}
          <div className='col mb-4'>
            <InputComponent
              label='Email Address'
              placeholder='Enter your email address'
              icon='Message'
              type='email'
              change={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* <div className='col mb-3'>
            <div className='row'>
              <div className='col-11'>
                <InputComponent
                  label='Password'
                  placeholder='Enter your password'
                  icon='Lock'
                  type={togglePassword ? 'text' : 'password'}
                  change={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className='col-1 d-flex flex-column justify-content-center align-items-center'>
                <span
                  style={{
                    marginTop: '30px',
                  }}
                >
                  {togglePassword ? (
                    <i
                      className='iconly-Show icli fs-4'
                      onClick={() => setTogglePassword(!togglePassword)}
                      style={{ cursor: 'pointer', color: '#05569f' }}
                    ></i>
                  ) : (
                    <i
                      className='iconly-Hide icli fs-4'
                      onClick={() => setTogglePassword(!togglePassword)}
                      style={{ cursor: 'pointer', color: '#05569f' }}
                    ></i>
                  )}
                </span>
              </div>
            </div>
          </div> */}
          {/* test */}
          <div className='form-group col mb-3'>
            <label
              className='text-uppercase fw-600 fs-12 m-1'
              htmlFor='password'
            >
              Password
            </label>
            <div className='input-group'>
              <span
                className='input-group-text input-group-text-0 '
                id='basic-addon1'
              >
                <i className={`iconly-Lock icli fs-4`}></i>
              </span>

              <input
                type={togglePassword ? 'text' : 'password'}
                className='form-control form-control-0 ps-3 py-3 border-start-0'
                id='password'
                aria-describedby='emailHelp'
                placeholder='Enter your password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{borderRight: 'none'}}
              />

              <span className='input-group-text input-group-text-0 '>
                {togglePassword ? (
                  <i
                    className='iconly-Show icli fs-4'
                    onClick={() => setTogglePassword(!togglePassword)}
                    style={{cursor: 'pointer', color: '#05569f'}}
                  ></i>
                ) : (
                  <i
                    className='iconly-Hide icli fs-4'
                    onClick={() => setTogglePassword(!togglePassword)}
                    style={{cursor: 'pointer', color: '#05569f'}}
                  ></i>
                )}
              </span>
            </div>
          </div>
          {/* test */}
          <div className='row flex mb-5'>
            <div className=' col'>
              <Link
                className='text-secondary fs-14 text-link'
                to='/reverify-email/'
              >
                Resend Verification Email
              </Link>
            </div>
            <div className=' col text-end'>
              <Link
                className='text-secondary fs-14 text-link'
                to='/forgot-password/'
              >
                Forgot password?
              </Link>
            </div>
          </div>

          <div className='col text-center mt-neg'>
            <PrimaryButton type='primary' text='Sign In' loading={loading} />
          </div>
        </form>

        <p className='text-secondary text-center my-4 my-md-3 fs-14'>
          New To Krent?{' '}
          <Link className='text-link' to='/get-started/'>
            Sign Up
          </Link>
        </p>
      </div>
    </OnboardingLayout>
  );
};
