import { useSessionStorage } from '../../hooks'
import { useState, useEffect } from 'react'
import {
  serverTimestamp,
  Timestamp,
  doc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore'

import { firestore } from '@services/firebase.service'
import ktoast from '@services/toast.service'
import { renewBooking } from '@services/krent.service'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { formatCurrency } from '@utils/currency'
import { CycleToInterval } from '@utils/constants'
import { formatDate } from '@utils/helpers'

interface DateRange {
  start: Date
  end: Date
}

const ExtendBooking = ({
  transactionId,
  type,
  currency,
  duration,
  price,
  members,
  selectedMessage,
  departureDate,
  previousTransaction,
}: // setTransaction,
any) => {
  const [user] = useSessionStorage('user', {})
  const [loading, setLoading] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<any | null>(null)
  const [dateTo, setDateTo] = useState<any | null>(null)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [normalDays, setNormalDays] = useState(0)
  const [peakDays, setPeakDays] = useState(0)
  const [normalPrice, setNormalPrice] = useState(0)
  const [peakPrice, setPeakPrice] = useState(0)
  const [normalDateRanges, setNormalDateRanges] = useState<DateRange[]>([])
  const [peakDateRanges, setPeakDateRanges] = useState<DateRange[]>([])

  const datePickerMinDate = new Date(departureDate)

  const messageCollections: any = process.env['REACT_APP_FIREBASE_COLLECTION']
  useEffect(() => {
    if (dateFrom && dateTo) {
      const start = new Date(dateFrom)
      const end = new Date(dateTo)

      let normalDaysCount = 0
      let peakDaysCount = 0
      let currentNormalRange = null
      let currentPeakRange = null
      const normalRanges = []
      const peakRanges = []

      const diffInDays = Math.ceil(
        (end.getTime() - start.getTime()) / (1000 * 3600 * 24),
      )

      for (let i = 0; i < diffInDays; i++) {
        const current = new Date(start)
        current.setDate(start.getDate() + i)

        const month = current.getMonth()
        const day = current.getDate()
        const isPeakDay =
          (month === 11 && day >= 1) || (month === 0 && day <= 15)

        if (isPeakDay) {
          peakDaysCount++
          if (!currentPeakRange) {
            currentPeakRange = {
              start: new Date(current),
              end: new Date(current),
            }
            currentPeakRange.end.setDate(current.getDate() + 1)
          } else {
            currentPeakRange.end = new Date(current)
            currentPeakRange.end.setDate(current.getDate() + 1)
          }
          if (currentNormalRange) {
            normalRanges.push(currentNormalRange)
            currentNormalRange = null
          }
        } else {
          normalDaysCount++
          if (!currentNormalRange) {
            currentNormalRange = {
              start: new Date(current),
              end: new Date(current),
            }
            currentNormalRange.end.setDate(current.getDate() + 1)
          } else {
            currentNormalRange.end = new Date(current)
            currentNormalRange.end.setDate(current.getDate() + 1)
          }
          if (currentPeakRange) {
            peakRanges.push(currentPeakRange)
            currentPeakRange = null
          }
        }
      }

      if (currentNormalRange) normalRanges.push(currentNormalRange)
      if (currentPeakRange) peakRanges.push(currentPeakRange)

      setNormalDateRanges(normalRanges)
      setPeakDateRanges(peakRanges)
      setNormalDays(normalDaysCount)
      setPeakDays(peakDaysCount)
      setNormalPrice(normalDaysCount * price)
      setPeakPrice(peakDaysCount * (price * 1.4))
    } else {
      setNormalDays(0)
      setPeakDays(0)
      setNormalPrice(0)
      setPeakPrice(0)
      setNormalDateRanges([])
      setPeakDateRanges([])
    }
  }, [dateFrom, dateTo, price])

  const formatDateRange = (range: any) => {
    const formatToDateString = (date: Date) => {
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }
    return `${formatToDateString(range.start)} - ${formatToDateString(
      range.end,
    )}`
  }

  // Function to calculate min date
  const handleMinDate = (selectedDate: Date | null) => {
    let minDate
    if (selectedDate) {
      minDate = new Date(selectedDate)
      minDate.setDate(minDate.getDate() + 1)
      return minDate
    }
    minDate = new Date()
    return minDate
  }

  const handleRenewBooking = async () => {
    if (user && user?.id) {
      setLoading(true)
      const formattedDateFrom = formatDate(dateFrom)
      const formattedDateTo = formatDate(dateTo)
      try {
        const d: any = await renewBooking({
          originalTransaction: transactionId,
          arrival_date: formattedDateFrom,
          departure_date: formattedDateTo,
          previousTransaction,
        })

        // setTransaction(d?.data);

        const docRef = doc(firestore, messageCollections, selectedMessage)
        await updateDoc(docRef, {
          meta: {
            request: {
              id: d?.data?.id,
              property: d?.data.listing?.id,
              request_type: type,
              arrival_date: dateFrom,
              departure_date: dateTo,
            },
          },
          chats: arrayUnion({
            from_uid: user?.id,
            to_uid: user?.id === members[0] ? members[1] : members[0],
            type: 'text',
            value: 'I would like to extend my booking.',
            createdAt: Timestamp.now(),
          }),
          read: false,
          updatedAt: serverTimestamp(),
        })

        setSuccess(d?.message)
        ktoast.success('Your booking has been renewed successfully.')
      } catch (err: any) {
        if (err.errors && err.errors[0]) {
          ktoast.error(err.errors[0].message)
          setError(err.errors[0].message)
          setTimeout(() => {
            setError('')
          }, 3000)
        } else {
          console.error('Error updating document: ', err)
          ktoast.error(
            'Failed to send message. Please check you have a working internet connection',
          )
        }
      } finally {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="border-1 rounded border-purple">
        <div className="d-flex flex-column p-4 h-100">
          <div className="d-flex flex-column">
            <p className="light-text">
              {type === 'long lease'
                ? 'Rent'
                : type === 'shortlet'
                ? 'Shortlet'
                : 'Sale'}{' '}
              Price
            </p>
            <div className=" d-flex d-md-block d-lg-flex justify-content-between align-items-center mt-neg">
              <p className="fs-12">
                <span className="fs-22 fw-bolder">
                  {currency}
                  {formatCurrency(price)}
                </span>
                {type !== 'sale' && <span> / {CycleToInterval(duration)}</span>}
              </p>
              <p>
                {type === 'sale' && (
                  <span className="badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-custom-royal bg-custom-royal bg-opacity-10 border border-success border-opacity-10 rounded-pill">
                    FOR SALE
                  </span>
                )}
                {type === 'long lease' && (
                  <span className="badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill">
                    RENTAL
                  </span>
                )}
                {type === 'shortlet' && (
                  <span className="badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-pill">
                    SHORT LET
                  </span>
                )}
              </p>
            </div>
          </div>

          <hr />

          <div className="">
            <h5 className="fw-bold mb-2">
              {type === 'shortlet'
                ? 'Reservations'
                : type === 'long lease'
                ? 'Rent Duration'
                : 'Contact Owner'}
            </h5>
            {success && <span className="fs-13 text-success">{success}</span>}
            {error && <span className="fs-13 text-danger">{error}</span>}

            {type !== 'sale' && (
              <div className="form-group m-1">
                <label className="form-label fs-14">
                  {type === 'shortlet' ? 'Check In' : 'From'}
                </label>
                <div className="input-group">
                  <span className="input-group-text input-group-text-0">
                    <i className={`iconly-Calendar`}></i>
                  </span>
                  <div className="form-control form-control-0 border-start-0">
                    <DatePicker
                      selected={dateFrom}
                      onChange={(date) => setDateFrom(date)}
                      placeholderText="dd-mm-yyyy"
                      className="form-control form-control-0 border-0"
                      minDate={datePickerMinDate}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
              </div>
            )}

            {type !== 'sale' && (
              <div className="form-group mb-3">
                <label className="form-label fs-14">
                  {type === 'shortlet' ? 'Check Out' : 'To'}
                </label>
                <div className="input-group">
                  <span className="input-group-text input-group-text-0">
                    <i className={`iconly-Calendar`}></i>
                  </span>
                  <div className="form-control form-control-0 border-start-0">
                    <DatePicker
                      selected={dateTo}
                      onChange={(date: any) => setDateTo(date)}
                      placeholderText="dd-mm-yyyy"
                      className="form-control form-control-0 border-0"
                      minDate={handleMinDate(dateFrom)}
                      // showYearDropdown
                      // showMonthDropdown
                      disabled={!dateFrom}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
              </div>
            )}

            {type === 'shortlet' && dateFrom && dateTo ? (
              <div className="">
                <div className="d-flex justify-content-between text-center gap-1">
                  <h6 className="text-gray-600 mb-1">Days</h6>
                  <h6 className="text-gray-600 mb-1">Date ranges</h6>
                  <h6 className="text-gray-600 mb-1">Total price</h6>
                </div>
                <div className="d-flex justify-content-between gap-1 my-2 ">
                  {normalDays > 0 && (
                    <p className="fs-12 m-0">{normalDays} normal days</p>
                  )}
                  <div className="flex flex-col space-y-1">
                    {normalDateRanges.map((range, index) => (
                      <div key={`normal-${index}`} className="fs-12">
                        {formatDateRange(range)}
                      </div>
                    ))}
                  </div>
                  {normalDays > 0 && (
                    <p className="fs-12 m-0">
                      {currency}
                      {formatCurrency(normalPrice)}
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between gap-1 my-2 ">
                  {peakDays > 0 && (
                    <p className="fs-12 m-0">{peakDays} peak days</p>
                  )}
                  <div className="flex flex-col space-y-1">
                    {peakDateRanges.map((range, index) => (
                      <p key={`peak-${index}`} className="fs-12 m-0">
                        {formatDateRange(range)}
                      </p>
                    ))}
                  </div>
                  {peakDays > 0 && (
                    <p className="fs-12 m-0">
                      {currency}
                      {formatCurrency(peakPrice)}
                    </p>
                  )}
                </div>

                <div className="d-flex justify-content-between my-2 ">
                  <h5>Total price</h5>
                  <h5>
                    {currency}
                    {formatCurrency(normalPrice + peakPrice)}
                  </h5>
                </div>
              </div>
            ) : null}

            <div className="d-grid my-4 gap-3">
              <button
                className="btn btn-primary py-3 rounded-pill"
                disabled={
                  loading ||
                  (!dateFrom && type !== 'sale') ||
                  (!dateTo && type !== 'sale') ||
                  (dateFrom && dateTo && dateFrom > dateTo)
                }
                onClick={() => {
                  handleRenewBooking()
                }}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  'Book Now'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtendBooking
