import {PropertyListResponse} from '@interfaces/properties.interface';
import {formatCurrency} from '../../../utils/currency';
import {CycleToInterval} from '../../../utils/constants';

import defaultImage from '../../../assets/images/property-card.png';
import {Link} from 'react-router-dom';

export const PropertyCard: React.FC<PropertyListResponse> = (
  p: PropertyListResponse
) => {
  return (
    <div className='col'>
      <div
        className='card border-light shadow-sm h-100 position-relative '
        style={{overflow: 'hidden'}}
      >
        <Link
          to={`/property/${p.slug}`}
          state={{property: p, id: p.slug}}
          className='stretched-link'
        ></Link>
        {/* image */}
        <div className='position-relative'>
          {/* <div
          className='position-relative'
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0,0,0,0.3)), url(${
              p.coverImage ? p.coverImage : defaultImage
            })`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '265px',
            backgroundSize: 'cover',
          }}
        > */}
          <img
            src={p.coverImage ? p.coverImage : defaultImage}
            className='card-img-top'
            alt={p.slug}
            title={p.slug}
          />
          {/* price */}
          <p
            className='text-muted fs-12 position-absolute'
            style={{bottom: '21px', left: '21px'}}
          >
            <span className='text-white fs-18 fw-bolder'>
              &#x20A6;{formatCurrency(p.price)}
            </span>
            {p.intent !== 'sale' && (
              <span className='text-white'>/{CycleToInterval(p.cycle)}</span>
            )}
          </p>
          {/* intent */}
          <div
            className='d-flex justify-content-between position-absolute '
            style={{top: '21px', right: '21px'}}
          >
            {p.intent === 'sale' && (
              <span
                className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-white'
                style={{background: '#963780'}}
              >
                FOR SALE
              </span>
            )}
            {p.intent === 'long lease' && (
              <span
                className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-white'
                // className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill'
                style={{background: '#05569F'}}
              >
                FOR RENT
              </span>
            )}
            {p.intent === 'shortlet' && (
              <span
                className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-white'
                style={{background: '#C0190F'}}
              >
                {/* <span className='badge d-inline-flex fs-12 mb-3 px-2 py-2 fw-semibold text-primary bg-primary bg-opacity-10 border border-primary border-opacity-10 rounded-pill'> */}
                SHORT LET
              </span>
            )}
          </div>
        </div>

        <div className='card-body'>
          <h2 className='fw-bolder mb-1 fs-14'>{p.title}</h2>
          <p className='fs-12 text-muted'>
            {p.summary.slice(0, 100)}
            {p.summary.length > 100 ? '...' : '.'}
          </p>
          <p className='fs-12 text-muted'>{p.location.city}</p>
          {p.referenceNumber && (
            <p className='fs-12 text-muted'>Ref : {p.referenceNumber}</p>
          )}
          <hr />
          <div className='gap-3 d-md-block'>
            <span className='fs-12 me-3'>
              <i className='fa-light fa-bed-front text-primary'></i> {p.beds}{' '}
              Beds
            </span>
            <span className='fs-12 me-3'>
              <i className='fa-light fa-bath text-primary'></i> {p.bathrooms}{' '}
              Bathrooms
            </span>
            {/* <span className='fs-12'>
              <i className='fa-light fa-ruler-triangle text-primary'></i> 5x7 m
              <sup>2</sup>
            </span> */}
          </div>
        </div>
        {/* BADGE */}
        {/* <div
          className='position-absolute bg-success text-white pt-1  text-center d-flex align-items-center  fs-6'
          style={{
            zIndex: ' 1',
            fontSize: ' 15px',
            left: '5px',

            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            // transform: "rotate(-45deg)",
            // left: "-40px",
            // top: "25%",
            // transformOrigin: "left top",
            // marginLeft: "1.6rem",
          }}
        >
          <span
          //  style={{marginLeft: "2.7rem"}}
          >
            <i className={`iconly-Shield-Done icli fs-2`}></i>
          </span>
        </div> */}
      </div>
    </div>
  );
};
