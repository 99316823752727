// import { BaseApiResponse, PaginatedResponse } from '@interfaces/app.interface';
// import { PropertyKind } from '@interfaces/properties.interface';
import {BaseLayout} from '@layouts/BaseLayout';
import {PropertyCardList} from '@lists/PropertyCardList';
// import {  getPropertyKinds } from '@services/krent.service';
// import {SearchCard2} from '@shared/cards';
// import { SearchCard } from '@shared/cards/SearchCard';
import axios from 'axios';
import Pagination from 'components/Pagination';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const Preview = () => {
  // const [propertyKinds, setPropertyKinds] = useState<PropertyKind[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [getUser,setGetUser] = useState(false)

  const {id} = useParams<Record<string, string>>();

  const getData = async () => {
    setLoading(true);
    const val = await axios.get(
      `${process.env['REACT_APP_API_BASE_URL']}/properties?user=${id}`
    );
    if (val.data.success) {
      setLoading(false);
      const {page_count, data, total} = val.data;
      setData({
        properties: data,
        page_count,
        total,
        numOfPages: Math.ceil(total / limit),
      });
    }
  };

  const [data, setData] = useState<any>({
    properties: [],
    current_page: null,
    page_count: 0,
    total: 0,
    numOfPages: 1,
  });

  const [page, setPage] = useState<any>(1);
  const limit: any = 20;

  // Fecth properties function
  // const fetchProperties = () => {
  //     setLoading(true);
  //     let abortController: AbortController = new AbortController();

  //     getProperties(id, abortController.signal)
  //     .then((res: any) => {
  //       const { page_count, data, total } = res;
  //       setData({
  //         properties: data,
  //         page_count,
  //         total,
  //         numOfPages: Math.ceil(total / limit),
  //       });
  //       // setProperties(res.data);
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //     let abortController = new AbortController();
  //     getPropertyKinds(abortController.signal)
  //       .then((res: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
  //         setPropertyKinds(res.data);
  //       })
  //       .catch(() => {})
  //       .finally(() => {});

  //     return () => {
  //       return abortController.abort();
  //     };
  // }, []);

  useEffect(() => {
    getData();
  }, [id]);

  const changePage = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <BaseLayout>
        <section className='container d-flex py-5'>
          <h6>
            You're now viewing {data?.properties[0]?.user?.firstname}{' '}
            {data?.properties[0]?.user?.lastname}'s listings
          </h6>
        </section>
        {/* <section className='container mt-5 mb-5'>
          <div className='bg-white col-12   rounded'>

            <SearchCard2
              extraClass='rounded-top-left-0 rounded-top-right-0'
              sizeClass='col-md-6 col-8'
              page={page}
              setPage={setPage}
            />
          </div>
        </section> */}
        {/* <section className='container mt-5 mb-5'>
                <h5 className='fw-bolder'>Search for properties</h5>
                <SearchCard
                location={'Ikeja'}
                propertySize={'2'}
                propertyTypes={propertyKinds}
                price='20'
                />
                <button
                className='btn btn-link fw-bold px-0 no-decoration'
                data-bs-toggle='offcanvas'
                data-bs-target='#offcanvasExample'
                aria-controls='offcanvasExample'
                >
                Filters
                </button>
                <Link
                className='btn btn-link fw-bold px-0 text-danger ml-1 no-decoration'
                to={'/explore'}
                >
                Clear Filters
                </Link>
            </section> */}
        <section className='container'>
          <PropertyCardList properties={data.properties} loading={loading} />

          <Pagination
            page={page}
            numOfPages={data.numOfPages}
            changePage={changePage}
          />
        </section>
      </BaseLayout>
    </>
  );
};

export default Preview;
