import React, {useState} from 'react';
// import locationIcon from '../../assets/icons/location.svg';
import locationIcon from '../../../assets/icons/map.svg';
import phoneIcon from '../../../assets/icons/call.svg';
import envelopeIcon from '../../../assets/icons/mail.svg';
import question from '../../../assets/icons/question.svg';
import RequestForm from 'components/RequestForm';
const ContactInfo = () => {
  const [openModal, setOpenModal] = useState(false);

  const infos = [
    {
      icon: question,
      title: 'Request a property',
      dsc: ['Can’t find the home you are looking for?'],
    },
    {
      icon: phoneIcon,
      title: 'Lets talk on the phone',
      dsc: ['Give us a call at +2349118008008 to talk to us directly '],
    },
    {
      icon: envelopeIcon,
      title: 'Send us an email',
      dsc: [
        'you can shoot us an email at hello@krent.space or info@krent.space',
      ],
    },
    {
      icon: locationIcon,
      title: 'Visit us at our location',
      dsc: ['Visit our headquarters, H3 Bayview estate ikate Lekki Lagos'],
    },
  ];
  return (
    <div className=' pb-3'>
      <div className='container'>
        <div className='row '>
          {infos.map((item, index) => (
            <div
              key={index}
              className='col-12 col-md-6 col-lg-3 d-flex align-items-stretch mb-4'
            >
              <div className='bg-white border-0 rounded px-3 py-4 shadow-sm w-100 h-100'>
                <div className='d-flex justify-content-center align-items-center mb-3'>
                  <img src={item.icon} alt={item.title} className='img-fluid' />
                </div>
                <div className='d-flex flex-column justify-content-center text-center'>
                  <h6 className='fw-bold mb-2 fs-13'>{item.title}</h6>
                  <p>
                    {item.dsc.map((line, i) => (
                      <span key={i} className='mb-0 '>
                        {line}
                      </span>
                    ))}
                    {item.title === 'Request a property' && (
                      <>
                        <span
                          className='text-primary cursor-pointer'
                          onClick={() => setOpenModal(true)}
                        >
                          {' '}
                          Click here{' '}
                        </span>
                        <span>to request a property</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <RequestForm
        setRequestFormOpen={setOpenModal}
        requestFormOpen={openModal}
      />
    </div>
  );
};

export default ContactInfo;
