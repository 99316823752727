import axios from "axios";

import {fetchCookie} from "@utils/cookies";

// const url = process.env['REACT_APP_API_BASE_URL'];

const customFetch = axios.create({
  baseURL: process.env["REACT_APP_API_BASE_URL"],
});

// Add a CancelToken to the configuration of the request
customFetch.interceptors.request.use((config: any) => {
  let _token = fetchCookie("1Q_SPA");
  if (_token) {
    config.headers.common["Authorization"] = `Bearer ${_token}`;
  }

  // Create a new cancel token source
  const source = axios.CancelToken.source();

  // Assign the cancel token to the request configuration
  config.cancelToken = source.token;

  // Attach a cancellation function to the request configuration
  config.cancel = () => {
    source.cancel("Request canceled by user");
  };

  return config;
});

export default customFetch;
