import { MouseEventHandler } from 'react';

type KProps = Partial<{
    hideAfter: number;
    heading: string;
    type: string;
    role: string;
    toastContainerID: string;
    renderIcon: Function;
    bar: Partial<{
        size: string;
        style: 'solid' | 'dashed' | 'dotted';
        color: string;
    }>;
    onClick: MouseEventHandler;
    onHide: Function;
}>;

type CTMethod = (message: string, options?: KProps) => void;



const showNotificationBar: CTMethod = (message, options) => {
    const el: HTMLCollectionOf<HTMLDivElement> = document.getElementsByClassName('notification-bar') as HTMLCollectionOf<HTMLDivElement>;
    const p: HTMLCollectionOf<HTMLParagraphElement> = el[0].getElementsByTagName('p') as HTMLCollectionOf<HTMLParagraphElement>;

    p[0].innerHTML = message;
    p[0].classList.add(`text-${options?.type}`);

    el[0].classList.toggle('show');
    el[0].classList.add(`bg-${options?.type}`);

    setTimeout(() => {
        el[0].classList.toggle('show');
        p[0].classList.remove(`text-${options?.type}`);
        el[0].classList.remove(`bg-${options?.type}`)
    }, (options?.hideAfter || 3) * 1000)
}

const ktoast = {
    success: (message: string, options?: KProps) => {
        showNotificationBar(message, { ...options, type: 'success' })
    },
    warn: (message: string, options?: KProps) => {
        showNotificationBar(message, { ...options, type: 'warning' })
    },
    error: (message: string, options?: KProps) => {
        showNotificationBar(message, {...options, type: 'danger'})
    },
    info: (message: string, options?: KProps) => {
        showNotificationBar(message, {...options, type: 'info'})
    }      
}

export default ktoast;