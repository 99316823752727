import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {DashboardLayout} from '@layouts/index';

import {PrimaryButton} from '@shared/buttons';
import {Modal} from '@shared/controls/Modal';
import {ListingTypeCard, ListingCard, ListingCardMobile} from '@shared/cards';

// import { listings } from './data';

import notification from '../../assets/images/notifications.png';
import {
  deleteProperty,
  getListings,
  toggleVisibility,
} from '@services/krent.service';
import {PropertyListResponse} from '@interfaces/properties.interface';
import {useSessionStorage} from '../../hooks';
import {useLocation} from 'react-router';
import ktoast from '@services/toast.service';

export const Listing = () => {
  const location = useLocation();
  const {state}: {state: any} = location;

  const [user] = useSessionStorage('user', {});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('shortlet');
  const [modalType, setModalType] = useState<string>('listing');
  const [search, setSearch] = useState<string>('');
  const [id, setId] = useState<any>(0);
  const [listingId, setListingId] = useState<string>('');
  const [deListResponse, setdeListResponse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [listings, setListings] = useState<PropertyListResponse[]>([]);
  const [intent, setIntent] = useState<string>('');

  const navigate = useNavigate();

  const headings: string[] = [
    'PROPERTY',
    'COST',
    'PROPERTY TYPE',
    'LISTED ON',
    'STATUS',
    'ACTIONS',
  ];

  const filterListings = (listings: any[], tab: string) => {
    return listings?.filter((listing: any) => listing.intent === tab);
  };

  useEffect(() => {
    if (state && state['showToast'] === true) {
      ktoast.success(state['toastMessage']);
    }
  }, [state]);

  useEffect(() => {
    setLoading(true);

    const abortController = new AbortController();

    getListings(user?.id, {intent: tab}, abortController.signal)
      .then((res: any) => {
        // console.log(res);
        setListings(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, [user, tab]);

  useEffect(() => {
    const abortController = new AbortController();

    const switchTabs = (event: any) => {
      // console.log(event.target); // newly activated tab
      // console.log(event.target.dataset);
      // console.log(event.relatedTarget) // previous active tab
      getListings(
        user?.id,
        {intent: event.target.dataset.tabIntent},
        abortController.signal
      )
        .then((res: any) => {
          // console.log(res);
          setListings(res.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    const tabEl = document.querySelectorAll(
      'button[data-bs-toggle="tab"]'
    ) as NodeList;
    // console.log(tabEl);
    tabEl.forEach((el) => {
      el.addEventListener('show.bs.tab', switchTabs);
      // console.log('added');
    });

    return () => {
      abortController.abort();
      tabEl.forEach((el) => {
        el.removeEventListener('show.bs.tab', switchTabs);
        // console.log('removed');
      });
    };
  }, [user]);

  const archiveProperty = (id: string) => {
    const abortController = new AbortController();
    setLoading(true);
    // setShowModal(false);
    deleteProperty(id)
      .then(() => {
        ktoast.error('Listing deleted successfully');
        setdeListResponse('Property Delisted Successfully');
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
        ktoast.error('Listing deleted successfully');
        setdeListResponse(err?.errors?.message[0]);
      })
      .finally(() => {
        setLoading(false);

        // setdeListResponse('Property Delisted Successfully');
      });

    getListings(user?.id, {intent: tab}, abortController.signal)
      .then((res: any) => {
        // console.log(res);
        setListings(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };
  const toggleProperty = (id: string) => {
    const abortController = new AbortController();
    setLoading(true);
    // setShowModal(false);
    toggleVisibility(id)
      .then((res: any) => {
        if (res?.data?.isVisible) {
          ktoast.success('Listing is now visible');
        } else {
          ktoast.error('Listing hidden successfully');
        }

        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
        ktoast.error(err?.errors?.message[0]);
      })
      .finally(() => {
        setLoading(false);
      });

    getListings(user?.id, {intent: tab}, abortController.signal)
      .then((res: any) => {
        // console.log(res);
        setListings(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>Listing</h3>
        <p className='text-secondary fs-14'>
          Manage all the properties listed on this platform
        </p>
      </div>

      <div className='row'>
        <div className='col-12'>
          <Fragment>
            <div className='card border-0 border-round shadow-sm d-none d-md-flex'>
              <div className='d-flex justify-content-between'>
                <nav className='flex-fill align-self-end'>
                  <div
                    className='nav nav-theme-horizontal nav-pills nav-justified nav-fill justify-content-start'
                    id='nav-tab'
                    role='tablist'
                  >
                    <button
                      className='nav-link text-secondary py-3 mx-4 active'
                      id='nav-shortlet-tab'
                      data-tab-intent='shortlet'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-shortlet'
                      type='button'
                      role='tab'
                      aria-controls='nav-shortlet'
                      aria-selected='true'
                      onClick={() => setTab('shortlet')}
                    >
                      Shortlet
                    </button>
                    <button
                      className='nav-link text-secondary py-3 mx-4'
                      id='nav-long-lease-tab'
                      data-tab-intent='long-lease'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-long-lease'
                      type='button'
                      role='tab'
                      aria-controls='nav-long-lease'
                      aria-selected='false'
                      onClick={() => setTab('long lease')}
                    >
                      Rent
                    </button>
                    <button
                      className='nav-link text-secondary py-3 mx-4'
                      id='nav-sale-tab'
                      data-tab-intent='sale'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-sale'
                      type='button'
                      role='tab'
                      aria-controls='nav-sale'
                      aria-selected='false'
                      onClick={() => setTab('sale')}
                    >
                      Sale
                    </button>
                    {/* <button
                      className='nav-link text-secondary py-3 mx-4'
                      id='nav-iBuy-tab'
                      data-tab-intent='iBuy'
                      data-bs-toggle='tab'
                      data-bs-target='#nav-iBuy'
                      type='button'
                      role='tab'
                      aria-controls='nav-sale'
                      aria-selected='false'
                      // onClick={() => setTab('iBuying')}
                    >
                      iBuying
                    </button> */}
                  </div>
                </nav>

                <div className='pt-3 pb-1'>
                  <div className='row g-0'>
                    {/* <div className='col-6 d-flex flex-row justify-content-center align-items-center gap-2'>
                      <input
                        type='text'
                        className='form-control border-none'
                        value={search}
                        placeholder='Search Keyword...'
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className='iconly-Search icli fs-22 mr-2' />
                    </div> */}
                    <div className='col-12 px-3'>
                      <PrimaryButton
                        type='primary'
                        text='New Listing'
                        width='100%'
                        click={() => {
                          setModalType('listing');
                          setShowModal(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className='text-center align-middle h-100'>
                  <p>Loading..</p>
                </div>
              ) : listings && listings.length > 0 ? (
                <div className='tab-content' id='nav-tabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='nav-shortlet'
                    role='tabpanel'
                    aria-labelledby='nav-shortlet-tab'
                    tabIndex={0}
                  >
                    <table className='table table-borderless fs-14 d-none d-md-table'>
                      <thead
                        style={{backgroundColor: '#F7F8FF', color: '#0E1956'}}
                      >
                        <tr>
                          {headings.map((heading, index) => (
                            <th className='p-3' key={index} scope='col'>
                              {heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='table-group-divider'>
                        {listings?.map((listing: PropertyListResponse) => (
                          <ListingCard
                            propert={listing}
                            id={listing.id}
                            slug={listing.slug}
                            key={listing.id}
                            property={listing.title}
                            propertyType={listing?.kind?.title}
                            cost={listing.price}
                            duration={listing.cycle}
                            listed={listing.createdAt}
                            status={listing.published}
                            intent={listing.intent}
                            setIntent={setIntent}
                            otherImages={listing.otherImages}
                            // stay={listing.stay}
                            setModalType={setModalType}
                            setShowModal={() => setShowModal(true)}
                            setId={setId}
                            setListingId={setListingId}
                            cardType='listing'
                            isVisible={listing.isVisible}
                            toggleVisibility={() => toggleProperty(listing.id)}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='nav-long-lease'
                    role='tabpanel'
                    aria-labelledby='nav-long-lease-tab'
                    tabIndex={0}
                  >
                    <table className='table table-borderless fs-14 d-none d-md-table'>
                      <tbody className='table-group-divider'>
                        {listings?.map((listing: PropertyListResponse) => (
                          <ListingCard
                            id={listing.id}
                            key={listing.id}
                            property={listing.title}
                            propertyType={listing.intent}
                            cost={listing.price}
                            duration={listing.cycle}
                            listed={listing.createdAt}
                            status={listing.published}
                            // stay={listing.stay}
                            setModalType={setModalType}
                            setShowModal={() => setShowModal(true)}
                            setId={setId}
                            cardType='listing'
                            // archive={archiveProperty}
                            setListingId={setListingId}
                            isVisible={listing.isVisible}
                            toggleVisibility={() => toggleProperty(listing.id)}
                            // slug={listing.slug}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='nav-sale'
                    role='tabpanel'
                    aria-labelledby='nav-sale-tab'
                    tabIndex={0}
                  >
                    <table className='table table-borderless fs-14 d-none d-md-table'>
                      <tbody className='table-group-divider'>
                        {listings?.map((listing: PropertyListResponse) => (
                          <ListingCard
                            id={listing.id}
                            key={listing.id}
                            property={listing.title}
                            propertyType={listing.intent}
                            cost={listing.price}
                            duration={listing.cycle}
                            listed={listing.createdAt}
                            status={listing.published}
                            // stay={listing.stay}
                            setModalType={setModalType}
                            setShowModal={() => setShowModal(true)}
                            setId={setId}
                            cardType='listing'
                            setListingId={setListingId}
                            isVisible={listing.isVisible}
                            toggleVisibility={() => toggleProperty(listing.id)}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className='card border-0 border-round shadow-sm p-5 text-center d-flex align-items-center'>
                  <h6 className='fs-18 mt-1 fw-bold mb-4'>
                    No properties listed yet
                  </h6>
                  <img src={notification} alt='No transactions' width={200} />
                  <p className='fs-14 light-text'>
                    Looks like you haven’t listed any properties yet
                  </p>

                  <PrimaryButton
                    type='outline'
                    text='List your first property'
                    width='250px'
                    click={() => {
                      setModalType('listing');
                      setShowModal(true);
                    }}
                  />
                </div>
              )}
            </div>
            <div className='d-block d-md-none p-1'>
              <div className='col-12 mb-3'>
                <input
                  type='text'
                  className='form-control border-round-xl bg-purple p-2 px-4 mb-2'
                  placeholder='Search...'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <select
                  className='form-select border-round-xl bg-purple p-2 px-4 mb-2'
                  onChange={(e) => {
                    setTab(e.target.value);
                  }}
                >
                  <option defaultValue={tab}>Select Listings</option>
                  <option value='shortlet'>Short Let</option>
                  <option value='long lease'>Rent</option>
                  <option value='sale'>Sale</option>
                </select>
                <PrimaryButton
                  type='primary'
                  text='New Listing'
                  click={() => {
                    setModalType('listing');
                    setShowModal(true);
                  }}
                />
              </div>

              <div className=' col-12'>
                {filterListings(listings, tab)?.map((listing) => {
                  // console.log(listing);

                  return (
                    <ListingCardMobile
                      propert={listing}
                      id={listing.id}
                      key={listing.id}
                      property={listing.title}
                      propertyType={listing?.kind?.title}
                      cost={listing.price}
                      duration={listing.cycle}
                      listed={listing.createdAt}
                      status={listing.published}
                      intent={listing.intent}
                      setIntent={setIntent}
                      // stay={listing.stay}
                      setModalType={setModalType}
                      setShowModal={() => setShowModal(true)}
                      setId={setId}
                      setListingId={setListingId}
                      cardType='listing'
                      otherImages={listing.otherImages}
                      isVisible={listing.isVisible}
                      toggleVisibility={() => toggleProperty(listing.id)}
                    />
                  );
                })}
              </div>
            </div>
          </Fragment>
        </div>
      </div>

      <Modal
        id={id.toString()}
        show={showModal}
        size={modalType === 'listing' ? 'lg' : 'md'}
        onClose={() => {
          setShowModal(false);
          setdeListResponse('');
        }}
      >
        <div className='col-12 px-4'>
          {modalType === 'listing' && (
            <>
              <h6 className='fw-bold mb-4'>
                What kind of listing do you want to create?
              </h6>
              <ListingTypeCard
                title='Short Let'
                description='Rent out your apartments to people for a short period of time'
                click={() => navigate('/listings/shortlet/')}
              />
              <ListingTypeCard
                title='Rent'
                description='Rent out your apartments to people for longer periods. e.g 6 months'
                click={() => navigate('/listings/rental/')}
              />
              <ListingTypeCard
                title='Sales'
                description='Sell properties or apartments to people'
                click={() => navigate('/listings/sales/')}
              />
              <ListingTypeCard
                title='Krent iBuying'
                description='Sell properties instantly to Krent '
                // click={() => navigate('/listings/iBuying/')}
              />
            </>
          )}
          {modalType === 'delisting' && (
            <div className='col'>
              <h5 className='fw-bold mb-4'>Hide Property</h5>
              <p className='light-text'>
                Are you sure you want to Hide this property, it will need to be
                reviewed before it goes live again
              </p>
              <div className='row mt-3'>
                <div className='col-7'>
                  <PrimaryButton
                    type='primary'
                    text='Delete Property'
                    click={() => {
                      toggleProperty(id);
                      setShowModal(false);
                    }}
                    close
                    bgColor='#EA4335'
                  />
                </div>
                <div className='col-5'>
                  <PrimaryButton
                    type='primary'
                    text='Cancel'
                    close
                    click={() => setShowModal(false)}
                  />
                </div>
              </div>
            </div>
          )}
          {modalType === 'edit-listing' && (
            <div className='col'>
              <h5 className='fw-bold mb-4'>
                Are you sure you want to edit a listing that is live
              </h5>
              <p className='light-text'>
                Editing properties that are live will take them offline and you
                can resubmit for review when you’re done. Do you want to proceed
              </p>
              <div className='row'>
                <div className='col-7'>
                  <PrimaryButton
                    type='primary'
                    text='Edit Listing'
                    click={() => {
                      let int;
                      if (intent === 'long lease') {
                        int = 'rental';
                      } else if (intent === 'sale') {
                        int = 'sales';
                      } else {
                        int = intent;
                      }
                      navigate(`/listings/${int}/?id=${id}`);
                      // setShowModal(false);
                    }}
                  />
                </div>
                <div className='col-5'>
                  <PrimaryButton
                    type='primary'
                    text='Cancel'
                    close
                    click={() => setShowModal(false)}
                  />
                </div>
              </div>
            </div>
          )}
          {modalType === 'delete-listing' && (
            <div className='col'>
              <h5 className='fw-bold mb-4'>Delete Listing</h5>
              {/* {!deListResponse ? ( */}
              <p className='light-text'>
                Are you sure you want to delete this listing, this action is
                irreversible
              </p>
              {/* ) : (
                <p>{deListResponse}</p>
              )} */}

              <div className='row'>
                {!deListResponse && (
                  <div className='col-7'>
                    <PrimaryButton
                      type='primary'
                      text='Delete Listing'
                      click={() => {
                        archiveProperty(listingId);

                        setShowModal(false);
                      }}
                      close
                      bgColor='#EA4335'
                    />
                  </div>
                )}
                <div className={!deListResponse ? 'col-5' : 'col-12'}>
                  <PrimaryButton
                    type='primary'
                    text={!deListResponse ? 'Cancel' : 'Close'}
                    close
                    click={() => setShowModal(false)}
                    bgColor={deListResponse ? '#EA4335' : ''}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </DashboardLayout>
  );
};
