// import { format } from 'date-fns';

type Props = {
  description: string;
  description2?: string;
  cautionFees?: boolean;
  property?: string;
  value: number;
  time: Date;
  type?: string;
};

export const TransactionCard: React.FC<Props> = ({
  description,
  description2,
  property = '',
  value,
  time,
  type = '',
}: Props) => {
  return (
    <div className='row mt-3'>
      <div className='col-8'>
        <div>
          <p className='fs-14 fw-bold'>{`	${description2 || description} ${
            !property ? '' : `(${property})`
          }`}</p>
        </div>
        <div>
          <p className='light-text fs-12'>
            {/* {format(new Date(time), 'yyyy/MM/dd').toString()} */}
            {new Date(time?.toLocaleString())?.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </p>
        </div>
      </div>
      <div className='col-4 text-start text-md-end fs-14'>
        <p
          className={`fw-bold ${
            type === 'Payment' || 'payout'
              ? 'text-success'
              : type === 'Withdrawal'
              ? 'text-danger'
              : type === 'caution'
              ? ''
              : ''
          }`}
        >
          {type === 'caution'
            ? ' '
            : type === 'Payment' || 'payout'
            ? '+'
            : type === 'Withdrawal'
            ? '-'
            : ''}
          N{value?.toLocaleString()}
        </p>
      </div>
    </div>
  );
};
