import {useEffect} from 'react';

type Props = {
  setShowModal?: (showModal: boolean) => void;
  index: number;
  btnType?: 'primary' | 'outline';
  children: JSX.Element[];
};

export const ListingFormComponent = ({children, index}: Props) => {
  const barProgress = ((index + 1) / children.length) * 100;

  useEffect(() => {
    const forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.from(forms).forEach((form: any) => {
      form.addEventListener(
        'submit',
        (event: React.FormEvent<HTMLFormElement>) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add('was-validated');
        },
        false
      );
    });

    // TODO: Add clean up function
  }, [index]);

  return (
    <div>
      <div className='progress border-round-top'>
        <div
          className='progress-bar'
          style={{width: `${barProgress}%`, backgroundColor: '#47bba9'}}
        ></div>
      </div>

      <div className='container p-4 px-md-5 justify-content-center'>
        <div className='col-12'>{children[index]}</div>

        {/* <div className='col-12 text-end m-2'>
					{index > 0 && (
						<button className='btn-el fw-bold mr-3' onClick={() => setIndex(index - 1)}>
							Go Back
						</button>
					)}

					{index < children.length - 1 && (
						<PrimaryButton
							type={btnType || 'outline'}
							text='Continue'
							width='50%'
							click={(e) => {
								e.preventDefault();
								setIndex(index + 1);
							}}
						/>
					)}

					{index === children.length - 1 && index !== 0 && (
						<PrimaryButton
							type={btnType || 'outline'}
							text={btnType === 'primary' ? 'Submit' : 'Continue'}
							width='50%'
							click={(e) => {
								e.preventDefault();
								setShowModal!(true);
							}}
						/>
					)}
				</div> */}
      </div>
    </div>
  );
};
