import {addPageToSessionStorage} from '@utils/helpers';

interface Page {
  page: number;
  numOfPages: number;
  changePage: (arg: number | string) => void;
  setPage: (arg: number) => void;
  // page_count?: number;
}

const Pagination: any = (prop: Page) => {
  // const dispatch = useAppDispatch();

  const {
    page,
    // changePage,
    numOfPages,
    // total,
    // limit,
    setPage,
  } = prop;

  // const numOfPages = Math.ceil(total / (limit || 20));

  // const handlePageChange = (pageNumber: number | string) => {
  //  changePage(pageNumber);
  //   setQuery({...query, page: pageNumber});
  // };

  const changePage = (page: number) => {
    setPage(page);
    // add page to session storage
    addPageToSessionStorage(page);
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const addPageButton = ({
    pageNumber,
    activeClass,
  }: {
    pageNumber: number;
    activeClass: any;
  }) => {
    return (
      <li
        className={activeClass ? 'page-item active border-danger' : 'page-item'}
        key={pageNumber + 1}
      >
        <button
          className='page-link text-primary'
          onClick={() => {
            changePage(pageNumber);
          }}
        >
          {pageNumber}
        </button>
      </li>
    );
  };
  const handleNext = () => {
    let newPage = page + 1;
    if (newPage > numOfPages) {
      newPage = 1;
    }

    changePage(newPage);
  };
  const handlePrev = () => {
    let newPage = page - 1;
    if (newPage < 1) {
      newPage = numOfPages;
    }

    changePage(newPage);
  };

  const renderPageButtons = () => {
    const pageButtons: any = [];
    // first Button
    pageButtons.push(addPageButton({pageNumber: 1, activeClass: page === 1}));

    // dots
    if (page > 2) {
      pageButtons.push(
        <ul
          className=' pagination text-primary align-items-center '
          key={'dots-1'}
        >
          <li className='page-item m-2 text-primary'>...</li>
        </ul>
      );
    }

    // active/current Button
    if (page !== 1 && page !== numOfPages) {
      pageButtons.push(addPageButton({pageNumber: page, activeClass: true}));
    }
    // dots
    if (page < numOfPages - 1) {
      pageButtons.push(
        <ul
          className=' pagination align-items-center text-primary'
          key={'dot-2'}
        >
          <li className='page-item m-2 text-primary'>...</li>
        </ul>
      );
    }
    // last Button
    pageButtons.push(
      addPageButton({pageNumber: numOfPages, activeClass: page === numOfPages})
    );
    return pageButtons;
  };

  if (numOfPages < 2) return null;

  return (
    <div className='text-primary mb-5'>
      {/* <!-- Pagination (prev) --> */}
      <ul className=' pagination justify-content-center text-primary'>
        {/* <li className='page-item' onClick={prev}> */}
        <li className='page-item' key={'prev1'}>
          <button
            className='page-link text-primary'
            onClick={() => {
              handlePrev();
            }}
          >
            <i className='fe fe-arrow-left ms-1 text-primary'></i> Previous
          </button>
        </li>

        {/* </li> */}

        {/* <!-- Pagination --> */}

        {renderPageButtons()}

        {/* <!-- Pagination (next) --> */}

        <li className='page-item text-primary' key={'next1'}>
          <button
            className='page-link text-primary'
            onClick={() => {
              handleNext();
            }}
          >
            Next <i className='fe fe-arrow-right ms-1 text-primary'></i>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;

// interface Page {
//   page: number;
//   numOfPages: number;
//   changePage: (page: number) => void;
// }

// const Pagination: any = (prop: Page) => {
//   const { numOfPages, page, changePage } = prop;

//   const pages = Array.from({ length: numOfPages }, (_, index) => {
//     return index + 1;
//   });

//   const handleNext = () => {
//     let newPage = page + 1;
//     if (newPage > numOfPages) {
//       newPage = 1;
//     }
//     changePage(newPage);
//   };
//   const handlePrev = () => {
//     let newPage = page - 1;
//     if (newPage < 1) {
//       newPage = numOfPages;
//     }
//     changePage(newPage);
//   };

//   if (pages.length < 2) {
//     return null;
//   }

//   return (
//     <div className='row g-0 py-4'>
//       {/* <!-- Pagination (prev) --> */}
//       <div className='col list-pagination-prev pagination pagination-tabs justify-content-start'>
//         {/* <li className='page-item' onClick={prev}> */}
//         <span className='page-item ' onClick={handlePrev}>
//           <button className='page-link'>
//             <i className='fe fe-arrow-left ms-1'></i>
//             Previous
//           </button>
//         </span>
//         {/* </li> */}
//       </div>

//       {/* <!-- Pagination --> */}
//       <ul className='col list-pagination pagination pagination-tabs justify-content-center'>
//         {pages.map((pageNum) => {
//           return (
//             <li
//               className={pageNum === page ? 'page-item active' : 'page-item'}
//               key={pageNum}
//             >
//               <button
//                 className='page-link'
//                 onClick={() => {
//                   changePage(pageNum);
//                 }}
//               >
//                 {pageNum}
//               </button>
//             </li>
//           );
//         })}
//       </ul>

//       {/* <!-- Pagination (next) --> */}
//       <div className='col list-pagination-next pagination pagination-tabs justify-content-end'>
//         <span className='page-item'>
//           <button className='page-link' onClick={handleNext}>
//             Next <i className='fe fe-arrow-right ms-1'></i>
//           </button>
//         </span>
//       </div>
//     </div>
//   );
// };

// export default Pagination;
