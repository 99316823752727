import GetMeta from '@pages/SeoPages/GetMeta';
import {DashboardSideBar} from '@shared/navbars';

type Props = {
  children?: JSX.Element | JSX.Element[];
};

export const DashboardLayout: React.FC<Props> = ({children}: Props) => {
  return (
    <div className='container-fluid'>
      <GetMeta description='Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more' />
      {/* <div className='row'> */}
      <div className='row vh-100'>
        <div className='col-12  col-lg-3 col-xl-2 bg-dark text-light px-0 fixed-top side-bar '>
          {/* <div className='col-12 col-md-4 col-lg-3 col-xl-2 bg-dark text-light px-0 position-fixed top-0 start-0 bottom-0'> */}
          <DashboardSideBar />
        </div>
        <div className='col-12 col-md-12 col-lg-9 col-xl-10 bg-light px-0 ms-sm-auto position-relative mt-5 mt-lg-0 '>
          <div className='py-3 text-center bg-opacity-10 notification-bar'>
            <p className='mb-0 fs-14 fw-600'></p>
          </div>
          <div className='py-2  p-md-4'>
            {/* px-3 removed from the div */}
            <div className='container mt-5'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
