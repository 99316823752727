import { PrimaryButton } from '@shared/buttons';

type Props = {
  title: string;
  description: string;
  click?: () => void;
};

export const ListingTypeCard = ({ title, description, click }: Props) => {
  return (
    <div className='card border border-round mb-3'>
      <div className='row fs-14 p-3 g-0'>
        <div className='col-12 col-md-8'>
          <p className='fw-bold mb-0'>{title}</p>
          <p className='light-text mb-2 mb-md-0'>{description}</p>
        </div>
        <div className='col-12 col-md-4'>
          <PrimaryButton
            text={title === 'Krent iBuying' ? 'Coming Soon' : 'Continue'}
            type='outline'
            click={click}
          />
        </div>
      </div>
    </div>
  );
};
