import {BaseLayout} from '@layouts/index';
import {
  ReviewCard,
  SearchCard2,
  // SearchNav
} from '@shared/cards';

import {PropertyCardList} from '@lists/PropertyCardList';
import {testimonials} from './data';
// import {LocationCardList} from '@lists/LocationCardList';
import {NewsCardList} from '@lists/NewsCardList';
import {TestimonialCardList} from '@lists/TestimonialCardList';
import {createSearchParams, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
  getProperties,
  getPropertyKinds,
  // getTopLocations,
  // tempDelete,
} from '@services/krent.service';
import {
  PropertyKind,
  PropertyListResponse,
  // PropertyLocationResponse,
  PropertySummaryResponse,
} from '@interfaces/properties.interface';
import {BaseApiResponse, PaginatedResponse} from '@interfaces/app.interface';
// import { getBlogNews } from '@services/blog.service';
// import { NewsListResponse } from '@interfaces/news.interface';
import bgImg from '../../assets/images/Ikoyi2.jpg';
// import bgImg from '../../assets/images/kherobg.jpeg';
import {NewsListResponse} from '@interfaces/news.interface';
import {SectionHeading} from 'components/SectionHeading';
import PopularLocation from 'components/PopularLocation';
import Services from 'components/Services';
import NewsLetter from 'components/NewsLetter';
import {addPageToSessionStorage} from '@utils/helpers';
import AdModal from 'components/AdModal';
// import {Helmet} from 'react-helmet';
import AboutHome from 'components/AboutHome';
import GetMeta from '@pages/SeoPages/GetMeta';

// import GlobalSearch from 'components/GlobalSearch';
// import BreakingNews from 'components/breakingNews/BreakingNews';

export const Landing2 = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyIntent, setPropertyIntent] = useState<string>('');

  const [properties, setProperties] = useState<PropertyListResponse[]>([]);
  const [propertyKinds, setPropertyKinds] = useState<PropertyKind[]>([]);
  const [news, setNews] = useState<NewsListResponse[]>([]);
  const [adRight, setAdRight] = useState(0);

  // const [topLocations, setTooLocations] = useState<PropertyLocationResponse[]>(
  //   []
  // );
  const navigate = useNavigate();

  const handleSearchTab = (int: any) => {
    let params: URLSearchParams = new URLSearchParams();
    // const int = e.target.value;
    if (int) params.append('intent', int);
    // reset the page in session storage back to 1
    addPageToSessionStorage(1);
    setPropertyIntent(int);

    navigate(
      {
        pathname: '/explore/',
        search: `?${createSearchParams(params)}`,
      },
      {replace: false}
    );
  };

  useEffect(() => {
    setLoading(true);
    let abortController = new AbortController();
    getProperties({}, abortController.signal)
      .then(
        (
          res: BaseApiResponse<
            PropertyListResponse[],
            PaginatedResponse,
            PropertySummaryResponse
          >
        ) => {
          setProperties(res.data);
        }
      )
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, []);

  // useEffect(() => {
  //   let abortController = new AbortController();
  //   getTopLocations(abortController.signal)
  //     .then((res: BaseApiResponse<PropertyLocationResponse[]>) => {
  //       setTooLocations(res.data);
  //     })
  //     .catch(() => {})
  //     .finally(() => {});

  //   return () => {
  //     return abortController.abort();
  //   };
  // }, []);

  useEffect(() => {
    let abortController = new AbortController();
    getPropertyKinds(abortController.signal)
      .then((res: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
        setPropertyKinds(res.data);
      })
      .catch(() => {})
      .finally(() => {});

    return () => {
      return abortController.abort();
    };
  }, []);

  useEffect(() => {
    let abortController = new AbortController();

    const nNews = async () => {
      const url = process.env['REACT_APP_BLOG_BASE_URL'];

      try {
        const res = await fetch(`${url}`);
        let result = await res.json();
        setNews(result);
        return result;
      } catch (error) {
        console.log(error);
      }
    };
    nNews();
    return () => {
      abortController.abort();
    };
  }, []);

  const maxPrice = properties
    .map((prop) => prop.price)
    .reduce((a, b) => {
      return Math.max(a, b);
    }, 0);

  // const minPrice = properties
  //   .map((prop) => prop.price)
  //   .reduce((a, b) => {
  //     return Math.min(a, b);
  //   }, maxPrice);
  // console.log(maxPrice);
  // console.log(minPrice);

  return (
    <BaseLayout news={news} handleSearchTab={handleSearchTab}>
      {/* <button onClick={deleteT}>delete</button> */}
      {/* <BreakingNews news={news} /> */}
      <GetMeta
        description='Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more'
        canonicalValue=' '
      />
      {/* <Helmet>
        <title>
          Real Estate & Property in Nigeria for Sale and Rent - Krent
        </title>
        <meta
          name='description'
          content='Krent is a real estate and property listing website in Nigeria. Find homes to buy or rent from trusted landlords and agents. Sell your property or find a new home easily with our wide selection of flats, apartments, houses, and more'
        />
      </Helmet> */}

      <section
        className='container-fluid pb-5 position-relative hero-section'
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0,0,0,0.8)), url(${bgImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
        }}
      >
        <div className='container-fluid d-flex  justify-content-center '>
          <div
            className='container d-flex position-absolute  flex-column flex-lg-row  align-items-center justify-content-between  px-lg-3 gap-5  gap-lg-1 hero'
            // style={{bottom: '-0.8rem'}}
          >
            <div className='col-12  col-lg-5 text-center hero-title'>
              <h1 className='text-white mb-2  fw-600 font-display '>
                Own Your{' '}
                <span className='text-primary font-display'>Dreams</span>{' '}
              </h1>
              <h2 className='text-white  fw-500 '>
                Building the Future of Real Estate in Africa...
              </h2>
            </div>
            <div className='bg-white col-12  col-lg-7 rounded'>
              {/* <SearchNav /> */}
              <SearchCard2
                // extraClass='rounded-top-left-0 rounded-top-right-0'
                sizeClass='col-md-6 col-9'
                intent={propertyIntent}
                // setPropertyIntent={setPropertyIntent}
                propertyTypes={propertyKinds}
                maxPprice={maxPrice}
              />
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid pb-5'>
        <div className='row px-md-0'>
          <ReviewCard />
        </div>
      </section>

      <section className='container '>
        <SectionHeading
          title='Some homes you might like'
          text='Refined to your taste'
        />
        {/* <h5 className='fw-bolder'>Discover Properties</h5>
        <p className='text-muted fs-12'>
          See the best verified rental deals available today
        </p> */}
        <PropertyCardList
          properties={properties}
          loading={loading}
          items={9}
          showBtn
        />
      </section>

      <section className='container pb-5 px-0 px-md-3'>
        <SectionHeading
          title='About Us'
          text='We revolutionize the real estate market with a blend of innovative technology and expert knowledge.'
        />
        <AboutHome />
      </section>
      <section className='container pb-5 px-0 px-md-3'>
        <PopularLocation />
      </section>
      <section className='pb-5'>
        <div className='container'>
          <SectionHeading
            title='Testimonials'
            text='Take a look at what some our clients has to say'
          />

          <TestimonialCardList testimonials={testimonials} />
        </div>
      </section>

      <section className=' py-5 px-2 service'>
        <div className='container d-flex justify-content-center justify-content-lg-start'>
          <Services />
        </div>
      </section>
      <section className='py-5'>
        <div className='container'>
          <SectionHeading
            title='Blog Posts'
            text='Stay up to date with the latest from krent'
          />

          <NewsCardList lg={3} news={news.slice(0, 3)} />
        </div>
      </section>
      <section className=''>
        <div className='bg-primary p-5 p-md-0 px-md-5 px-lg-0 container-fluid'>
          <NewsLetter />
        </div>
      </section>

      {news.length > 0 ? (
        <div
          className='position-fixed  '
          style={{
            bottom: '1rem',
            right: `${adRight}rem`,
            zIndex: '99',
            background: '#282626',
            width: '18.75rem',
            height: '8.125rem',
            transition: 'all 1s ease-in-out',
          }}
        >
          <AdModal news={news} />
          <span
            className='position-absolute '
            style={{
              top: '-10px',
              left: '-5px',
            }}
            onClick={() => {
              setAdRight(-50);
            }}
          >
            <i className='fa fa-x border border-secondary fs-8 p-1  rounded-pill bg-white text-secondary cursor-pointer'></i>
          </span>
        </div>
      ) : (
        <span></span>
      )}
      <div
        className={`bg-primary position-fixed px-2 text-white cursor-pointer `}
        style={{
          height: '5rem',
          width: '1.5rem',
          bottom: '1rem',
          transition: 'all 1s ease-in-out',
          right: ` ${adRight === 0 ? '-50rem' : '0'}`,
        }}
        onClick={() => setAdRight(0)}
      >
        <span>
          N <br></br>e<br></br>w<br></br>s
        </span>
        <span
          className=' cursor-pointer position-absolute'
          style={{height: '8px', width: '8px', left: '-8px', top: '1.5rem'}}
        >
          <i className='fa fa-caret-left fs-20 p-1 text-primary cursor-pointer  '></i>
        </span>
      </div>
    </BaseLayout>
  );
};
