const NotificationBellDropdown = ({
  notifications,
  count,
  markRead,
}: {
  notifications: any;
  count: number;
  markRead: any;
}) => {
  return (
    <div style={{zIndex: '99999'}}>
      {/* Notification Bell */}
      <a
        className='navbar-user-link mb-3 position-relative'
        data-bs-toggle='offcanvas'
        href='#sidebarOffcanvasActivity'
        aria-controls='sidebarOffcanvasActivity'
        data-bs-scroll='true'
      >
        <span className='icon'>
          <i
            className='fa fa-bell text-primary'
            style={{fontSize: '1.5rem'}}
          ></i>
        </span>
        {/* {unreadCount > 0 && ( */}
        <span
          className={`badge bg-danger position-absolute top-${-2} start-100 translate-middle`}
          style={{fontSize: '0.65rem'}}
        >
          {count}
        </span>
        {/* )} */}
      </a>

      {/* Offcanvas: Activity */}
      <div
        className='offcanvas offcanvas-start'
        id='sidebarOffcanvasActivity'
        tabIndex={-1}
      >
        <div className='offcanvas-header'>
          <h4 className='offcanvas-title'>Notifications</h4>
          <button
            type='button'
            className='btn-close text-danger cursor-pointer'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>
        <div className='offcanvas-body'>
          <div className='list-group list-group-flush list-group-activity my-n3'>
            {notifications?.map((notification: any) => (
              <span
                key={notification.id}
                className={`list-group-item text-reset cursor-pointer ${
                  !notification.read ? 'bg-primary mt-1' : ''
                }`}
                onClick={() => markRead(notification.id)}
              >
                <div className='row'>
                  <div className='col-auto'>
                    <div className='avatar avatar-sm'>
                      <div className='avatar-title fs-lg bg-primary-soft rounded-circle text-primary'>
                        <i className={`fa ${notification.icon}`}></i>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col ms-n2  ${
                      !notification.read ? 'text-white' : 'text-gray'
                    }`}
                  >
                    <h5 className='mb-1'>{notification.title}</h5>
                    <p
                      className={`small ${
                        !notification.read ? 'text-white' : 'text-gray-700'
                      } mb-0`}
                    >
                      {notification.message}
                    </p>
                    <small className=' fw-bolder'>
                      {' '}
                      {new Date(
                        notification.createdAt.toLocaleString()
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </small>
                  </div>
                </div>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBellDropdown;
