type Props = {
  children?: JSX.Element | JSX.Element[];
};

export const OnboardingLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className='container-fluid bg-white'>
      <div className='row vh-100'>
        <div
          className='col-12 col-lg-5 col-md-6 px-lg-5 align-self-center justify-content-center w-form-responsive mx-auto '
          style={{ height: '95%', overflow: 'scroll' }}
        >
          {children}
        </div>
        <div className='col-12 col-lg-7 col-md-6 onboarding-bg d-none d-md-block'></div>
      </div>
    </div>
  );
};
