import {useSessionStorage} from '../hooks';
import {getProfile} from '@services/krent.service';
import {fetchCookie, removeCookie} from '@utils/cookies';
import {isEmpty} from '@utils/helpers';
import {useEffect, useMemo, useState} from 'react';
import {Outlet, Navigate, useLocation} from 'react-router-dom';

type Props = {
  roleOnly?: string;
  roleAgent?: string;
};
export const PrivateRoutes = ({roleOnly}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useSessionStorage('user', {});
  // const [kyc, setKyc] = useSessionStorage('kyc', {});

  const location = useLocation();
  const auth = useMemo(() => {
    return {isAuthenticated: fetchCookie('1Q_SPA') ? true : false};
  }, []);

  // useEffect(() => {
  //   const fetchKyc = async () => {
  //     const resp = await getPersonalKycStatus();
  //     setKyc(resp);
  //   };
  //   fetchKyc();
  // }, []);

  useEffect(() => {
    let abortController = new AbortController();

    if (auth.isAuthenticated && isEmpty(user)) {
      getProfile(abortController.signal)
        .then((re: any) => {
          setUser(re['data']);
        })
        .catch((error: any) => {
          console.log('Error: ', error);
          removeCookie('1Q_SPA');
          auth.isAuthenticated = false;
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        });
    } else {
      setLoading(false);
    }

    return () => {
      if (!abortController.signal.aborted) abortController.abort();
    };
  }, [user, setUser, auth]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return auth.isAuthenticated && !loading ? (
    !roleOnly ? (
      <Outlet />
    ) : user && user?.role === 'landlord' ? (
      <Outlet />
    ) : user && user?.role === 'agent' ? (
      <Outlet />
    ) : user && user?.role === 'user' ? (
      <Outlet />
    ) : (
      <Navigate to='/' state={{authenticated: true, from: location}} replace />
    )
  ) : (
    <Navigate
      to='/sign-in/'
      state={{authenticated: false, from: location}}
      replace
    />
  );
};
