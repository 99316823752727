import {BaseLayout} from '@layouts/BaseLayout';

import PageHero from '@shared/InnerPageHero';
import ContactInfo from '@shared/Contact-info';
import {InputComponent} from '@shared/forms';
import {PrimaryButton} from '@shared/buttons';
import KrentAddress from '@shared/map/KrentAddress';
import GetMeta from '@pages/SeoPages/GetMeta';

const Contact = () => {
  return (
    <BaseLayout>
      <GetMeta
        description='Get in touch with us for any inquiries, support, or feedback regarding our property listing site. We are here to help you with your property search, listing, or any other questions you may have.'
        title={`Contact Us | Krent Inc`}
        canonicalValue={'contact'}
      />
      <section className='pb-5 bg-light'>
        <div className='pb-5 bg-light' style={{marginTop: '-2rem'}}>
          <PageHero img='/assets/privacy.jpeg' currentPage='Contact' />
        </div>
        <div className='container-fluid  pb-5 bg-light'>
          <div className='container'>
            <div className='text-center pb-3'>
              <h1 className='fw-bolder '>
                Connect with Us for All Your Real Estate Needs
              </h1>
              <div className='d-flex justify-content-center row'>
                <p className=' col-md-9 col-lg-7'>
                  Welcome to our Contact page, where you can reach out to our
                  team of experienced professionals for personalized support and
                  assistance. Whether you have questions about buying or selling
                  property, or just want to learn more about the real estate
                  market, we're here to help. Fill out the form on this page to
                  send us a message, or use our directory to find the best
                  contact information for your specific needs. Our goal is to
                  make your real estate journey as seamless and stress-free as
                  possible, so don't hesitate to reach out to us today.
                </p>
              </div>
            </div>
          </div>
        </div>
        <ContactInfo />
        {/* <section className='row  bg-light d-flex'> */}
        <section className='row col-lg-10  contact  cont-map bg-light justify-content-center mx-auto'>
          <div className='  col-lg-5 mt-3'>
            <div className='row'>
              <div className=' contact-form  '>
                <form id='contact-form'>
                  <div className='section-head'>
                    <h6>Contact Us</h6>
                    <h4 className='playfont'>Get In Touch</h4>
                  </div>

                  <div className='messages'></div>

                  <div className='controls'>
                    <div className='col mb-4'>
                      <InputComponent
                        label='Email Address'
                        placeholder='Enter your email address'
                        icon='Message'
                        type='email'
                        change={(e) => e.target.value}
                      />
                    </div>
                    <div className='col mb-3'>
                      <InputComponent
                        label=' Name'
                        placeholder='Enter your name'
                        icon='user'
                        iconType='fa'
                        type='text'
                        change={(e) => e.target.value}
                        required
                      />
                    </div>

                    <div className='col mb-3 '>
                      <textarea
                        id='form_message'
                        name='message'
                        placeholder='Message'
                        rows={4}
                        required
                      ></textarea>
                    </div>

                    <div className='col text-center'>
                      <PrimaryButton
                        type='primary'
                        text='Send'
                        // loading={loading}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='contact-map map col-lg-6 mt-3 '>
            <KrentAddress />
            {/* <iframe src={appData.mapIframe}></iframe> */}
          </div>
          {/* <div
            className='bg-img'
            style={{ backgroundImage: 'url(/assets/img/2.jpg)' }}
          ></div> */}
        </section>
      </section>
    </BaseLayout>
  );
};

export default Contact;
