// import './App.css';

// import { SingleProperty } from '@layouts/SingleProperty';
// import { PropertyCard } from './components/shared/cards/PropertyCard';
// import propertyImage from './assets/images/property-card.png';
import './App.css';

// import { InputComponent } from './components/shared/forms/InputComponent';

// import { SearchCard } from '@shared/cards';

const App = () => {
  return (
    <div
      style={{
        width: '100vw',
      }}
    >
      {/* <SearchCard
				location='New York, USA'
				price='$500-$2,500'
				propertyType='Private House'
				propertySize='120m-150m'
			/> */}
      {/* <PropertyCard title="Palm Harbor" poster={propertyImage} type="sale" /> */}
    </div>
  );
};

export default App;
