export const ReviewCard = () => {
  return (
    <div className='container-fluid bg-primary-light p-4 col-12'>
      {/* <div className='d-flex align-items-center justify-content-center col-12 d-lg-none '>
        <span className=' fw-bolder d-inline-flex  '>
          <i className='fa fa-circle-check   fs-48 fw-bolder text-primary p-3 '></i>
        </span>
        <div className='d-flex flex-column'>
          <p className='fs-24 fw-600 font-onest p-0'>8 out of 10</p>
          <p className='fs-14 font-onest p-0 m-0'>Buyers recommend Krent</p>
        </div>
      </div> */}
      <div
        className='container d-flex align-items-center justify-content-between px-lg-5  '
        // style={{gap: '67px'}}
      >
        {/* recommend */}
        <div className='d-flex align-items-center justify-content-center col-12 col-md-6 col-lg-4'>
          <span className=' fw-bolder d-inline-flex  '>
            <i className='fa fa-circle-check   fs-48 fw-bolder text-primary p-3 '></i>
          </span>
          <div className='d-flex flex-column'>
            <p className='fs-24 fw-600 font-onest p-0'>8 out of 10</p>
            <p className='fs-14 font-onest p-0 m-0'>Buyers recommend Krent</p>
          </div>
        </div>
        {/* stars */}
        <div className='d-none d-md-flex col-md-6 col-lg-4 align-items-center justify-content-center'>
          <span className=' fw-bolder d-inline-flex  '>
            <i className='fa fa-circle-check   fs-48 fw-bolder text-primary p-3 '></i>
          </span>
          <div className='d-flex flex-column align-items-center'>
            <p className='fs-24 fw-600 font-onest p-0 d-flex gap-1'>
              <span className=''>
                <i className='fa fa-star fs-12 fw-bolder  star'></i>
              </span>
              <span className=''>
                <i className='fa fa-star fs-12 fw-bolder  star'></i>
              </span>
              <span className=''>
                <i className='fa fa-star fs-12 fw-bolder  star'></i>
              </span>
              <span className=''>
                <i className='fa fa-star fs-12 fw-bolder  star'></i>
              </span>
              <span className=''>
                <i className='fa fa-star fs-12 fw-bolder  star'></i>
              </span>
            </p>
            <p className='fs-14 font-onest p-0 m-0'>100+ Reviews</p>
          </div>
        </div>
        {/* Easy to use */}
        <div className='d-none d-lg-flex col-lg-4 align-items-center justify-content-center'>
          <span className=' fw-bolder d-inline-flex  '>
            <i className='fa fa-circle-check   fs-48 fw-bolder text-primary p-3 '></i>
          </span>
          <div className='d-flex flex-column'>
            <p className='fs-24 fw-600 font-onest p-0'>Easy to use</p>
            <p className='fs-14 font-onest p-0 m-0'>
              Simple and functional interface
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
