import Tippy from '@tippyjs/react';
import React, {Fragment} from 'react';

export function DocumentUpload(
  transaction: any,
  user: any,
  setShowAddBankModal: any,
  setOpenIncident: (arg: boolean) => void
  // setShowExtendBooking: (arg: boolean) => void
): React.ReactNode {
  return (
    <Fragment>
      <button
        className='btn btn-link fs-12'
        onClick={() => {
          setOpenIncident(true);
        }}
      >
        Report a problem
      </button>

      {transaction.renter?.id === user?.id ? (
        <div className='mb-3'>
          <p className='fs-14 alert alert-success' role='alert'>
            Your payment was successful.
          </p>
          <div className='alert alert-primary' role='alert'>
            <p className='fs-14'>
              <i className='iconly-Paper align-text-bottom icli fs-22 me-1'></i>
              <a
                className='fs-14'
                target='_blank'
                rel='noreferrer'
                href={transaction.renterSigningLink}
              >
                Click to sign legal document
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className='mb-3 '>
          <div className='alert alert-success' role='alert'>
            <p className='fs-14'>Payment has been completed.</p>
          </div>
          <div className='alert alert-primary' role='alert'>
            <p className='fs-14'>
              <i className='iconly-Paper align-text-bottom icli fs-22 me-1'></i>
              <a
                className='fs-14'
                target='_blank'
                rel='noreferrer'
                href={transaction.landlordSigningLink}
              >
                Click to sign legal document
              </a>
            </p>
          </div>
          {!transaction?.listing?.landlordAccountDetails && (
            <div>
              {' '}
              <h6 className='fs-10 fw-bold m-0'>
                Add Landlord's Account Details
              </h6>
              <button
                className='btn btn-primary'
                onClick={() => setShowAddBankModal(true)}
              >
                Add Account
              </button>
              <Tippy content='Due to your affiliation as an agent with this property,we will therefore transfer the due amount to the landlord and remit your commission to your account.'>
                <p className='fs-12 mt-1 ml-1 text-primary'>
                  why am i doing this?
                </p>
              </Tippy>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
