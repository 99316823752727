// import {useSessionStorage} from '../hooks';
import React, {useEffect, useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

import RequestForm from './RequestForm';

const MODAL_EXPIRY_TIME = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

const FIRST_VISIT_DELAY = 2 * 60 * 1000; // 2 minutes in milliseconds
// const FIRST_VISIT_DELAY = 20000; // 2 minutes in milliseconds

const CHECK_INTERVAL = 60 * 60 * 1000; // 1 hour in milliseconds

const GetHelp = () => {
  // const [user] = useSessionStorage('user', {});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [requestFormOpen, setRequestFormOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});

  const closeModal = () => setIsModalOpen(false);

  const shouldShowModal = () => {
    const modalTimestamp = sessionStorage.getItem('modalTimestamp');
    const sessionUser = JSON.parse(sessionStorage.getItem('user') || '{}');

    // Update user state if sessionUser is different from current user state
    if (JSON.stringify(sessionUser) !== JSON.stringify(user)) {
      setUser(sessionUser);
    }

    // Extract role from sessionUser and define conditions for displaying the modal
    const userRole = sessionUser?.role;
    const userIsLoggedOut = Object.keys(sessionUser).length === 0;
    const userHasRoleUser = userRole === 'user';

    // Prevent modal from rendering if the user role is 'agent' or 'landlord'
    if (userRole === 'agent' || userRole === 'landlord') {
      return false;
    }

    // Display modal for logged-out users or users with role 'user'
    if (!modalTimestamp) return true; // No timestamp means first-time visitor
    return (
      Date.now() - parseInt(modalTimestamp, 10) > MODAL_EXPIRY_TIME &&
      (userIsLoggedOut || userHasRoleUser)
    );
  };

  useEffect(() => {
    // First visit modal delay
    const showFirstVisitModal = setTimeout(() => {
      if (shouldShowModal()) {
        setIsModalOpen(true);
        sessionStorage.setItem('modalTimestamp', Date.now().toString());
      }
    }, FIRST_VISIT_DELAY);

    // Interval to re-check modal display conditions periodically
    const interval = setInterval(() => {
      if (shouldShowModal()) {
        setIsModalOpen(true);
        sessionStorage.setItem('modalTimestamp', Date.now().toString());
      }
    }, CHECK_INTERVAL);

    return () => {
      clearTimeout(showFirstVisitModal);
      clearInterval(interval);
    };
  }, [user]);

  return (
    <div>
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Need Help?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-3'>
            If you couldn't find what you're looking for, we're here to help!
          </p>
          <div className='d-flex flex-column gap-2'>
            <Button
              onClick={() =>
                window.open('https://wa.me/2349118008008', '_blank')
              }
              className='bg-success'
            >
              Chat with Us on WhatsApp
            </Button>
            <Button variant='primary' onClick={() => setRequestFormOpen(true)}>
              Fill Request Form
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className='mb-3'>
            If you close this pop-up, you can access the request form later via
            our Contact or FAQ pages.
          </p>
        </Modal.Footer>
      </Modal>
      <RequestForm
        requestFormOpen={requestFormOpen}
        setRequestFormOpen={setRequestFormOpen}
      />
    </div>
  );
};

export default GetHelp;
