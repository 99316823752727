import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PrimaryButton } from '@shared/buttons';

import { Link, useNavigate } from 'react-router-dom';
export const RedirectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data: any = location.state;

  useEffect(() => {
    if (data === null) {
      navigate('/sign-in', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='container pt-5 '>
      <div className='row'>
        <div className='col-lg-6 mx-auto'>
          <div className='jumbotron text-center'>
            {data === null ? (
              <h1 className='display-3'>Thank You!</h1>
            ) : (
              <h1 className=''>
                Dear,{' '}
                {data.firstname?.charAt(0).toUpperCase() +
                  data.firstname.slice(1)}{' '}
                {data.lastname.charAt(0).toUpperCase() + data.lastname.slice(1)}
              </h1>
            )}
            <p className='lead'>
              {`
             Thank you for signing up on Krent. Please check your email ${
               data !== null ? data.email : ''
             } to verify the email address and
              complete your account setup.`}
            </p>
            <hr />
            <p>
              Having trouble? <a href='/'>Contact us</a>
            </p>
            <Link className='text-link' to='/sign-in'>
              <PrimaryButton
                type='primary'
                text='Continue to sign in '
                width='80%'
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
