// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/clash-display/Fonts/WEB/css/clash-display.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import AppRouter from 'routes';
import 'rsuite/dist/rsuite.min.css';

import {Helmet} from 'react-helmet';
// import {useEffect, useState} from 'react';
import GetHelp from 'components/GetHelp';
import WhatsappIcon from 'components/WhatsApp';
// import {useSessionStorage} from './hooks';

const isGAEnabled = process.env['REACT_APP_ENABLE_GA'] === 'true';
const gaTrackingId = process.env['REACT_APP_GA_TRACKING_ID'];
const gaConfigId = process.env['REACT_APP_GA_CONFIG_ID'];

const GoogleAnalytics = () => {
  if (!isGAEnabled || !gaTrackingId || !gaConfigId) {
    return (
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
    );
  }

  return null;
};

// const RenderHelp = () => {
//   const [user] = useSessionStorage('user', {});
//   const [showHelp, setShowHelp] = useState<boolean>(false);

//   useEffect(() => {
//     if (!user || user.role === 'user') {
//       setShowHelp(true);
//     } else if (user.role === 'agent' || user.role === 'landlord') {
//       setShowHelp(false);
//     }
//   }, [user]);

//   return showHelp ? <GetHelp /> : null;
// };
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppRouter />
    <GoogleAnalytics />
    <GetHelp />
    <WhatsappIcon />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
