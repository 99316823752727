import React from 'react';
import {NewsCard} from '@shared/cards';
import {NewsListResponse} from '@interfaces/news.interface';
// import { Link } from "react-router-dom";

type Props = {
  md?: number;
  lg?: number;
  sm?: number;
  items?: number;
  news: NewsListResponse[];
};

export const NewsCardList: React.FC<Props> = ({
  news,
  sm = 1,
  md = 2,
  lg = 3,
}: Props) => {
  return (
    <React.Fragment>
      <div
        className={`row row-cols-${sm} row-cols-md-${md} row-cols-lg-${lg} g-5 `}
      >
        {news
          .map((v, k) => {
            return <NewsCard {...v} key={k} />;
          })
          .slice(0, 4)}
      </div>
      <div className='text-center my-5 pb-5'>
        <a
          href='https://blog.krent.space/'
          target='_blank'
          rel='noreferrer'
          className='btn btn-custom-primary text-decoration-none rounded-pill fs-14  px-4 fw-bold'
        >
          See More
        </a>
      </div>
    </React.Fragment>
  );
};
