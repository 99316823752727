export const SectionHeading = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  return (
    <div className='d-flex mb-5 flex-column align-items-center justify-content-center section-header'>
      <h3 className=' font-display text-center'>{title}</h3>
      <p className='font-onest text-center col-10'>{text}</p>
    </div>
  );
};
