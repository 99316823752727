import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {PrimaryButton} from '@shared/buttons';
import {differenceInDays} from 'date-fns';
type Props = {
  id: number;
  type: string;
  apartment: string;
  requestBy: string;
  date: string;
  durationStart: string;
  durationEnd: string;
  message: string;
  status: string;
  statusMessage: string;
  timeline: Array<any>;
};

export const RequestViewCard = ({
  req,
  accept,
  summary,
}: {
  req: any;
  accept?: any;
  summary?: boolean;
}) => {
  const [index, setIndex] = useState(0);

  const navigate = useNavigate();

  const request: Props = {
    id: 11,
    date: '12 June 2022',
    type: 'shortlet',
    apartment: 'Michael’s Apartment',
    requestBy: 'John Michael',
    durationStart: 'June 24 2022',
    durationEnd: 'June 27 2022',
    message: 'I’d love to book your apartment for a few days',
    status: 'accepted',
    statusMessage: 'You have successfully approved this booking.',
    timeline: [
      {
        stepStatus: 'done',
        date: 'June 1, 2022',
        amount: 0,
        message: 'John Michael requested for Michael’s Apartment for 3 days',
      },
      {
        stepStatus: 'done',
        date: 'June 2, 2022',
        amount: 0,
        message: 'You accepted John Michael’s request',
      },
      {
        stepStatus: 'pending',
        date: 'June 3, 2022',
        amount: 0,
        message: 'John Michael pays N50,000 for Michael’s Apartment',
      },
      {
        stepStatus: 'pending',
        date: 'June 3, 2022',
        amount: 0,
        message: 'John Michael checks out of Michael’s Apartment',
      },
    ],
  };

  const {timeline} = request;

  return (
    <>
      {index === 0 && (
        <>
          <div className='row p-3'>
            <h5 className='fw-bold mb-4 text-capitalize'>
              {req?.listing?.intent} Request
            </h5>
            {/* <div className='col-12 col-md-2'>
							<img
								src='https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg'
								alt={
									req?.renter?.name ||
									req?.renter?.prefferedName ||
									`${req?.renter?.firstname} ${req?.renter?.astname}`
								}
								width={60}
								height={60}
								className='rounded-circle'
							/>
						</div> */}
            <div className='col-10 mt-3'>
              {summary && (
                <div className='row g-0 gap-0 p-2'>
                  <div className='col-6'>
                    <h6
                      className='fs-12 fw-bold h-modal-color'
                      id='summary-title'
                    >
                      PROPERTY
                    </h6>
                    <p className='fs-14 light-text'>{req?.listing.title}</p>
                  </div>
                  <div className='col-6'>
                    <h6 className='fs-12 fw-bold h-modal-color'>ADDRESS</h6>
                    <p className='fs-14 light-text' id='summary-description'>
                      {req.listing?.location?.formattedAddress ||
                        req.listing?.location2?.formatted_address}
                    </p>
                  </div>
                </div>
              )}
              <div className='row g-0 gap-0 p-2'>
                <div className='col-6'>
                  <h6 className='fs-12 fw-bold h-modal-color'>REQUESTED BY</h6>
                  <p className='fs-14 light-text'>
                    {req?.renter?.name ||
                      req?.renter?.prefferedName ||
                      `${req?.renter?.firstname} ${req?.renter?.lastname}`}
                  </p>
                </div>
                <div className='col-6'>
                  <h6 className='fs-12 fw-bold h-modal-color'>REQUESTED ON</h6>
                  <p className='fs-14 light-text'>
                    {new Date(
                      req?.createdAt?.toLocaleString()
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                </div>
              </div>
              <div className='row g-0 gap-0 p-2'>
                <div className='col-6'>
                  <h6 className='fs-12 fw-bold h-modal-color'>DURATION</h6>
                  <p className='fs-14 light-text w-75'>
                    {new Date(
                      req?.arrival_date?.toLocaleString()
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}{' '}
                    -{' '}
                    {new Date(
                      req?.departure_date?.toLocaleString()
                    ).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}{' '}
                    {' ('}
                    {differenceInDays(
                      new Date(req?.departure_date),
                      new Date(req?.arrival_date)
                    )}{' '}
                    {' nights)'}
                  </p>
                </div>
                <div className='col-6'>
                  <h6 className='fs-12 fw-bold h-modal-color'>
                    {req?.listing?.intent === 'shortlet' ? ' HOST' : 'LANDLORD'}
                  </h6>
                  <p className='fs-14 light-text'>
                    {req?.owner?.preferredName}
                  </p>
                </div>
                {req?.bill[0]?.total ? (
                  <div className='col-6'>
                    <h6 className='fs-12 fw-bold h-modal-color'>TOTAL</h6>
                    <p className='fs-14 light-text'>
                      NGN {req?.bill[0]?.total?.toLocaleString()}
                    </p>
                  </div>
                ) : (
                  <div className='col-6'>
                    <h6 className='fs-12 fw-bold h-modal-color'>TOTAL</h6>
                    <p className='fs-14 light-text'>
                      NGN {req?.bill[0]?.propertyPrice?.toLocaleString()}
                    </p>
                  </div>
                )}
                <div className='col-6'>
                  <h6 className='fs-12 fw-bold h-modal-color'>View Property</h6>
                  <p className='fs-14 light-text'>
                    <Link to={`/property/${req?.listing?.slug}`}>
                      {req?.listing?.title}
                    </Link>
                  </p>
                </div>
              </div>
              {!summary && (
                <div className='row g-0 gap-0 p-2'>
                  <div className='col-12'>
                    {/* <h6 className='fs-12 fw-bold h-modal-color'>MESSAGE</h6> */}
                    <p className='fs-14 light-text m-0'>
                      {/* Hi, I'm interested in your lisiting */}
                    </p>
                    {/* {req?.status !== 'progress' && ( */}
                    <span
                      className='text-primary fw-bold cursor-pointer'
                      onClick={() => navigate(`/message/${req?.id}`)}
                    >
                      View and reply Messages
                    </span>
                    {/* )} */}
                  </div>
                </div>
              )}
            </div>
            {/* {!summary && (
              <div className='col-12 mt-4'>
                {req?.status === 'progress' && (
                  <div className='row'>
                    <div className='col-6'>
                      <PrimaryButton
                        type='primary'
                        text='Accept Booking'
                        click={() => {
                          setIndex(1);
                        }}
                      />
                    </div>
                    <div className='col-6'>
                      <PrimaryButton
                        type='outline'
                        text='Reject Booking'
                        click={() => setIndex(2)}
                      />
                    </div>
                  </div>
                )}
                {req?.status === 'rejected' && (
                  <div
                    className='col-12 border-round py-3 px-4'
                    style={{
                      backgroundColor: '#FCC7C245',
                    }}
                  >
                    <p className='text-danger fs-14 m-0'>
                      You set the property to unavailable
                    </p>
                  </div>
                )}
                {req?.status === 'expired' ||
                  (req?.status === 'accepted' && (
                    <div
                      className='col-12 border-round py-3 px-4'
                      style={{
                        backgroundColor: ' #ECEEFB',
                      }}
                    >
                      <p className='text-black fs-14 m-0'>
                        You set this property to available &nbsp;
                        {req?.status === 'accepted' ? (
                          <a href='#!' onClick={() => setIndex(3)}>
                            View Timeline
                          </a>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  ))}
              </div>
            )} */}
          </div>
        </>
      )}
      {/* This is not doing anything at the moment as all flow goes through messages */}
      {index === 1 && (
        <div className='p-3'>
          <h6 className='fw-bold'>
            Are you sure you want to accept this booking
          </h6>
          <p className='light-text fs-14'>
            Once you accept this booking, the customer will now be able to pay
            for this listing and also message you during their stay on the
            property
          </p>

          <div className='row'>
            <div className='col-6'>
              <PrimaryButton
                type='primary'
                text='Yes, I accept'
                click={() => {
                  // console.log(req?.id);
                  // console.log(req);
                  accept();
                }}
              />
            </div>
            <div className='col-6'>
              <PrimaryButton
                type='outline'
                text='No, not yet'
                click={() => setIndex(0)}
              />
            </div>
          </div>
        </div>
      )}
      {index === 2 && (
        <div className='p-3'>
          <h6 className='fw-bold'>
            Are you sure you want to reject this booking
          </h6>
          <p className='light-text fs-14'>
            Once you reject this booking, the customer will not be able to pay
            for this listing
          </p>

          <div className='row'>
            <div className='col-6'>
              <PrimaryButton
                type='primary'
                text='Reject Booking'
                bgColor='#E22718'
                // click={() => console.log(req?.id)}
              />
            </div>
            <div className='col-6'>
              <PrimaryButton
                type='outline'
                text='No, not yet'
                click={() => setIndex(0)}
              />
            </div>
          </div>
        </div>
      )}
      {index === 3 && timeline && (
        <>
          <div className='col-12 p-3'>
            <p
              className='m-0 btn-el d-flex flex-row gap-3'
              onClick={() => setIndex(0)}
            >
              <i className='fal fa-chevron-circle-left fs-22'></i> Go Back
            </p>
            <h5 className='fw-bold mt-3 mb-4'>Short-let Timeline</h5>
            {timeline.map((item) => {
              return (
                <div className='row g-0 my-2'>
                  <div className='col-1 text-center fs-22 number-circle'>
                    <i
                      className={
                        item.stepStatus === 'done'
                          ? 'fas fa-check-circle text-success'
                          : 'fal fa-circle text-primary'
                      }
                    />
                  </div>
                  <div className='col-11 py-1 px-2'>
                    <p className='m-0 fs-14 fw-bold'>{item.message}</p>
                    <p className='m-0 light-text fs-12'>
                      {item.stepStatus === 'done'
                        ? item.date
                        : 'Waiting for tenant to complete action'}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
