import {Transaction} from '@interfaces/transaction';
import {bankTransferPayment, uploadPaymentProof} from '@services/krent.service';
import {Modal} from '@shared/controls/Modal';
import {InputComponent} from '@shared/forms';
import React, {Fragment, useState} from 'react';
import {toast} from 'react-toastify';

type Data = {
  transaction: Transaction;
};

type BankDetails = {
  accountName: string;
  accountNumber: string;
  bankName: string;
};

export const BankTransfer = ({transaction}: Data): any => {
  const [openBankTransfer, setOpenBankTransfer] = useState<boolean>(false);
  const [bankTransferLoading, setBankTransferLoading] =
    useState<boolean>(false);
  const [uploadLoading, setUploadingLoading] = useState<boolean>(false);
  const [bankDetails, setBankDetails] = useState<BankDetails>({
    accountName: '',
    accountNumber: '',
    bankName: '',
  });
  const [urlPath, setUrlPath] = useState('');
  const [paymentProof, setPaymentProof] = useState<any>('');
  const [remarks, setRemarks] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleBankTransfer = async () => {
    setBankTransferLoading(true);
    try {
      const resp = await bankTransferPayment();
      if (resp.success) {
        setBankDetails(resp.data);
        setBankTransferLoading(false);
        setOpenBankTransfer(true);
      }
    } catch (error: any) {
      toast.error(error.errors[0]?.message);
      setBankTransferLoading(false);
    }
  };

  const handleReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files!;
    const [file] = files;
    setPaymentProof(files);
    setUrlPath(URL.createObjectURL(file));
  };

  const handlePaymentProofSubmit = async () => {
    setUploadingLoading(true);
    const formData = new FormData();

    formData.append('paymentProof', paymentProof[0]);
    formData.append('paymentProofRenterComment', remarks);

    try {
      const resp = await uploadPaymentProof(transaction?.id, formData);
      if (resp.data.success) {
        setSuccessMessage(resp.data.message);
        setUrlPath('');
        setRemarks('');
      }
      setUploadingLoading(false);
    } catch (error: any) {
      setUploadingLoading(false);
      setErrorMessage(error?.response?.data?.errors[0]?.message);
    }
  };

  return (
    <Fragment>
      <span className='text-success fs-10'>
        {transaction.paymentProof[0] &&
          'Your have successfully uploaded your bank transfer payment proof and its pending krents confirmation.'}
      </span>
      <button
        className='btn btn-primary w-100 mt-2'
        onClick={handleBankTransfer}
        // disabled={kyc.status !== 'APPROVED'}
      >
        {bankTransferLoading ? (
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'
          ></span>
        ) : (
          'Bank Transfer'
        )}
      </button>

      {/* Bank Transfer Modal */}
      <Modal
        id='bankTransfer'
        show={openBankTransfer}
        onClose={() => {
          setOpenBankTransfer(false);
          setUrlPath('');
          setRemarks('');
          setSuccessMessage('');
          setErrorMessage('');
        }}
        size={500}
      >
        <div>
          <h5 className=''>Bank Transfer Details</h5>
          <span className='fs-14 text-success'>
            Kindly upload your payment receipt upon successful transaction.
          </span>

          <div className='d-flex gap-1 mt-3'>
            <span className='fw-bold'>Bank Name :</span>
            <p>{bankDetails.bankName}</p>
          </div>
          <div className='d-flex gap-1 mt-2'>
            <span className='fw-bold'> Account Number :</span>
            <p>{bankDetails.accountNumber}</p>
          </div>
          <div className='d-flex gap-1 mt-2'>
            <span className='fw-bold'>Account Name :</span>
            <p>{bankDetails.accountName}</p>
          </div>
          {/* Upload payment receipt */}
          <div className=' col-12 mb-2 mt-3'>
            {/* <label className='form-label text-capitalize'>
              Upload Payment Receipt
            </label> */}
            {successMessage && (
              <p className='text-success fs-14'>{successMessage}</p>
            )}

            <div className='input-group mb-2 mt-2'>
              <input
                type='file'
                className='form-control border-end-0 border-rounded-5 py-2 px-4 rounded-pill-start d-none'
                placeholder='Upload Receipt'
                id='paymentReceipt'
                name='receipt'
                required
                onChange={handleReceiptChange}
              />
              <label
                className=' text-link text-decoration-none border-start-0 pe-4'
                htmlFor='paymentReceipt'
              >
                Click to Upload Payment Receipt
              </label>
            </div>
            {urlPath && (
              <div>
                <iframe
                  src={urlPath}
                  width='100%'
                  height='200px'
                  title='PDF Preview'
                ></iframe>
                <div className=''>
                  <div className='col mb-4'>
                    <InputComponent
                      label='Remarks'
                      placeholder='Enter payment remarks'
                      icon='Edit'
                      type='text'
                      value={remarks}
                      change={(e) => {
                        setRemarks(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className='btn-primary col-12'
                    onClick={handlePaymentProofSubmit}
                  >
                    {uploadLoading ? (
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    ) : (
                      'Upload'
                    )}
                  </button>
                  {errorMessage && (
                    <p className='text-danger fs-14'>{errorMessage}</p>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <div className='d-flex justify-content-center mt-4'>
            <button className='btn-primary'>Upload payment receipt</button>
          </div> */}
        </div>
      </Modal>
    </Fragment>
  );
};
