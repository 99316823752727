import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const PickAdate = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/messaging');
  }, []);
  return <div>PickAdate</div>;
};

export default PickAdate;
