import { format } from 'date-fns';

import { useState } from 'react';

// import { PrimaryButton } from '@shared/buttons';
// import { Modal } from '@shared/controls/Modal';
import { createIBuyProperty } from '@services/krent.service';
// import { useNavigate } from 'react-router';
import ktoast from '@services/toast.service';
// import { ListingFormComponent } from '@shared/forms';

import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
} from './steps';
import { ListingFormComponent } from '@shared/forms';

// const IBuyingStepper = ({
//   children,
//   index,
// }: {
//   children: any[];
//   index: number;
// }) => {
//   useEffect(() => {
//     const forms = document.querySelectorAll('.needs-validation');

//     // Loop over them and prevent submission
//     Array.from(forms).forEach((form: any) => {
//       form.addEventListener(
//         'submit',
//         (event: React.FormEvent<HTMLFormElement>) => {
//           if (!form.checkValidity()) {
//             event.preventDefault();
//             event.stopPropagation();
//           }

//           form.classList.add('was-validated');
//         },
//         false
//       );
//     });

//     // TODO: Add clean up function
//   }, [index]);

//   return children?.filter(Boolean)[index];
// };

export const ListiBuying = () => {
  // const navigate = useNavigate();

  const [index, setIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  // const [showModal, setShowModal] = useState<boolean>(false);

  // const [userType, setUserType] = useState<'landlord' | 'agent' | null>(null);
  // const [propertyType, setPropertyType] = useState<
  // 	'apartment' | 'house' | 'land' | null
  // >(null);
  // const [title, setTitle] = useState<string>();
  // const [isPropertyListed, setIsPropertyListed] = useState<string>();
  // const [listedOn, setListedOn] = useState<string>();
  // const [closeSaleOn, setCloseSaleOn] = useState<string>();

  const [propertyName, setPropertyName] = useState<string>('');
  const [propertyDesc, setPropertyDesc] = useState<string>('');
  const [propertyType, setPropertyType] = useState<string>('');
  const [propertyCategory, setPropertyCategory] = useState<string>('');
  const [spaceType, setSpaceType] = useState<string>('');
  const [safetyFeatures, setSafetyFeatures] = useState<any>([]);
  const [amenities, setAmenities] = useState<any>([]);
  const [bedrooms, setBedrooms] = useState<number>();
  const [bathrooms, setBathrooms] = useState<number>();
  const [floors, setFloors] = useState<number>();
  const [area, setArea] = useState<string>('');
  const [sellReason, setSellReason] = useState<string>('');
  const [propertyCondition, setPropertyCondition] = useState<number>();
  const [commonAreaCondition, setCommonAreaCondition] = useState<number>();
  const [locationCondition, setLocationCondition] = useState<number>();
  const [dateBuilt, setDateBuilt] = useState<string>(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [marketPrice, setMarketPrice] = useState<number>();
  const [idealPrice, setIdealPrice] = useState<number>();
  const [annualFees, setAnnualFees] = useState<number>();
  const [propertyTax, setPropertyTax] = useState<number>();
  const [netSalePrice, setNetSalePrice] = useState<number>();
  const [address, setAddress] = useState<string>();
  const [apartment, setApartment] = useState<string>();
  const [city, setCity] = useState<string>();
  const [state, setState] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [tourUrl, setTourUrl] = useState<string>();
  const [tourIntrest, setTourIntrest] = useState<boolean>();
  const [affiliation, setAffiliation] = useState<string>();
  const [propertyCreated, setPropertyCreated] = useState<boolean>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [propertyId, setPropertyId] = useState('');
  const [flood_meter, setFlood_meter] = useState<string>();
  const [neighbour_story, setNeighbour_story] = useState<boolean>(false);
  const [geo, setGeo] = useState<[]>();

  // const showM = () => {
  // 	const payload = {
  // 		title: propertyName,
  // 		summary: propertyDesc,
  // 		category: propertyCategory,
  // 		kind: propertyType,
  // 		space: spaceType,
  // 		address: `${apartment}, ${address}, ${city}, ${state}, ${country}`,
  // 		price: netSalePrice,
  // 		currency: 'NGN',
  // 		description: propertyDesc,
  // 		intent: 'iBuying',
  // 		bedrooms: bedrooms,
  // 		bathrooms: bathrooms,
  // 		floor: floors,
  // 		area: area,
  // 		virtual_tour_url: tourUrl,
  // 		video_url: videoUrl,
  // 		iBuyData: {
  // 			floors: floors,
  // 			selling_reason: sellReason,
  // 			rate_property_condition: propertyCondition,
  // 			rate_commonArea_condition: commonAreaCondition,
  // 			rate_location_condition: locationCondition,
  // 			date_built: dateBuilt,
  // 			market_price: marketPrice,
  // 			land_annualFees: annualFees,
  // 			property_tax: propertyTax,
  // 			net_sale_amount: netSalePrice,
  // 			ideal_price: idealPrice,
  // 		},
  // 	};

  // 	console.log(payload);

  // 	setLoading(true);

  // 	createIBuyProperty(payload)
  // 		.then(() => {
  // 			navigate(
  // 				{
  // 					pathname: '/listings/',
  // 				},
  // 				{
  // 					state: {
  // 						showToast: true,
  // 						toastMessage: 'Listing published successfully',
  // 					},
  // 					replace: true,
  // 				}
  // 			);
  // 		})
  // 		.catch((err) => {
  // 			console.log(err);
  // 			setShowModal(false);
  // 			ktoast.error(err.errors[0].message);
  // 		})
  // 		.finally(() => {
  // 			setLoading(false);
  // 		});
  // };

  const handleCreate = () => {
    setLoading(true);
    const payloads = {
      title: propertyName,
      summary: propertyDesc,
      category: propertyCategory,
      kind: propertyType,
      space: spaceType,
      address,
      location2: {
        apartment,
        city,
        state,
        country,
        geo,
        formatted_address: address,
      },
      price: netSalePrice,
      currency: 'NGN',
      description: propertyDesc,
      intent: 'iBuying',
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      floor: floors,
      area: area,
      virtual_tour_url: tourUrl,
      video_url: videoUrl,
      iBuyData: {
        floors: floors,
        selling_reason: sellReason,
        rate_property_condition: propertyCondition,
        rate_commonArea_condition: commonAreaCondition,
        rate_location_condition: locationCondition,
        date_built: dateBuilt,
        market_price: marketPrice,
        land_annualFees: annualFees,
        property_tax: propertyTax,
        net_sale_amount: netSalePrice,
        ideal_price: idealPrice,
        affiliation,
        amenities,
        neighbour_story,
        flood_meter,
      },
    };
    createIBuyProperty(payloads)
      .then((res: any) => {
        setPropertyId(res?.data.id);
        setPropertyCreated(true);
        setShowSuccessModal(true);
        // onNext();
      })
      .catch((err) => {
        console.log(err);

        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPrev = () => {
    setIndex(index - 1);
  };

  const onNext = () => {
    setIndex(index + 1);
  };

  return (
    <div style={{ maxHeight: '70vh', overflow: 'scroll' }}>
      <ListingFormComponent index={index}>
        <Step1
          propertyName={propertyName}
          setPropertyName={setPropertyName}
          propertyDesc={propertyDesc}
          setPropertyDesc={setPropertyDesc}
          onNext={onNext}
        />
        <Step2
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          propertyCategory={propertyCategory}
          setPropertyCategory={setPropertyCategory}
          affiliation={affiliation}
          setAffiliation={setAffiliation}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step3
          spaceType={spaceType}
          setSpaceType={setSpaceType}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step4
          bedrooms={bedrooms}
          setBedrooms={setBedrooms}
          bathrooms={bathrooms}
          setBathrooms={setBathrooms}
          floors={floors}
          setFloors={setFloors}
          area={area}
          setArea={setArea}
          sellReason={sellReason}
          setSellReason={setSellReason}
          propertyCondition={propertyCondition}
          setPropertyCondition={setPropertyCondition}
          commonAreaCondition={commonAreaCondition}
          setCommonAreaCondition={setCommonAreaCondition}
          locationCondition={locationCondition}
          setLocationCondition={setLocationCondition}
          dateBuilt={dateBuilt}
          setDateBuilt={setDateBuilt}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step5
          marketPrice={marketPrice}
          setMarketPrice={setMarketPrice}
          idealPrice={idealPrice}
          setIdealPrice={setIdealPrice}
          annualFees={annualFees}
          setAnnualFees={setAnnualFees}
          propertyTax={propertyTax}
          setPropertyTax={setPropertyTax}
          netSalePrice={netSalePrice}
          setNetSalePrice={setNetSalePrice}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step6
          safetyFeatures={safetyFeatures}
          setSafetyFeatures={setSafetyFeatures}
          amenities={amenities}
          setAmenities={setAmenities}
          onPrev={onPrev}
          onNext={onNext}
        />
        <Step7
          address={address}
          setAddress={setAddress}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          apartment={apartment}
          setApartment={setApartment}
          onPrev={onPrev}
          onNext={onNext}
          handleCreate={handleCreate}
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
          setNeighbour_story={setNeighbour_story}
          setFlood_meter={setFlood_meter}
          flood_meter={flood_meter}
          setGeo={setGeo}
        ></Step7>
        <Step8
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
          tourUrl={tourUrl}
          setTourUrl={setTourUrl}
          tourIntrest={tourIntrest}
          setTourIntrest={setTourIntrest}
          setLoading={setLoading}
          loading={loading}
          onPrev={onPrev}
          propertyId={propertyId}
          propertyCreated={propertyCreated}
        ></Step8>

        {/* {propertyType === 'house' ? (
					<HouseStep1 onPrev={onPrev} onNext={onNext} />
				) : undefined}
				{propertyType === 'house' ? (
					<HouseStep2 onPrev={onPrev} onNext={() => setShowModal(true)} />
				) : undefined}

				{propertyType === 'apartment' ? (
					<ApartmentStep1 onPrev={onPrev} onNext={onNext} />
				) : undefined}
				{propertyType === 'apartment' ? (
					<ApartmentStep2 onPrev={onPrev} onNext={() => setShowModal(true)} />
				) : undefined}

				{propertyType === 'land' ? (
					<LandStep1 onPrev={onPrev} onNext={() => setShowModal(true)} />
				) : undefined} */}
      </ListingFormComponent>

      {/* {showModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className='col px-4'>
            <h6 className='fw-bold fs-20'>
              Are you ready to publish this property?
            </h6>
            <p className='light-text'>
              Once you publish this property, Krent will review your listing and
              your listing would go live once it is approved.
            </p>

            <div className='row'>
              <div className='col-6'>
                <PrimaryButton
                  text='Publish'
                  type='primary'
                  loading={loading}
                  click={showM}
                />
              </div>
              <div className='col-6'>
                <PrimaryButton
                  text='Do this later'
                  type='outline'
                  click={() => setShowModal(false)}
                />
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
};
