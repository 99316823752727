export const addToNewsletter = (email: string): Promise<any> => {
    
    const formData: FormData = new FormData();

    formData.append('client_id', process.env['REACT_APP_ZENTER_CLIENT_ID'] || '');
    formData.append('password', process.env['REACT_APP_ZENTER_PASSWORD'] || '');
    // formData.append('redirect', process.env['REACT_APP_ZENTER_REDIRECT_URL'] || '');
    formData.append('audiences[]', process.env['REACT_APP_ZENTER_AUDIENCE'] || '');
    formData.append('vars[7][]', process.env['REACT_APP_ZENTER_VARS'] || '');
    formData.append('list[]', process.env['REACT_APP_ZENTER_LIST'] || '');
    formData.append('email', email);
    
    return new Promise((resolve, reject) => {
        return fetch(
            `https://app.zenter.ng/import`, {
            // signal: signal,
            method: 'POST',
            body: formData,
        }).then((res) => {
            console.log(res);
            return res.text();
        }).then((response: any) => {
            if (response.toString() === '1') {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch((err) => {
            reject(err);
        });
    });
};