import React, { useEffect, useRef } from 'react';

declare global {
  interface Window {
    bootstrap: any;
  }
}

type ModalProps = {
  id?: string;
  show: boolean;
  onClose: Function;
  size?: 'sm' | 'md' | 'lg' | number;
  position?: 'top' | 'center';
  children?: JSX.Element | JSX.Element[];
  dismissible?: boolean;
};

export const Modal = ({
  children,
  show,
  onClose,
  id,
  size = 'md',
  position = 'center',
  dismissible: dismissable = true,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalSize = size === 'md' ? '' : `modal-${size}`;

  useEffect(() => {
    const myModal = new window.bootstrap.Modal(
      document.getElementById(id ?? 'RootModal'),
      {
        keyboard: false,
        backdrop: dismissable || 'static',
        focus: false,
        // show: true,
      }
    );

    if (!show) {
      document
        .getElementById(id ?? 'RootModal')
        ?.removeEventListener('hidden.bs.modal', () => onClose(), false);
      myModal.hide();
      // (
      //   document.getElementById(id ?? 'RootModal') as HTMLDivElement
      // ).style.display = 'hidden';
      document.body.classList.remove('modal-open');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('padding-right');
      document.body.style.removeProperty('z-index');
      // document.body.style.overflow = 'unset';
      // document.body.style.paddingRight = 'unset'
      // document.body.style.zIndex = '1';
      // const mo = document.getElementById(id ?? 'RootModal') as HTMLDivElement;
      // mo.style.display = 'hidden';
    }

    if (show) {
      // document.body.style.zIndex = '-1';
      // document.getElementById(id ?? 'RootModal')
      // ?.addEventListener('hide.bs.modal', () => (document.getElementById(id ?? 'RootModal') as HTMLDivElement).style.display = 'hidden', true);

      document
        .getElementById(id ?? 'RootModal')
        ?.addEventListener('hidden.bs.modal', () => onClose(), true);
      myModal.show();
    }

    return () => {
      document
        .getElementById(id ?? 'RootModal')
        ?.removeEventListener('hidden.bs.modal', () => onClose(), false);
      myModal.dispose();
      // myModal.hide();
      // onClose();
    };
  }, [show, onClose, id, dismissable]);

  return (
    <React.Fragment>
      <div
        className={`modal  ${typeof size === 'string' ? modalSize : ''}`}
        id={id ?? 'RootModal'}
        tabIndex={-1}
        ref={modalRef}
        // data-bs-backdrop={dismissable ? 'dismiss' : 'static'}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div
          className={`modal-dialog ${
            position === 'center' ? 'modal-dialog-centered' : ''
          }`}
          style={typeof size === 'number' ? { maxWidth: size } : undefined}
        >
          <div className='modal-content'>
            <div className='modal-header border-0'>
              {dismissable && (
                <button
                  type='button'
                  className='btn-close fs-12'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              )}
            </div>
            <div className='modal-body'>{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
