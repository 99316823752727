import {Link} from 'react-router-dom';

const KycAlert = ({status}: {status: string}) => {
  return (
    <div className='row'>
      <div className='col-12'>
        <div
          role='alert'
          className={`alert ${
            status.includes('pending') ? 'alert-warning' : 'alert-danger'
          } alert-dismissible fade show text-black  d-flex justify-content-between align-items-center`}
        >
          {/* <i className={`iconly-alert  icli fs-4`}></i> */}
          {status || 'Please Complete your KYC.'}

          {!status.includes('pending') && (
            <Link to='/settings' className='btn btn-success'>
              Add KYC
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
export default KycAlert;
