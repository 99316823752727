import React, {Fragment} from 'react';
import {PrimaryButton} from '@shared/buttons';
import {Link} from 'react-router-dom';

type Props = {
  setOpenTerms: (param: boolean) => void;
  setOpenPolicy: (param: boolean) => void;
  agree: boolean;
  setAgree: (param: boolean) => void;
  acceptLoading: boolean;
  acceptBooking: () => void;
  kyc: any;
  setOpenCancellation: (param: boolean) => void;
};

export const BookShorlet = ({
  setOpenTerms,
  setOpenPolicy,
  agree,
  setAgree,
  acceptLoading,
  acceptBooking,
  kyc,
  setOpenCancellation,
}: Props): any => {
  return (
    <Fragment>
      <div className='col mb-1 '>
        <span
          className='fs-12 text-link mr-3'
          onClick={() => setOpenTerms(true)}
        >
          Terms and Conditions
        </span>
        <span className='fs-12 text-link' onClick={() => setOpenPolicy(true)}>
          Cancellation Policy
        </span>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            name='terms'
            checked={agree}
            id='terms'
            onChange={() => setAgree(!agree)}
          />
          <label className='form-check-label fs-10 fw-400' htmlFor='terms'>
            By booking you agree to the terms and condition of your host.
          </label>
        </div>
      </div>
      <div className='d-grid gap-2'>
        <div>
          <PrimaryButton
            loading={acceptLoading}
            click={acceptBooking}
            type='primary'
            text='Book Now'
            disable={kyc.status !== 'APPROVED' || !agree}
          />
          <div className='fs-10 text-danger text-center  '>
            {kyc.status === 'PENDING' ? (
              <span>
                Please reach out to Krent support on{' '}
                <span className='text-primary'>+234 911 800 8008 </span> for an
                update on your KYC pending approval to enable you proceed with
                your booking.
              </span>
            ) : kyc.status === 'APPROVED' ? (
              ''
            ) : (
              <span>
                {' '}
                Please complete your kyc <Link to='/settings'>here</Link> to
                proceed
              </span>
            )}
          </div>
        </div>
        <PrimaryButton
          type='outline'
          text='Not Interested'
          click={setOpenCancellation}
        />
      </div>
    </Fragment>
  );
};
