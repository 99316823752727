import React from 'react';

type Props = {
  text: string;
  icon?: string;
  width?: string;
  color?: string;
  bgColor?: string;
  close?: boolean;
  type: 'primary' | 'outline';
  loading?: boolean;
  disable?: boolean;
  click?: (e?: any) => void;
};

export const PrimaryButton: React.FC<Props> = ({
  text,
  icon,
  width = '100%',
  color,
  bgColor,
  type,
  click,
  close,
  loading,
  disable,
}: Props) => {
  return (
    <button
      className={`btn btn-${type} m-1`}
      style={{
        width: width,
        borderColor: color,
        color: color,
        backgroundColor: disable ? 'grey' : bgColor,
      }}
      onClick={click}
      disabled={loading || disable}
      data-bs-dismiss={close ? 'modal' : ''}
      aria-label={close ? 'Close' : ''}
    >
      {icon && (
        <span className='mr-1'>
          <i className={`fas fa-${icon}`} />
        </span>
      )}
      {loading ? (
        <span
          className='spinner-border spinner-border-sm'
          role='status'
          aria-hidden='true'
        ></span>
      ) : (
        text
      )}
    </button>
  );
};
