import {getPropertyKinds, requestForm} from '@services/krent.service';
import {PrimaryButton} from '@shared/buttons';
import {InputComponent} from '@shared/forms';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';

const RequestForm = ({
  requestFormOpen,
  setRequestFormOpen,
}: {
  requestFormOpen: boolean;
  setRequestFormOpen: (arg: boolean) => void;
}) => {
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [category, setCategory] = useState<any>({});
  const [categories, setCategories] = useState<any>([]);
  const [success, setSuccess] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      fullName: name,
      category,
      phone: phoneNumber,
      description: message,
      email,
      location,
    };

    requestForm(data)
      .then((res) => {
        console.log(res);
        if (res.success) {
          setSuccess('Your request as been submitted successfully');
        }
        setLoading(false);
      })
      .catch((err) => {
        setSuccess(
          'Sorry your request couldnt be submitted at the moment,Please try again.'
        );

        console.log(err);
        setLoading(false);
      });
  };

  const closeModal = () => {
    setRequestFormOpen(false);
    setSuccess('');
  };

  useEffect(() => {
    const abortController = new AbortController();

    getPropertyKinds(abortController.signal)
      .then((res: any) => {
        setCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Modal show={requestFormOpen} onHide={closeModal} centered size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Property Request Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-3'>
          If you are unable to find the property you need in our existing
          listings, please use this form to specify the type of property you are
          looking for. Provide detailed information about your requirements, and
          our team will review and process your request accordingly.{' '}
        </p>
        <div className='   '>
          <form id='requestForm' onSubmit={handleSubmit}>
            <div className='controls'>
              <div className='row col-12'>
                <div className='col-12 col-lg-6 mb-3'>
                  <InputComponent
                    label='Full Name'
                    placeholder='Enter your full name'
                    icon='user'
                    iconType='fa'
                    type='text'
                    change={(e) => setName(e.target.value)}
                  />
                </div>
                <div className='col-12 col-lg-6 mb-3'>
                  <InputComponent
                    label='Email Address'
                    placeholder='Enter your email address'
                    icon='Message'
                    type='email'
                    change={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className='row col-12'>
                <div className='col-12 col-lg-6 mb-3'>
                  <InputComponent
                    label='Phone Number'
                    placeholder='Enter your mobile number'
                    icon='phone'
                    iconType='fa'
                    type='number'
                    change={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
                <div className='col-12 col-lg-6 mb-3'>
                  {/* <InputComponent
                    label=' Property Category'
                    placeholder='e.g.-shortlet'
                    icon='home'
                    iconType='fa'
                    type='text'
                    change={(e) => setCategory(e.target.value)}
                    required
                  /> */}
                  <div className={`form-group m-1`}>
                    <label
                      className={`text-uppercase fw-600 fs-12 m-1`}
                      htmlFor='category'
                    >
                      Property Category
                    </label>
                    <div className='input-group'>
                      <span
                        className='input-group-text input-group-text-0 '
                        id='basic-addon1'
                      >
                        <i className={`fa-light fa-home fs-5`}></i>
                      </span>
                      <select
                        required
                        className='form-control form-control-0 ps-3 py-3  border-start-0'
                        value={category?.id}
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                      >
                        <option value=''>Select an option</option>
                        {categories.map((d: any) => {
                          return (
                            <option key={d.id} value={d.id}>
                              {d.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row col-12'>
                <div className='col-12 col-lg-6 mb-3'>
                  <InputComponent
                    label='Location'
                    placeholder='Enter your location'
                    icon='map'
                    iconType='fa'
                    type='text'
                    change={(e) => setLocation(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className=' form-group  mb-3 ml-1 mr-3'>
                <label
                  className={`text-uppercase fw-600 fs-12 m-1`}
                  htmlFor='form_message'
                >
                  Message
                </label>
                <textarea
                  id='form_message'
                  name='message'
                  placeholder='e.g. A two bedroom apartment with 3 toilets in ikeja'
                  rows={4}
                  required
                  className='form-control form-control-0 ps-3 py-3   '
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              {success && <p className='text-success fs-12 mx-4'>{success}</p>}
              <div className='col text-center'>
                <PrimaryButton type='primary' text='Send' loading={loading} />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant='secondary' onClick={closeModal}>
          Close
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};
export default RequestForm;
