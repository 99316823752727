import rental from '../../assets/images/rental.png';
import shortlet from '../../assets/images/shortlet.png';
import sales from '../../assets/images/sales.png';
import iBuying from '../../assets/images/iBuying.png';

type Props = {
  children?: JSX.Element | JSX.Element[];
  type: string;
};

export const ListingLayout: React.FC<Props> = ({ children, type }: Props) => {
  const bgStyle = {
    backgroundImage: `url(${
      type === 'rental'
        ? rental
        : type === 'sales'
        ? sales
        : type === 'shortlet'
        ? shortlet
        : type === 'iBuying'
        ? iBuying
        : rental
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
    };

  return (
    <div className='container-fluid bg-white'>
      <div style={{position:'static',minHeight:'100vh'}} className='row'>
        <div
          className='col-12 col-lg-5  d-none d-lg-block'
          style={bgStyle}
        ></div>
        <div className='col-12 col-lg-7  justify-content-center'>
          <div className='py-3 text-center bg-opacity-10 notification-bar'>
            <p className='mb-0 fs-14 fw-600'></p>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};