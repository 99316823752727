import {DashboardLayout, BaseLayout, SingleProperty} from '@layouts/index';

import {PropertyCard} from '@shared/cards';

import {TestimonialCardList} from '@lists/TestimonialCardList';

import {testimonials} from '../Landing2/data';
// import { properties, testimonials } from './data';
import {useLocation} from 'react-router';
import {
  PropertyListResponse,
  PropertySummaryResponse,
} from '@interfaces/properties.interface';
import {useSessionStorage} from '../../hooks';
import {useEffect, useState} from 'react';
import {getProperties, getSingleProperty} from '@services/krent.service';
import {BaseApiResponse, PaginatedResponse} from '@interfaces/app.interface';
import {SectionHeading} from 'components/SectionHeading';

// type LocationProps = {
//   property: PropertyListResponse;
// };

export const Property = () => {
  const location: any = useLocation();
  const [user] = useSessionStorage('user', {});
  const [allProperties, setAllProperties] = useState<PropertyListResponse[]>();
  const [singleProperty, setSingleProperty] = useState<PropertyListResponse>();
  // const {property}: LocationProps = location?.state as LocationProps;

  useEffect(() => {
    let abortController = new AbortController();
    getProperties({}, abortController.signal)
      .then(
        (
          res: BaseApiResponse<
            PropertyListResponse[],
            PaginatedResponse,
            PropertySummaryResponse
          >
        ) => {
          setAllProperties(res.data);
        }
      )
      .catch(() => {})
      .finally(() => {
        // setLoading(false);
      });
    return () => {
      return abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    let fetchID;
    if (location?.state?.id) {
      fetchID = location.state?.id;
    } else {
      const pathname = location.pathname;
      fetchID = pathname.match(/\/property\/([^/]+)/)[1];
    }

    getSingleProperty(fetchID, abortController.signal)
      .then((res: any) => {
        setSingleProperty(res?.data);
      })
      .catch((err) => {
        console.log(err.errors[0].message);
      });
  }, [location?.state?.id]);

  return user && user?.id ? (
    <DashboardLayout>
      <SingleProperty prop={location?.state?.property} />
    </DashboardLayout>
  ) : (
    <BaseLayout>
      <SingleProperty prop={location?.state?.property || singleProperty} />

      <section className='p-5 bg-purple'>
        <h6 className='fw-bold'>Similar Listings</h6>

        <div className='row'>
          {allProperties
            ?.filter(
              (prop) =>
                prop.intent === location?.state?.property?.intent &&
                prop.id !== location?.state?.property?.id
            )
            ?.map((v, k) => (
              <div className='col-12 col-md-4 mb-4 mb-md-0 ' key={k}>
                <PropertyCard {...v} />
              </div>
            ))
            .slice(0, 3)}
        </div>
      </section>

      <section className='py-5'>
        <div className='container'>
          <SectionHeading
            title='Testimonials'
            text='Take a look at what some our clients has to say'
          />

          <TestimonialCardList testimonials={testimonials} />
        </div>
      </section>

      <section className='py-5'>
        <div className='container text-center'>
          <h4 className='fw-bolder'>
            You're one step away from an amazing experience
          </h4>
          <div className='d-grid gap-3 d-md-block my-4'>
            <a
              href='!#'
              className='btn btn-custom-primary py-3 px-4 rounded-pill me-2 fs-14 fw-600'
            >
              List A Property
            </a>
            <a
              href='!#'
              className='btn btn-white border-dark py-3 px-3 rounded-pill fs-14 fw-600'
            >
              Browse Properties
            </a>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
};
