import React from 'react';
import {PropertyCard} from '@shared/cards';
import {PropertyListResponse} from '@interfaces/properties.interface';
import {Link} from 'react-router-dom';
import {PropertCardHorizontal} from '@shared/cards/PropertyCardHorizontal';

type Props = {
  md?: number;
  lg?: number;
  sm?: number;
  items?: number;
  loading?: boolean;
  transactions?: any;
  orientation?: 'horizontal' | 'vertical';
  properties: PropertyListResponse[];
  showBtn?: boolean;
};

export const PropertyCardList: React.FC<Props> = ({
  loading,
  properties,
  items,
  sm = 1,
  md = 2,
  lg = 3,
  orientation = 'vertical',
  showBtn = false,
}: Props) => {
  return (
    <React.Fragment>
      <div
        className={`row row-cols-${sm} row-cols-md-${md} row-cols-lg-${lg} g-5 mb-5`}
      >
        {loading
          ? [...new Array(10).fill(0)].map((_, j) => (
              <div className='col' key={j}>
                <div
                  className='card  border-light shadow-sm h-100'
                  aria-hidden='true'
                >
                  <svg
                    className='bd-placeholder-img card-img-top'
                    width='100%'
                    height='180'
                    xmlns='http://www.w3.org/2000/svg'
                    role='img'
                    aria-label='Placeholder'
                    preserveAspectRatio='xMidYMid slice'
                    focusable='false'
                  >
                    <title>Placeholder</title>
                    <rect width='100%' height='100%' fill='#868e96'></rect>
                  </svg>
                  <div className='card-body'>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6'></span>
                    </h5>
                    <p className='card-text placeholder-glow'>
                      <span className='placeholder col-7'></span>
                      <span className='placeholder col-4'></span>
                      <span className='placeholder col-4'></span>
                      <span className='placeholder col-6'></span>
                      <span className='placeholder col-8'></span>
                    </p>
                    <span
                      tabIndex={-1}
                      className='btn btn-primary bg-primary disabled placeholder col-6'
                    ></span>
                  </div>
                </div>
              </div>
            ))
          : orientation === 'vertical'
          ? properties?.slice(0, items).map((v, k) => {
              return <PropertyCard {...v} key={k} />;
            })
          : properties?.slice(0, items).map((v, k) => {
              return <PropertCardHorizontal {...v} key={k} />;
            })}
      </div>
      {showBtn && (
        <div className='text-center mb-5'>
          <Link
            className='btn btn-custom-primary text-decoration-none rounded-pill fs-14 py-3 px-4 fw-bold'
            to='/explore'
          >
            See All Listings
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};
