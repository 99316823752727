import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env['REACT_APP_API_FIREBASE'],
  authDomain: process.env['REACT_APP_API_FIREBASE_AUTH_DOM'],
  projectId: process.env['REACT_APP_API_FIREBASE_PROJ_ID'],
  storageBucket: process.env['REACT_APP_API_FIREBASE_STOREBUCKET'],
  messagingSenderId: process.env['REACT_APP_API_FIREBASE_MSG_SENDER_ID'],
  appId: process.env['REACT_APP_API_FIREBASE_APP_ID'],
  measurementId: process.env['REACT_APP_API_FIREBASE_MS_ID']
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

export const firestore = getFirestore(app);

export const sendChat = (payload: any) => {
  console.log(payload);
  // try {
  // 	addDoc(collectionGroup(firestore, `messages/${id}`), {
  // 		from_uid: user?.id,
  // 		to_uid: owner,
  // 		type: 'text',
  // 		text: "I'm interested in your property",
  // 		meta: {
  // 			property: {
  // 				id,
  // 				request_type: '2022-09-25',
  // 				arrival_date: '2022-09-15'
  // 			},
  // 			sent_by: {
  // 				name: user?.name,
  // 				photoUrl: user?.photo
  // 			}
  // 		},
  // 		createdAt: serverTimestamp()
  // 	}).then((docRef) => {
  // 		console.log(docRef)
  // 		ktoast.success('Your booking has been logged successfully. The Property owner will be notified of your interest.')
  // 	});
  // } catch (e) {
  // 	console.error("Error adding document: ", e);
  // }
};
