// import propertyIcon from '../assets/icons/property.svg';
// import documentationIcon from '../assets/icons/documentation.svg';
// import paymentIcon from '../assets/icons/payment.svg';
// import locationIcon from '../assets/icons/location.svg';
import {serviceData} from '@pages/Landing2/data';
import {Link} from 'react-router-dom';

const Services = () => {
  return (
    <div
      className='bg-white d-flex flex-column justify-content-center gap-5 rounded-3 px-5 py-5'
      style={{maxWidth: '609px'}}
    >
      <h4 className='text-center font-display fs-24 fw-600'>
        How <span className='text-primary'>krent</span> works
      </h4>
      <div className='d-flex flex-column text-center text-md-start align-items-center  justify-content-center gap-5 px-3 '>
        {serviceData.map((data: any, i: number) => {
          return (
            <div
              className='d-flex flex-column flex-md-row align-items-center justify-content-center text-center text-md-start'
              style={{maxWidth: '90%', gap: '2rem'}}
              key={i}
            >
              <div>
                <img src={data?.image} alt='propertyIcon' className=' ' />
              </div>
              <div>
                <p className='fw-bolder mb-1'>{data?.title}</p>
                <small
                  className='text-secondary d-block text-center text-md-start fs-12'
                  style={{maxWidth: '381px'}}
                >
                  {data?.text}
                </small>
              </div>
            </div>
          );
        })}

        {/* <button className='btn btn-primary mb-5'>Get Started</button> */}
        <Link
          className='btn btn-custom-primary text-decoration-none rounded-pill mb-5'
          to='/get-started/'
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};
export default Services;
