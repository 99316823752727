// import {addToNewsletter} from '@services/zenter.service';
// import {subscribeToGetResponse} from '@services/getResponse.service';
import Logo from '@shared/Logo';
// import {useState} from 'react';
import {Link} from 'react-router-dom';
// import ktoast from "@services/toast.service";

interface Prop {
  setPage: (page: any) => void;
}

export const BaseFooter = ({setPage}: Prop) => {
  const currentYear = new Date().getFullYear();

  // let params: URLSearchParams = new URLSearchParams();
  // const int = e.target.value;
  // if (int) params.append('intent', int);

  // const [loading, setLoading] = useState<boolean>(false);
  // const subscribeToNewsletter = (
  //   e: any /*e: React.FormEvent<HTMLFormElement>*/
  // ) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   addToNewsletter(`${e.target.email.value}`)
  //     .then(() => {
  //       // ktoast.success(`We've added ${e.target.email.value} to our mailing list. We promise we don't spam 😁`)
  //     })
  //     .catch((er) => {
  //       console.log(er);
  //       // ktoast.error(`Sorry an error occured while adding your email. Please refresh the page and try again 😔`)
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };
  // const subscribeToNewsletter = (e: any) => {
  //   e.preventDefault();
  //   subscribeToGetResponse(e.target.email.value);
  // };

  return (
    <footer className='bg-black text-white pt-5 px-4 px-md-0'>
      <div className='container mt-5'>
        <div className='row mb-5 d-flex justify-content-around'>
          <div className='col-md-4 col-lg-3'>
            <div className='col-12 mb-5 mb-md-0'>
              <h4 className='fw-bolder mb-3'>
                <Logo png />
              </h4>
              <div className='d-flex w-md-75 mb-4'>
                <a
                  className='me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 no-decoration-hover no-decoration'
                  href='https://www.linkedin.com/company/krent-inc/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa-brands fa-linkedin fs-22 text-dark'></i>
                </a>
                <a
                  className='me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 no-decoration-hover no-decoration'
                  href='https://instagram.com/krent_inc?igshid=MzRlODBiNWFlZA=='
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa-brands fa-instagram fs-22 text-dark'></i>
                </a>
                <a
                  className='me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 no-decoration-hover no-decoration'
                  href='https://www.facebook.com/KrentInc?mibextid=9R9pXO no-decoration-hover'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa-brands fa-facebook fs-22 text-dark'></i>
                </a>
                <a
                  className='me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 no-decoration-hover  no-decoration'
                  href='https://x.com/krent_inc?t=iDh8266ktZdEv_bipAo1xg&s=09'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa-brands fa-twitter fs-22 text-dark'></i>
                </a>
              </div>
              <p>Follow us on social media, never miss a thing!</p>
            </div>
          </div>
          {/* last list */}

          <div className='col-md-7 col-12 mb-5 mb-md-0'>
            <h4 className='mb-3'>Quick Links</h4>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-6 mb-5 mb-md-0'>
                <ul className='list-unstyled'>
                  {/* <li className='mb-3'>
                    <p className='text-white fw-bolder'>Links</p>
                  </li> */}

                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/house'
                    >
                      House for sale
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/land'
                    >
                      Land for sale
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/apartment'
                    >
                      Flats & Apartments for Sale
                    </Link>
                  </li>

                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/house/in/abuja'
                    >
                      Houses for sale in Abuja
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/house/in/lagos'
                    >
                      Houses for sale in Lagos
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/house/in/lekki'
                    >
                      Houses for sale in Lekki
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/house/in/ikoyi'
                    >
                      Houses for sale in Ikoyi
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/land/in/lagos'
                    >
                      Land for sale in Lagos
                    </Link>
                  </li>

                  {/* <li className='mt-3'>
                    <Link
                      to={{
                        pathname: '/explore/',
                        search: `?${createSearchParams({
                          ...params,
                          intent: 'shortlet',
                        })}`,
                      }}
                      className='text-secondary '
                    >
                      Book a shortlet
                    </Link>
                  </li> */}

                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-sale/commercial'
                    >
                      Commercial Property for Sale
                    </Link>
                  </li>
                </ul>
              </div>

              <div className='col-lg-3 col-md-4 col-6 mb-5 mb-md-0'>
                <ul className='list-unstyled'>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/house'
                    >
                      House for rent
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/apartment'
                    >
                      Flats & Apartments for Rent
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/apartment/in/abuja'
                    >
                      Flats for Rent in Abuja
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/apartment/in/lagos'
                    >
                      Flats for Rent in Lagos
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/house/in/abuja'
                    >
                      Houses for Rent in Abuja
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/house/in/lagos'
                    >
                      Houses for Rent in Lagos
                    </Link>
                  </li>

                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-rent/land'
                    >
                      Lands lease
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-2 col-md-4 col-6 mb-5 mb-md-0'>
                <ul className='list-unstyled'>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-shortlet/in/lekki'
                    >
                      Short Let in Lekki
                    </Link>
                  </li>
                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-shortlet/in/abuja'
                    >
                      Short Let in Abuja
                    </Link>
                  </li>

                  <li className='mb-3' onClick={() => setPage(1)}>
                    <Link
                      className='text-secondary'
                      aria-current='page'
                      to='/property-for-shortlet/in/lagos'
                    >
                      Short Let in Lagos
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className='col-lg-2 col-md-4 col-6 mb-5 mb-md-0'>
                <ul className='list-unstyled'>
                 
                  <li className='mb-3'>
                    <Link to={'/'} className='text-secondary '>
                      Home
                    </Link>
                  </li>
                  <li className='mb-3'>
                    <Link to={'/about'} className='text-secondary '>
                      About
                    </Link>
                  </li>
                  <li className='mb-3'>
                    <Link to={'/explore/'} className='text-secondary '>
                      Properties
                    </Link>
                  </li>
                  <li className='mb-3'>
                    <Link to={'/contact/'} className='text-secondary '>
                      Contact
                    </Link>
                  </li>
                  <li className='mb-3'>
                    <Link to={'/get-started/'} className='text-secondary'>
                      Become an agent
                    </Link>
                  </li>
                </ul>
              </div> */}
              <div className='col-lg-2 col-md-4 col-6 mb-5 mb-md-0'>
                <ul className='list-unstyled'>
                  <li className='mb-3'>
                    <Link to={'/about'} className='text-secondary '>
                      About
                    </Link>
                  </li>
                  <li className='mt-3'>
                    <Link to={'/privacy'} className='text-secondary '>
                      Privacy
                    </Link>
                  </li>
                  <li className='mt-3'>
                    <Link to={'/terms'} className='text-secondary '>
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li className='mt-3'>
                    <Link to={'/contact'} className='text-secondary '>
                      Contact
                    </Link>
                  </li>
                  <li className='mt-3'>
                    <Link to={'/get-started'} className='text-secondary'>
                      Become an agent
                    </Link>
                  </li>
                  <li className='mt-3'>
                    <Link to={'/profile'} className='text-secondary '>
                      My Account
                    </Link>
                  </li>
                  <li className='mt-3'>
                    <Link to={'/faq'} className='text-secondary '>
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* end last list */}
        </div>
      </div>
      <hr />
      <div className='container-fluid bg-black text-center py-3'>
        <p className='text-white'>Copyright {currentYear} | Krent.space</p>
      </div>
    </footer>
  );
};
