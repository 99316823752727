import {useSessionStorage} from '../../hooks';
import {PropertyListResponse} from '@interfaces/properties.interface';
import {DashboardLayout} from '@layouts/DashboardLayout';
import {PropertyCardList} from '@lists/PropertyCardList';
import {
  getAllProperties,
  // getAllProperties,
  getPropertyTransactions,
  // getSingleProperty,
} from '@services/krent.service';
import {useEffect, useState} from 'react';
import ktoast from '@services/toast.service';

export const MyProperties = () => {
  const [user] = useSessionStorage('user', {});
  const [loading, setLoading] = useState<boolean>(false);
  const [properties, setProperties] = useState<PropertyListResponse[]>([]);
  // const [allproperties, setAllProperties] = useState<PropertyListResponse[]>(
  //   []
  // );
  // const [transactions, setTransactions] = useState<any>();

  const filterProperty = (id: string, data: any) => {
    const res = data.filter((p: any) => p.id === id);
    return res;
  };
  useEffect(() => {
    setLoading(true);
    let abortController: AbortController = new AbortController();
    getAllProperties(abortController.signal)
      .then((propertyRes) => {
        // setAllProperties(propertyRes.data);
        getPropertyTransactions(user?.id, abortController.signal)
          .then((res: any) => {
            for (let i = 0; i < res.data?.length; i++) {
              const prop = filterProperty(
                res.data[i].listing.id,
                propertyRes.data
              );

              setProperties((p) => [...p, ...prop]);
            }
            // setTransactions(res.data);
          })
          .catch((err) => {
            ktoast.error(err.errors[0].message);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err.errors[0].message);
      });

    return () => {
      abortController.abort();
    };
  }, [user]);

  useEffect(() => {
    setLoading(true);
    let abortController: AbortController = new AbortController();

    return () => {
      abortController.abort();
    };
  }, [user]);

  if (loading) {
    return (
      <DashboardLayout>
        <div className='row mb-3'>
          <h3 className='fw-bold mb-1'>My Properties</h3>
          <p className='text-secondary fs-14'>
            Manage all the properties listed on this platform.
          </p>
        </div>

        <section className='container'>
          <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5 mb-5'>
            {[...new Array(6).fill(0)].map((_, j) => (
              <div className='col ' key={j}>
                <div className='col'>
                  <div className='card  border-light shadow-sm h-100'>
                    <div className='row g-0'>
                      <div className='col-md-4 h-100'>
                        <svg
                          className='bd-placeholder-img img-fluid rounded-start h-100'
                          width='100%'
                          height='180'
                          xmlns='http://www.w3.org/2000/svg'
                          role='img'
                          aria-label='Placeholder'
                          preserveAspectRatio='xMidYMid slice'
                          focusable='false'
                        >
                          <title>Placeholder</title>
                          <rect
                            width='100%'
                            height='100%'
                            fill='#868e96'
                          ></rect>
                        </svg>
                      </div>
                      <div className='col-md-8'>
                        <div className='card-body'>
                          <h5 className='card-title placeholder-glow'>
                            <span className='placeholder col-6'></span>
                          </h5>
                          <p className='card-text placeholder-glow'>
                            <span className='placeholder col-4'></span>
                            <span className='placeholder col-6'></span>
                            <span className='placeholder col-8'></span>
                            <span className='placeholder col-5'></span>
                            <span className='placeholder col-8'></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <h3 className='fw-bold mb-1'>My Properties</h3>
        <p className='text-secondary fs-14'>
          Properties you have engaged with.
        </p>
      </div>

      <section className='container'>
        <PropertyCardList
          orientation='horizontal'
          lg={2}
          md={1}
          sm={1}
          transactions
          properties={properties}
          loading={loading}
        />
      </section>
    </DashboardLayout>
  );
};
