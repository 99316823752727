import {PropertyListResponse} from '@interfaces/properties.interface';

export const getTitle = (
  int: string,
  location: string,
  totalListed: number
) => {
  let intent = int;
  let title = `Property for ${intent}`;
  if (int === 'shortlet') {
    title = `Find ${intent} in ${location} ${totalListed} Listed | Krent`;
  }

  if (location) {
    title = `Find House, Flats & Land For ${intent} in ${location} ${totalListed} Listed | Krent`;
  }
  return title;
};

export const getH1 = (
  int?: string,
  location?: string,
  bedroom?: string,
  kind?: string
) => {
  let intent = int;
  let title = `Property for ${intent} in ${
    location ? capitalizedWord(location) : 'Nigeria'
  } `;
  if (int === 'shortlet') {
    title = `Shortlet Property in ${
      location ? capitalizedWord(location) : 'Nigeria'
    } `;
    if (location) {
      title = `Shortlet Property in ${capitalizedWord(location)}`;
    }
    if (bedroom) {
      title = `${bedroom} Shortlet Property in Nigeria`;
    }
    if (kind) {
      title = `${capitalizedWord(kind)} Shortlet Property in ${
        location ? capitalizedWord(location) : 'Nigeria'
      } `;
    }
    return title;
  }

  if (location) {
    title = `Property for  ${capitalizedWord(intent!)} in ${capitalizedWord(
      location
    )}`;
  }
  if (bedroom) {
    title = `${bedroom} property for ${capitalizedWord(intent!)} in Nigeria`;
  }
  if (kind) {
    if (kind === 'commercial') {
      title = `Commercial property for ${capitalizedWord(intent!)} in Nigeria`;
      return title;
    }
    title = `${capitalizedWord(kind)} for ${capitalizedWord(intent!)} in ${
      location ? capitalizedWord(location) : 'Nigeria'
    } `;
  }
  return title;
};

export const getH2 = (
  int?: string,
  location?: string,
  total?: string,
  bedroom?: string,
  kind?: string
) => {
  let intent = int;
  let locationText = location ? `in ${location}, Nigeria` : 'in Nigeria';
  let text = `As of ${todayDate()}, we have a total of ${total} ${
    bedroom ? bedroom : ''
  }${
    kind === 'commercial' ? 'commercial property' : kind ? kind : 'Property'
  } for ${intent} ${locationText}. These listings are posted by both real estate agents and property owners verified by Krent. You can refine your search by price, number of bedrooms, and property type. Finding your perfect home is now made easy.`;

  return text;
};

export const getDescription = (int: string, location: string) => {
  if (location) {
    return `Find the best properties for ${int} in ${
      location.charAt(0).toUpperCase() + location.slice(1)
    }. Browse listings and get the best deals.`;
  }

  return `Find the best properties for ${int} Browse listings and get the best deals.`;
};

export const getCanonicalUrl = (int: string, location: string) => {
  if (location) {
    return `https://www.krent.space/property-for-${int}/${location}`;
  }
  return `https://www.krent.space/property-for-${int}`;
};

export const todayDate = () => {
  const getFormattedDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.toLocaleString('default', {month: 'long'});
    const year = today.getFullYear();

    return `${day} ${month} ${year}`;
  };

  return getFormattedDate();
};

export const getDataProperties = (data: any) => {
  const unique = <T>(arr: (T | undefined)[]): T[] =>
    Array.from(new Set(arr.filter((item): item is T => item !== undefined)));

  const cities = unique(
    data?.map((item: PropertyListResponse) => item.location?.city) ?? []
  );
  const bedrooms = unique(
    data?.map((item: PropertyListResponse) => {
      if (item.bedrooms !== undefined) {
        return `${item.bedrooms} `;
        // ${
        //   item.bedrooms === 1 ? 'bedroom' : 'bedrooms'
        // }`;
      }
      return undefined;
    }) ?? []
  );
  const kinds = unique(
    data?.map((item: PropertyListResponse) => item.kind?.title) ?? []
  );

  return {cities, bedrooms, kinds};
};

export const formatLinkText = (text: string) => {
  return text.toLowerCase().replace(/\s+/g, '-');
};

export const reverseFormatLinkText = (text: string) => {
  return text.replace(/-/g, ' ');
};

export const extractNumber = (text: string) => {
  const hypenIndex = text.indexOf('-');
  const number = text.substring(0, hypenIndex);

  return number;
};

export const capitalizedWord = (text: string) => {
  const newWord = text.charAt(0).toUpperCase() + text.slice(1);
  return newWord;
};

export const getMostExpensive = (data: any) => {
  const mostExpensive = data?.reduce(
    (max: any, item: any) => (item.price > max.price ? item : max),
    data[0]
  );

  return mostExpensive?.price?.toLocaleString();
};

export const getCheapest = (data: any) => {
  const cheapest = data?.reduce(
    (max: any, item: any) => (item.price < max.price ? item : max),
    data[0]
  );

  return cheapest?.price?.toLocaleString();
};

export const getAverage = (data: any) => {
  const average = data?.reduce((sum: number, item: any) => sum + item.price, 0);
  const averagePrice = average / data?.length;
  return averagePrice?.toLocaleString();
};
