import {separateFullName} from '@utils/helpers';

type Props = {
  image: string;
  name: string;
  title: string;
  testimony: string;
  inverse?: boolean;
};

export const TestimonialCard: React.FC<Props> = ({
  image,
  name,
  // title,
  testimony,
}: // inverse = false,
Props) => {
  const {firstName, lastName} = separateFullName(name);

  return (
    <div className='col  pb-5 rounded-3'>
      <div
        className='d-flex flex-column gap-2 text-center position-relative   border border-white h-100 rounded-3'
        style={{padding: '23px 32px', background: '#FAFCFF'}}
      >
        {/* <div className='position-absolute' style={{}}>
          <span className=' fa fa-quote-left d-inline-block fs-32 text-primary mb-4 text-left'></span>
        </div> */}
        <p className=' text-light-black fs-6 text-start mb-5 flex-fill'>
          <span className=' fa fa-quote-left d-block fs-32 text-primary mb-2 text-left'></span>
          {testimony}
        </p>
        <div className=' d-flex align-items-center gap-3 '>
          <div
            className=' d-inline-block  border-white rounded-circle position-relative overflow-hidden '
            style={{
              width: '5rem',
              height: '5rem',
              // boxShadow: '0 0 2px 2px #05569f',
            }}
          >
            <img src={image} alt='' className='w-100 h-auto' />
          </div>
          <div className=''>
            <p className='font-onest text-start fw-bold  text-black text-capitalize'>
              {firstName} {lastName}
            </p>
            <p className='text-start font-onest fs-14  fw-400 text-light-black mb-1 text-capitalize'>
              Lagos Nigeria
            </p>
          </div>
          {/* <span >{title}</span> */}
        </div>
      </div>
    </div>
  );
};
