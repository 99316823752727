import { OnboardingLayout } from '@layouts/OnboardingLayout';
import { PrimaryButton } from '@shared/buttons';

export const ChangePasswordSuccess = () => {
	return (
		<OnboardingLayout>
			<div
				className='container p-3 p-md-5'
				style={{
					height: '100vh',
				}}
			>
				<div className='col-12 col-md-10'>
					<div className='row text-center text-md-start mt-4 mt-md-0'>
						<a className='navbar-brand fw-bolder fs-4' href='#!'>
							krent
						</a>
					</div>

					<div className='col my-5 py-5 py-md-4 text-center text-md-start'>
						<h4 className='fw-bold'>Password changed</h4>
						<p className='my-5 my-md-3'>
							<i
								className='fa-solid fa-circle-check'
								style={{
									fontSize: '5rem',
									color: '#28C955',
								}}
							></i>
						</p>

						<p className='light-text mb-4 mb-md-0'>
							Your password has been changed successfully. You may now log in.
						</p>

						<PrimaryButton type='outline' text='Log In To Krent' />
					</div>
				</div>
			</div>
		</OnboardingLayout>
	);
};
