import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const AdModal = ({news}) => {
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 7000,
    draggable: true,
    // fade: true,
    focusOnSelect: true,
    pauseOnFocus: true,
  };

  return (
    <Slider {...settings}>
      {news.map((item) => (
        <div
          key={item?.id}
          className=''
          style={{
            zIndex: 100,
          }}
        >
          <div className='d-flex gap-2 pr-2 '>
            <div className=''>
              <img
                src={item?.uagb_featured_image_src?.thumbnail[0]}
                alt=''
                style={{
                  width: '6.875rem',
                  height: '8.125rem',
                  objectFit: 'cover',
                }}
                className=''
              />
            </div>
            <div className=''>
              <p className='text-white p-1 fs-12'>{item?.title?.rendered}</p>

              <a
                href={item?.link}
                target='_blank'
                rel='noreferrer'
                className='btn bg-primary p-1 fs-10 text-white mt-1'
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default AdModal;
