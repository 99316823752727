import { useState } from 'react';
import axios from 'axios';

import { OnboardingLayout } from '@layouts/OnboardingLayout';
import { InputComponent } from '@shared/forms';
import { PrimaryButton } from '@shared/buttons';
import Logo from '@shared/Logo';
import { Link, useNavigate } from 'react-router-dom';

const url = process.env['REACT_APP_API_BASE_URL'];

export const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(`${url}/auth/forgotpassword`, {
        email,
      })
      .then((res) => {
        setSuccess(
          `${res.data.data.slice(0, res.data.data.length - 1)} to ${email} `
        );
        setTimeout(() => {
          setSuccess('');
          navigate('/sign-in/');
        }, 3000);
      })
      .catch((err) => {
        setError(err.response.data.errors[0].message);
        setTimeout(() => {
          setError('');
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <OnboardingLayout>
      <div className='container'>
        {/* <h3 className='text-md-start fw-bolder mb-5'>krent</h3> */}
        <h3
          className='text-md-start fw-bolder mb-2 text-link text-black mt-3'
          onClick={() => navigate('/')}
        >
          <Logo />
        </h3>
        <h4 className='fw-bolder'>Reset your password</h4>
        <p className='text-secondary fs-14 mb-4'>
          Lost your password? No worries we’ve got you covered it happens to the
          best of us. Enter your email address.
        </p>
        <form className='mt-4 mt-md-3' onSubmit={handleSubmit}>
          <div className='col mb-4'>
            {success && <span className='text-success col-12'>{success}</span>}
            <InputComponent
              label='Email Address'
              placeholder='Enter your email address'
              icon='Message'
              type='email'
              value={email}
              change={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className='col text-center'>
            {error && <span className='text-danger mb-1'>{error}</span>}
            <PrimaryButton
              type='primary'
              text='Reset Password'
              loading={loading}
            />
          </div>
          <div className='col text-end mb-5'>
            <Link className='text-secondary fs-14 text-link' to='/'>
              Back Home
            </Link>
            <Link className='text-secondary fs-14 text-link ml-3' to='/sign-in'>
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </OnboardingLayout>
  );
};
