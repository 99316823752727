import {LocationListResponse} from '@interfaces/locations.interface';
// import { NewsListResponse } from "@interfaces/news.interface";
import {PropertyListResponse} from '@interfaces/properties.interface';
import {TestimonialListResponse} from '@interfaces/testimonials.interface';
import locationImage2 from '../../assets/images/location-card-2.png';
import locationImage3 from '../../assets/images/location-card-3.png';
import locationImage1 from '../../assets/images/location-card.png';
// import newsImage from "../../assets/images/news-card-image.png";
// import testimonyImage1 from '../../assets/images/tm.jpeg';
// import testimonyImage2 from '../../assets/images/tm3.jpeg';
// import testimonyImage3 from '../../assets/images/tm4.jpeg';
// import testimonyImage4 from '../../assets/images/tw.webp';
import testimonyImage from '../../assets/images/avatar ph.jpeg';

import propertyIcon from '../../assets/icons/property.svg';
import documentationIcon from '../../assets/icons/documentation.svg';
import paymentIcon from '../../assets/icons/payment.svg';
import locationIcon from '../../assets/icons/location.svg';

export const properties: PropertyListResponse[] = [
  {
    category: {
      _id: '612881e0d0c4166348e3debf',
      title: 'Rental unit',
      slug: 'rental-unit',
    },
    kind: {
      id: '612a149cc1976d6838debb1c',
      title: 'Apartment',
      slug: 'apartment',
    },
    location: {
      type: 'Point',
      coordinates: [3.33075, 6.52051],
      formattedAddress: 'Alhaji Jimoh Street, Lagos, Lagos, NG',
      street: 'Alhaji Jimoh Street',
      city: 'Lagos',
      state: 'Lagos',
      zipcode: '',
      country: 'NG',
    },
    featured: false,
    otherImages: [],
    floorPlans: [],
    guests: 2,
    ratings: 0,
    ratingsAverage: 0,
    published: false,
    title: 'Ikeja House',
    summary: 'Ikeja house',
    amenities: [
      {
        offers: [
          'Kitchen',
          'Space where guests can cook their own meals',
          'Refrigerator',
          'Microwave',
          'Cooking basics',
          'Coffee maker',
          'Pots and pans, oil, salt and pepper',
          'Dishes and silverware',
          'Bowls, chopsticks, plates, cups, etc.',
          'Stove',
          'Hot water kettle',
          'Wine glasses',
          'Barbecue utensils',
          'Grill, charcoal, bamboo skewers/iron skewers, etc.',
          'Dining table',
        ],
        title: 'Kitchen and dining',
      },
      {
        offers: ['Bathtub', 'Hot water', 'Hair dryer'],
        title: 'Bathroom',
      },
    ],
    price: 150000,
    currency: 'NGN',
    description: 'Ikeja house',
    intent: 'long lease',
    cycle: 'yearly',
    beds: 1,
    bedrooms: 1,
    bathrooms: 1,
    virtual_tour_url: 'http://youtube.com',
    video_url: 'https://youtube.com',
    user: {
      role: 'user',
      name: 'UCHE',
      email: 'uche@uxmaestro.com',
      id: '625d755d95f6f9bc6148424e',
    },
    createdAt: new Date('2022-04-19T14:17:04.929Z'),
    updatedAt: new Date('2022-04-19T14:17:04.929Z'),
    slug: 'ikeja-house',
    reviews: [],
    id: '625ec460f1f4c317fd97a9c4',
  },
  {
    category: {
      _id: '61288274d0c4166348e3dec8',
      title: 'Serviced Apartment',
      slug: 'serviced-apartment',
    },
    kind: {
      id: '612a149cc1976d6838debb1c',
      title: 'Apartment',
      slug: 'apartment',
    },
    location: {
      type: 'Point',
      coordinates: [4.091523, 6.40948],
      formattedAddress: ', Lekki, Lagos, NG',
      street: '',
      city: 'Lekki',
      state: 'Lagos',
      zipcode: '',
      country: 'NG',
    },
    featured: false,
    otherImages: [
      'property-625e900b95f6f9bc61484363-1650375148418-1.jpeg',
      'property-625e900b95f6f9bc61484363-1650375148423-2.jpeg',
    ],
    floorPlans: [],
    guests: 3,
    ratings: 0,
    ratingsAverage: 0,
    published: false,
    title: '3 Room Luxury Apartment In Lekki 1',
    summary: 'Fully fitted 3 rooms luxury apartment in Lekki I',
    amenities: [
      {
        offers: [],
        title: 'Gym',
      },
      {
        offers: [],
        title: 'Dish Washer',
      },
    ],
    price: 5000000,
    currency: 'NGN',
    description: 'Lorem ipsum',
    intent: 'long lease',
    cycle: 'yearly',
    beds: 3,
    bedrooms: 3,
    bathrooms: 4,
    virtual_tour_url: 'https://youtube.com/tour',
    video_url: 'https://youtube.com',
    user: {
      role: 'landlord',
      name: 'ONOCHIE JEFFREY',
      email: 'jeff.ict@gmail.com',
      id: '61273cec9d7e08389055c124',
    },
    createdAt: new Date('2022-04-19T10:33:47.866Z'),
    updatedAt: new Date('2022-04-19T13:32:28.779Z'),
    slug: '3-room-luxury-apartment-in-lekki-1',
    // coverImage: "property-625e900b95f6f9bc61484363-1650375148247-cover.jpeg",
    reviews: [],
    id: '625e900b95f6f9bc61484363',
  },
  {
    category: {
      _id: '61288274d0c4166348e3dec8',
      title: 'Serviced Apartment',
      slug: 'serviced-apartment',
    },
    kind: {
      id: '612a149cc1976d6838debb1c',
      title: 'Apartment',
      slug: 'apartment',
    },
    location: {
      type: 'Point',
      coordinates: [3.46417, 6.44264],
      formattedAddress:
        'Fola Osibo Road & Adebayo Doherty Street, Lagos, Lagos, NG',
      street: 'Fola Osibo Road & Adebayo Doherty Street',
      city: 'Lagos',
      state: 'Lagos',
      zipcode: '',
      country: 'NG',
    },
    featured: false,
    otherImages: [
      'property-6206c507a4f9116437fdb223-1646647329940-1.jpeg',
      'property-6206c507a4f9116437fdb223-1646647329941-2.jpeg',
      'property-6206c507a4f9116437fdb223-1647199433993-1.jpeg',
    ],
    floorPlans: [
      'property-floorplan-6206c507a4f9116437fdb223-1646647330147-2.jpeg',
      'property-floorplan-6206c507a4f9116437fdb223-1646647330147-1.jpeg',
    ],
    guests: 3,
    ratings: 0,
    ratingsAverage: 0,
    published: false,
    title: 'New Property 2022 Image Test',
    summary: 'Lovely apartment in secure serene location',
    amenities: [
      {
        offers: [],
        title: 'Dish Washer',
      },
      {
        offers: [],
        title: 'Gym',
      },
      {
        offers: [],
        title: 'Emergency Exit',
      },
      {
        offers: ['Wifi', 'Dedicated workspace'],
        title: 'Internet and office',
      },
      {
        offers: ['Sit out furnitures', 'Mirrow', 'Swing Chair'],
        title: 'Balcony 2.0',
      },
      {
        offers: [],
        title: 'Elevator',
      },
      {
        offers: [],
        title: 'Fire Alarm',
      },
      {
        offers: [],
        title: 'Pet Friendly',
      },
      {
        offers: [],
        title: 'Modern Kitchen',
      },
      {
        offers: ['Portable fans', 'Heating', 'Air conditioning'],
        title: 'Heating and cooling',
      },
      {
        offers: ['Item', 'Item 2'],
        title: 'New Amenity',
      },
    ],
    price: 250000,
    currency: 'NGN',
    description:
      'Description for New Property 2022 Image Test New Property 2022 Image Test',
    intent: 'long lease',
    cycle: 'weekly',
    beds: 2,
    bedrooms: 2,
    bathrooms: 2,
    virtual_tour_url:
      'https://www.youtube.com/watch?v=PP3S8W9F7iA&ab_channel=EdenOasis',
    video_url:
      'https://www.youtube.com/watch?v=PP3S8W9F7iA&ab_channel=EdenOasis',
    user: {
      role: 'landlord',
      name: 'ONOCHIE JEFFREY',
      email: 'jeff.ict@gmail.com',
      id: '61273cec9d7e08389055c124',
    },
    createdAt: new Date('2022-02-11T20:20:23.806Z'),
    updatedAt: new Date('2022-03-13T19:23:54.180Z'),
    slug: 'new-property-2022-image-test',
    // coverImage: "property-6206c507a4f9116437fdb223-1646649089022-cover.jpeg",
    reviews: [],
    id: '6206c507a4f9116437fdb223',
  },
];

export const locations: LocationListResponse[] = [
  {
    image: locationImage1,
    title: 'Lagos',
    description: '147 properties',
  },
  {
    image: locationImage2,
    title: 'Port Harcourt',
    description: '169 properties',
  },
  {
    image: locationImage3,
    title: 'Abuja',
    description: '30 properties',
  },
];

// export const news: NewsListResponse[] = [
//   {
//     image: newsImage,
//     title: "Krent goes on live beta",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
//   },
//   {
//     image: newsImage,
//     title: "Krent goes on live beta",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
//   },
//   {
//     image: newsImage,
//     title: "Krent goes on live beta",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
//   },
//   {
//     image: newsImage,
//     title: "Krent goes on live beta",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
//   },
// ];

export const testimonials: TestimonialListResponse[] = [
  {
    image: testimonyImage,
    testimony:
      'I needed a place for my besties and me to enjoy my birthday, and I began my search on Instagram. Mid search, a Krent ad caught my eye and I quickly went on the website.  Finding the perfect spot was easy, and the booking process was super smooth. We had a blast celebrating in our stylish apartment; it was the perfect hangout for us.',
    name: 'Oluwatomisin',
    title: 'User',
  },
  {
    image: testimonyImage,
    testimony:
      'As a professional  who relocates often, Krent is my go-to platform for finding shortlets. I love the flexibility it offers, letting me choose apartments in different neighborhoods based on my project needs. The platform is user-friendly, and the listings always match my expectations.Plus, the customer support is top-notch.',
    name: 'Jason',
    title: 'User',
  },
  {
    image: testimonyImage,
    testimony:
      'Getting renters for my properties was really difficult. Then, a fellow agent told me about Krent. I was skeptical but after seeing his success, I gave it a shot. I’m not very good at navigating online platforms but thanks to Krent’s amazing support team, it was easy! They walked me through everything. Recently, I closed a couple of deals, and I hope to get more in the future.',
    name: 'Mr Ayodele',
    title: 'User',
  },
  {
    image: testimonyImage,
    testimony:
      'Choosing Krent for my property listings was a smart move. Their media team even came over to film a beautiful 3D tour and capture high-quality images of my property. My property practically shined online! With this, I was able to swiftly attract interested buyers and renters. It didnt feel like a hassle at all. I almost got moved to try something else. It also had a lot of budget friendly options.',
    name: 'Mrs Cassandra',
    title: 'User',
  },
];
export const serviceData: any = [
  {
    image: propertyIcon,
    text: 'Find sweet deals on houses or apartments in your desired locationin Nigeria. Virtual walk-through or in-person viewing available.',
    title: 'Search for property',
  },
  {
    image: documentationIcon,
    text: 'Easily submit documents and applications through a secured online portal. We do thorough background checks and identity verification.',

    title: 'Documentation',
  },
  {
    image: paymentIcon,
    text: ' Secured payments for one-time purchase of properties or installment payments for a rental homes, apartments, villas and more.',

    title: 'Make Payment',
  },
  {
    image: locationIcon,
    text: ' open the door to a million happy memories with our top-notch services. Congratulations on your new property!',

    title: 'Get the keys to your Dream Property',
  },
];
