import {BaseLayout} from '@layouts/BaseLayout';
// import axios from 'axios';

import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';

const NotFoundPage = () => {
  useEffect(() => {
    const head = document.head; // Get the head element
    const titleElement = document.querySelector('title');
    const metaElements = document.querySelectorAll('meta');

    // Move the title to the top of the <head>
    if (titleElement) {
      head.insertBefore(titleElement, head.firstChild);
    }

    // Move meta tags to the top, right after the title
    Array.from(metaElements).forEach((meta) => {
      head.insertBefore(
        meta,
        titleElement ? titleElement.nextSibling : head.firstChild
      );
    });
  }, []);
  // useEffect(() => {
  //   console.log(document);
  //   const getProfile = async () => {
  //     const res = await axios.get(
  //       `${process.env['REACT_APP_API_BASE_URL']}/props/`
  //     );
  //     console.log(res);
  //   };
  //   getProfile();
  // }, []);
  return (
    <BaseLayout>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name='robots' content='noindex, follow' />
        <meta
          name='description'
          content='The page you are looking for does not exist. Please use the navigation to find the right page.'
        />
      </Helmet>
      <div
        className='d-flex flex-column justify-content-center align-items-center  bg-light'
        style={{height: '54vh'}}
      >
        <div className='text-center'>
          <h1 className='display-1 fw-bold text-danger'>404</h1>
          <p className='lead'>
            Oops! The page you're looking for doesn't exist.
          </p>
          <Link to='/' className='btn btn-primary mt-3'>
            Go Back Home
          </Link>
        </div>
      </div>
    </BaseLayout>
  );
};

export default NotFoundPage;
