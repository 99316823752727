import React from 'react';
import {TestimonialCard} from '@shared/cards';
import {TestimonialListResponse} from '@interfaces/testimonials.interface';

type Props = {
  md?: number;
  lg?: number;
  sm?: number;
  xl?: number;
  items?: number;
  testimonials: TestimonialListResponse[];
};

export const TestimonialCardList: React.FC<Props> = ({
  testimonials,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
}: Props) => {
  return (
    <React.Fragment>
      <div
        className={`row row-cols-${sm} row-cols-md-${md} row-cols-lg-${lg} row-cols-xl-${xl} g-lg-5 mb-5 `}
      >
        {testimonials.map((v, k) => {
          return <TestimonialCard {...v} key={k} inverse={k % 2 === 1} />;
        })}
      </div>
    </React.Fragment>
  );
};
