import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { ListingLayout } from '@layouts/ListingLayout';

import { ListShortlet } from './Shortlet/ListShortlet';
import { ListRent } from './Rent/ListRent';
import { ListSale } from './Sale/ListSale';
import { ListiBuying } from './iBuying/ListiBuying';

export const ListProperty = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const edit = searchParams.get('id');
  const { type } = useParams();

  const navigate = useNavigate();

  let title;

  if (edit) {
    title = `${
      type === 'rental'
        ? 'Update Your Rental Property'
        : type === 'shortlet'
        ? 'Update Your Shortlet Property'
        : type === 'sales'
        ? 'Update Your Property'
        : type === 'iBuying'
        ? 'Welcome To Krent iBuying'
        : ''
    }`;
  } else {
    title = `${
      type === 'rental'
        ? 'List A Rental Property'
        : type === 'shortlet'
        ? 'List A Shortlet Property'
        : type === 'sales'
        ? 'Sell A Property'
        : type === 'iBuying'
        ? 'Welcome To Krent iBuying'
        : ''
    }`;
  }

  const description = `${
    type === 'rental'
      ? 'Rent out your apartments to people for a short period of time'
      : type === 'shortlet'
      ? 'Shortlet your apartments to people for a short period of time'
      : type === 'sales'
      ? 'Sell your apartments to people'
      : type === 'iBuying'
      ? 'Krent has a high purchasing power to buy your apartment in 72 hours'
      : ''
  }`;

  return (
    <ListingLayout type={type!}>
      <div
        style={{ height: '90vh', overflowY: 'scroll' }}
        className='col-12 p-3 p-md-4'
      >
        <button
          className=' d-flex flex-row align-items-center gap-1 fs-14 mb-3 btn-el text-link text-dark'
          onClick={() => navigate('/listings')}
        >
          <i className='iconly-Arrow-Left-Circle icli'></i>
          Back To Dashboard
        </button>

        <div className='col'>
          <h3 className='fw-bold'>{title}</h3>
          <p className='light-text'>{description}</p>
        </div>

        <div className='col'>
          {type === 'shortlet' && <ListShortlet />}
          {type === 'rental' && <ListRent />}
          {type === 'sales' && <ListSale />}
          {type === 'iBuying' && <ListiBuying />}
        </div>
      </div>
    </ListingLayout>
  );
};
