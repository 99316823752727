import {Link} from 'react-router-dom';

type Props = {
  id: string;
  propert?: any;
  property: string;
  cost: number;
  duration: string;
  listed: Date;
  status: boolean;
  stay?: number;
  propertyType?: any;
  intent?: string;
  setIntent?: (type: string) => void;
  setModalType?: (type: string) => void;
  // setId?: (type: string) => void;
  setId?: (type: any) => void;
  setShowModal: (show: boolean) => void;
  from?: string;
  cardType: 'listing' | 'request';
  archive?: any;
  otherImages?: string[];
  setListingId?: (type: string) => void;
  isVisible?: boolean;
  toggleVisibility?: any;
  slug?: string;
};

export const ListingCard = ({
  id,
  propert,
  property,
  cost,
  duration,
  listed,
  status,
  stay,
  propertyType = '',
  setModalType,
  setId,
  setIntent,
  intent,
  setShowModal,
  setListingId,
  // archive,
  otherImages,
  cardType,
  isVisible,
  toggleVisibility,
}: // slug,
Props) => {
  return (
    <tr
      className='table-group-divider align-middle position-relative'
      style={{borderTopColor: '#8F8FB147'}}
    >
      <td className='fw-400 p-3 text-wrap' style={{maxWidth: 250}}>
        <Link
          to={`/property/${propert?.slug}`}
          state={{property: propert, id: propert?.slug}}
          style={{textDecoration: 'none'}}
        >
          {property}
        </Link>
      </td>
      <td className='fw-400 p-3'>
        <span className='fw-bold'>NGN</span>{' '}
        <span>{cost?.toLocaleString()}</span>
        {propert?.intent === 'sale' ? '' : <span>/ {duration}</span>}
      </td>
      <td className='fw-400 p-3 text-capitalize'>{propertyType}</td>
      <td className='fw-400 p-3'>
        {new Date(listed?.toLocaleString()).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </td>
      <td className='fw-400 p-3'>
        <span className='d-flex justify-content-center align-items-center gap-2 rounded-pill border border-2 p-2 fs-12'>
          <i
            className={`fas fa-circle ${
              status ? 'text-success' : 'text-warning'
            }`}
          />
          <span className='m-0 text-capitalize'>
            {status ? 'Published' : 'Pending'}
          </span>
        </span>
      </td>
      {cardType === 'request' && (
        <td className='fw-400 p-3'>
          {stay} {`${duration}${stay! > 1 ? 's' : ''}`}
        </td>
      )}
      <td className='fw-400 p-3'>
        {otherImages?.length === 0 && (
          <div className='position-absolute  top-0 end-0 p-0  '>
            <span className='d-block bg-warning p-1 '>Pending Images</span>
            <Link
              className='text-end d-block '
              to={`/listings/${
                intent === 'long lease'
                  ? 'rental'
                  : intent === 'sale'
                  ? 'sales'
                  : intent
              }/?id=${id}`}
              state={{index: 6}}
            >
              Add Now
            </Link>
          </div>
        )}
        <div className='dropdown'>
          <i
            className='fas fa-ellipsis-vertical fs-18'
            data-bs-toggle='dropdown'
            aria-expanded='false'
            style={{cursor: 'pointer'}}
          />
          <ul className='dropdown-menu fs-14 border-round px-3'>
            <li>
              {/* <a
                className='dropdown-item'
                href='#!'
                onClick={() => console.log(id, status)}
              >
                View
              </a> */}
              <Link
                className='dropdown-item'
                to={`/property/${propert?.slug}`}
                state={{property: propert, id: propert?.slug}}
              >
                View
              </Link>
            </li>
            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <span
                className='dropdown-item cursor-pointer'
                // href='#!'
                onClick={() => {
                  setModalType!('edit-listing');
                  setId!(propert?.id);
                  setIntent!(intent!);
                  setShowModal(true);
                }}
              >
                Edit
              </span>
            </li>

            <li>
              <hr className='dropdown-divider' />
            </li>
            {/* <li>
              <span
                className='dropdown-item cursor-pointer'
                // href='#!'
                onClick={() => {
                  setModalType!('edit-listing');
                  setId!(id);
                  setIntent!(intent!);
                  setShowModal(true);
                }}
              >
                Hide
              </span>
            </li> */}
            {/* <li>
              <hr className='dropdown-divider' />
            </li> */}
            <li>
              <span
                className='dropdown-item cursor-pointer'
                onClick={() => {
                  // setModalType!('delisting');
                  // setId!(id);
                  // setShowModal(true);
                  toggleVisibility();
                }}
              >
                {isVisible ? 'Hide' : 'Unhide'}
              </span>
            </li>

            <li>
              <hr className='dropdown-divider' />
            </li>
            {/* </>
									)} */}

            <li>
              <span
                className='dropdown-item cursor-pointer'
                onClick={() => {
                  setModalType!('delete-listing');
                  setShowModal(true);
                  setId!(id);
                  setListingId!(id);
                }}
              >
                Delete
              </span>
            </li>
          </ul>
        </div>
      </td>
      {/* {otherImages?.length === 0 && (
        <td className='position-absolute  top-0 end-0 p-0 '>
          <span className='d-block bg-warning p-1 '>Pending Images</span>
          <Link
            className='text-center d-block'
            to={`/listings/${
              intent === 'long lease'
                ? 'rental'
                : intent === 'sale'
                ? 'sales'
                : intent
            }/?id=${id}`}
            state={{index: 6}}
          >
            Add Now
          </Link>
        </td>
      )} */}
    </tr>
  );
};

export const ListingCardMobile = ({
  id,
  property,
  propert,
  cost,
  duration,
  listed,
  status,
  stay,
  from,
  setShowModal,
  setModalType,
  propertyType,
  cardType,
  setId,
  setIntent,
  intent,
  otherImages,
  toggleVisibility,
  isVisible,
}: Props) => {
  return (
    <>
      <div
        className='card border border-0 border-round p-4 my-4'
        style={{
          color: '#0F0C3D',
        }}
      >
        <div className='row my-4'>
          <div className='col-9'>
            <h6 className='h-card-color'>PROPERTY</h6>
            <Link
              to={`/property/${propert?.slug}`}
              state={{property: propert, id: propert?.slug}}
              style={{textDecoration: 'none'}}
            >
              {property}
            </Link>
          </div>
          <div className='col-3'>
            <div className='dropdown'>
              <i
                className='fas fa-ellipsis-vertical fs-18'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{cursor: 'pointer'}}
              />

              <ul className='dropdown-menu fs-14 border-round px-3'>
                <li>
                  {cardType === 'listing' ? (
                    <Link
                      className='dropdown-item'
                      to={`/property/${propert?.slug}`}
                      state={{property: propert, id: propert?.slug}}
                    >
                      View
                    </Link>
                  ) : (
                    <a
                      className='dropdown-item'
                      href='#!'
                      onClick={() => {
                        // setId!(id);
                        setShowModal!(true);
                      }}
                    >
                      View
                    </a>
                  )}
                </li>

                {cardType === 'listing' && (
                  <>
                    <li>
                      <hr className='dropdown-divider' />
                    </li>

                    <li>
                      <span
                        className='dropdown-item cursor-pointer'
                        // href='#!'
                        onClick={() => {
                          setModalType!('edit-listing');
                          setId!(id);
                          setIntent!(intent!);
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </span>
                    </li>

                    <li>
                      <hr className='dropdown-divider' />
                    </li>
                    <li>
                      <span
                        className='dropdown-item cursor-pointer'
                        onClick={() => {
                          toggleVisibility();
                        }}
                      >
                        {isVisible ? 'Hide' : 'Unhide'}
                      </span>
                    </li>

                    <li>
                      <hr className='dropdown-divider' />
                    </li>

                    <li>
                      <span
                        className='dropdown-item cursor-pointer'
                        onClick={() => {
                          setModalType!('delete-listing');
                          setShowModal(true);
                          setId!(id);
                          // setListingId!(id);
                        }}
                      >
                        Delete
                      </span>
                    </li>
                    {/* <li>
                      <a
                        className='dropdown-item'
                        href='#!'
                        onClick={() => {
                          setModalType!('delisting');
                          // setId(id);
                          setShowModal(true);
                        }}
                      >
                        Delist
                      </a>
                    </li>

                    <li>
                      <hr className='dropdown-divider' />
                    </li>

                    <li>
                      <a
                        className='dropdown-item'
                        href='#!'
                        onClick={() => {
                          setModalType!('delete-listing');
                          // setId(id);
                          setShowModal(true);
                        }}
                      >
                        Delete
                      </a>
                    </li> */}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-6'>
            <h6 className='h-card-color'>COST PER NIGHT</h6>
            <p className='m-0'>
              <span className='fw-bold'>NGN</span> {cost?.toLocaleString()}
            </p>
          </div>
          {stay && (
            <div className='col-6'>
              <h6 className='h-card-color'>STAY DURATION</h6>
              <p className='m-0'>
                {stay} {`${duration}${stay > 1 ? 's' : ''}`}
              </p>
            </div>
          )}
        </div>
        {from ? (
          <div className='col-12 mb-4'>
            <h6 className='h-card-color'>REQUEST FROM</h6>
            <p className='text-capitalize m-0'>{from}</p>
          </div>
        ) : (
          <div className='col-12 mb-4'>
            <h6 className='h-card-color'>PROPERTY TYPE</h6>
            <p className='text-capitalize m-0'>{propertyType}</p>
          </div>
        )}
        <div className='col-12 mb-4'>
          <h6 className='h-card-color'>DATE ADDED</h6>
          {new Date(listed?.toLocaleString()).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </div>
        <div className='col-12 mb-4'>
          {otherImages?.length === 0 && (
            <div className='bg-warning d-flex align-items-center justify-content-between'>
              <span className='d-block p-2 '>Pending Images</span>
              <Link
                className='text-center  d-block p-2'
                to={`/listings/${
                  intent === 'long lease'
                    ? 'rental'
                    : intent === 'sale'
                    ? 'sales'
                    : intent
                }/?id=${id}`}
                state={{index: 6}}
              >
                Add Now
              </Link>
            </div>
          )}
        </div>
        <div className='col-12 mb-4'>
          <div
            className='col-8 d-flex flex-row justify-content-center align-items-center gap-2 p-2'
            style={{
              border: '1px solid #B1B7D6',
              borderRadius: '22px',
            }}
          >
            <i
              className={`fas fa-circle ${
                status ? 'text-success' : 'text-danger'
              }`}
              // style={{
              // 	color: `${
              // 		status === 'listed'
              // 			? '#3DDC97'
              // 			: status === 'review'
              // 			? '#EAB735'
              // 			: '#C6C6C6'
              // 	}`,
              // }}
            ></i>
            <p className='m-0 text-capitalize'>
              {status ? 'Published' : 'Pending'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
