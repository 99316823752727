// import image from '../../../assets/images/inner-pagebg.jpg';

import {Link} from 'react-router-dom';

const PageHero = ({img, currentPage}: any) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        height: 200,
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        objectFit: 'cover',
      }}
      className='page-hero position-relative '
    >
      <div className='container h-100 d-flex align-items-center '>
        <div className='row  ' style={{zIndex: '10'}}>
          <h2 className='d-flex text-white mt-3 fw-bolder'>
            {currentPage === 'faq'
              ? currentPage.toUpperCase() + 's'
              : currentPage}
          </h2>
          <div className='col-xl-6 d-flex '>
            <Link
              to='/'
              className='text-white no-decoration d-flex align-items-center '
            >
              <i className='fa fa-home mr-1 fs-12' aria-hidden='true'></i> Home
            </Link>
            <span className=' text-white ml-1 mr-1'> / </span>
            <span className='text-primary'>{currentPage}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHero;
