const TermsAndConditionData = () => {
  return (
    <div>
      <div className='container-fluid  mb-5'>
        <div className='container'>
          <div className=' mb-5'>
            <h1 className='fw-bolder text-primary'>Welcome to Krent</h1>
            <div className='d-flex  row'>
              {/* <p className=' col-md-9 col-lg-7'> */}
              <p className=' col-12 fw-bold'>
                This website is owned and operated by Krent. By accessing or
                using this website, you agree to be bound by these terms and
                conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className='mb-5 pb-5'>
        <div className='container'>
          {/* <div className='  d-flex flex-column  justify-content-center col-md-9 mx-auto'> */}
          <div className='  d-flex flex-column col-12 col-md-10'>
            <h6 className='fw-bold mb-2 text-primary'>Intellectual Property</h6>
            <p className='mb-2'>
              The content of this website, including without limitation text,
              graphics, logos, images, and software, is the property of Krent or
              its licensors and is protected by copyright and other intellectual
              property laws. You may not use, reproduce, distribute, or display
              any part of this website for any commercial or public purpose
              without our prior written consent.
            </p>
            <h6 className='text-primary mb-2'>Use of the Website</h6>
            <p className='fw-bold mb-2'>
              You may use this website only for lawful purposes and in
              accordance with these Terms. You agree not to use this website:
            </p>

            <ul className='terms-list'>
              <li className='mb-3'>
                To infringe the intellectual property rights of Krent or others.
              </li>
              <li className='mb-3'>
                To transmit or store illegal material, including without
                limitation material that is threatening, obscene, or defamatory.
              </li>
              <li className='mb-3'>
                To transmit or store material that contains viruses, Trojan
                horses, worms, or other malicious software.
              </li>
              <li className='mb-3'>
                To engage in any other conduct that is harmful to Krent or any
                other person or entity, or that would otherwise violate the law.
              </li>
            </ul>
            <p className='fw-bold mb-3'>
              We reserve the right to terminate or limit your access to this
              website if we believe that you have engaged in any prohibited
              conduct.
            </p>
            <h6 className='fw-bold text-primary mb-2'>
              Listings and Advertisements
            </h6>
            <p className='mb-3'>
              Krent provides a platform for real estate agents, developers, and
              other users to list properties for sale, rent, and short-term
              rental. The information contained in these listings and
              advertisements is provided by the users and is not verified or
              endorsed by Krent. We make no representations or warranties as to
              the accuracy or completeness of the information contained in these
              listings and advertisements.
            </p>
            <h6 className='fw-bold text-primary mb-2'>
              Disclaimer of Warranties
            </h6>
            <p className='mb-3'>
              THIS WEBSITE AND ITS CONTENT ARE PROVIDED “AS IS” AND WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST
              EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, KRENT DISCLAIMS ALL
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AND NON-INFRINGEMENT.
            </p>
            <h6 className='fw-bold mb-2 text-primary'>
              Limitation of Liability
            </h6>
            <p className='mb-3'>
              KRENT WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES
              OF ANY KIND, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT,
              INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, WHETHER IN
              CONTRACT, TORT, OR OTHERWISE, ARISING OUT OF YOUR USE OF THIS
              WEBSITE OR ITS CONTENT, OR YOUR INABILITY TO USE THIS WEBSITE OR
              ITS CONTENT.
            </p>
            <h6 className='fw-bold mb-2 text-primary'>Indemnification</h6>
            <p className='mb-3'>
              You agree to indemnify, defend, and hold Krent harmless from and
              against any claims, liabilities, damages, losses, and expenses,
              including without limitation reasonable attorney’s fees and costs,
              arising out of or in any way connected with your use of this
              website or your violation of these Terms.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermsAndConditionData;
