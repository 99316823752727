import {useSessionStorage} from '../../hooks';
import {DashboardLayout} from '@layouts/index';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getProfile} from '@services/krent.service';
import image from '../../assets/images/profile-pic.png';
import {Dropdown, Tooltip, Whisper} from 'rsuite';
import {Button} from 'react-bootstrap';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

export const Profile = () => {
  const {REACT_APP_API_BASE_URL} = process.env;
  const baseUrl = REACT_APP_API_BASE_URL?.includes('staging')
    ? 'https://staging.krent.space/'
    : 'https://krent.space/';
  const [user, setUser] = useSessionStorage('user', {});
  const [copied, setCopied] = useState(false);

  // console.log(user);
  const abortController = new AbortController();
  useEffect(() => {
    getProfile(abortController.signal).then((res: any) => {
      setUser(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`${baseUrl}properties/${user.id}`)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const renderIconButton = (props: any, ref: any) => {
    return (
      <Button
        {...props}
        {...ref}
        variant='primary'
        className='rounded-button py-2  align-self-end mt-3'
      >
        <i className='fa-regular fa-share-nodes fa-2x'></i>
        <small style={{fontSize: '10px'}} className='d-block'>
          Share your listings
        </small>
      </Button>
    );
  };

  return (
    <DashboardLayout>
      <div className='row mb-3'>
        <div className='d-flex justify-content-between'>
          <div>
            <h3 className='fw-bold mb-1'>My Profile</h3>
            <p className='text-secondary fs-14'>Manage your profile on Krent</p>
          </div>
          <div className='align-middle'>
            <Link
              to='/profile/edit/'
              className='btn btn-outline-dark py-2 px-4 rounded-pill fw-bolder'
            >
              Edit Profile
            </Link>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='card border-0 border-round shadow-sm'>
            <div className='card-body px-md-5 px-4'>
              <div className='row border-bottom mb-5 pb-0 pb-md-5'>
                <div className='col-12 mb-4 mb-md-0 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-md-3 gap-4'>
                    <div className='align-self-center'>
                      <img
                        src={user.photo !== 'default.jpg' ? user?.photo : image}
                        alt={user?.prefferedName}
                        style={{objectFit: 'cover'}}
                        height={80}
                        width={80}
                        className='rounded-circle'
                      />
                    </div>
                    <div className='align-self-center'>
                      <p className='fs-18 fw-bold mb-0 lh-1'>
                        {user?.name ||
                          user?.prefferedName ||
                          `${user?.firstname} ${user?.lastname}`}
                      </p>
                      <span className='fs-12'>{user?.email}</span>
                    </div>
                  </div>

                  <div className='d-flex flex-column'>
                    {(user.role == 'agent' || user.role == 'landlord') && (
                      <Dropdown
                        className='ml-auto'
                        renderToggle={renderIconButton}
                      >
                        <Whisper
                          placement='top'
                          trigger='click'
                          speaker={
                            <Tooltip>
                              {copied ? 'Copied to clipboard' : ''}
                            </Tooltip>
                          }
                        >
                          <Dropdown.Item
                            style={{fontSize: '12px'}}
                            onClick={() => handleCopyClick()}
                          >
                            <i className='fa-regular fa-copy text-primary'></i>{' '}
                            Copy Link
                          </Dropdown.Item>
                        </Whisper>

                        <Dropdown.Item>
                          <FacebookShareButton
                            url={`${baseUrl}properties/${user.id}`}
                          >
                            <FacebookIcon size={20} round /> Facebook
                          </FacebookShareButton>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <TwitterShareButton
                            url={`${baseUrl}properties/${user.id}`}
                          >
                            <TwitterIcon size={20} round /> Twitter
                          </TwitterShareButton>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <WhatsappShareButton
                            url={`${baseUrl}properties/${user.id}`}
                          >
                            <WhatsappIcon size={20} round /> Whatsapp
                          </WhatsappShareButton>
                        </Dropdown.Item>
                      </Dropdown>
                    )}
                  </div>
                </div>

                <div className=''></div>

                {/* <div className="col-12 col-md-7 py-4">
                                    <div className="row">
                                        <div className="col-4 ">
                                            <p className="fw-bolder text-uppercase fs-12 mb-1">Properties</p>
                                            <p className="fw-bolder fs-18">400</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="fw-bolder text-uppercase fs-12 mb-1">Reviews Recieved</p>
                                            <p className="fw-bolder fs-18">100</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="fw-bolder text-uppercase fs-12 mb-1">Average Review</p>
                                            <p className="fw-bolder fs-18">3.5</p>
                                        </div>
                                    </div>

                                </div> */}
              </div>

              <div className='row'>
                <div className='col-6 col-md-2 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1'>
                    Preffered name
                  </p>
                  <p className='fs-14'>{user?.preferredName || '-'}</p>
                </div>
                {/* <div className='col-6 col-md-3 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1'>Gender</p>
                  <p className='fs-14'>Male</p>
                </div> */}
                <div className='col-6 col-md-2 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1'>
                    Nationality
                  </p>
                  <p className='fs-14'>
                    {user?.nationality?.charAt(0).toUpperCase() +
                      user.nationality?.slice(1)}
                  </p>
                </div>
                <div className='col-6 col-md-2 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1 me-2'>
                    Phone number{' '}
                    {!user?.verified?.includes('phone') && (
                      <span>
                        <i className='iconly-Danger icli text-danger fs-14'></i>
                      </span>
                    )}{' '}
                  </p>
                  <p className='fs-14'>{user?.phone}</p>
                </div>
                {/* <div className='col-6 col-md-3 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1'>
                    Government id
                  </p>
                  <p className='fs-14'>Voter's Card</p>
                </div> */}
                <div className='col-6 col-md-2 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1'>Role</p>
                  <p className='fs-14'>
                    {user?.role?.charAt(0).toUpperCase() + user?.role?.slice(1)}
                  </p>
                </div>
                <div className='col-6 col-md-2 mb-4'>
                  <p className='fw-bolder text-uppercase fs-12 mb-1'>
                    Language
                  </p>
                  <p className='fs-14'>
                    {user?.language?.charAt(0).toUpperCase() +
                      user?.language?.slice(1)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
