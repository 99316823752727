import React from 'react';

const KrentAddress = () => {
  return (
    <div className='w-100 h-100'>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.722079071393!2d3.489267214779864!3d6.429738626030003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5d0386dba51%3A0xa6d642b809a10f26!2sBayview%20Estate%20LSDPC-MISA%20Property%20Development!5e0!3m2!1sen!2sng!4v1675780085923!5m2!1sen!2sng'
        width='100%'
        height='100%'
        title='krent address'
        style={{ border: '0' }}
        loading='lazy'
      ></iframe>
    </div>
  );
};

export default KrentAddress;
