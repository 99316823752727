import { Overview } from './Overview';

export const Blank = () => {
	return (
		<div
			style={{
				backgroundColor: '#F4F4F4',
				height: '100vh',
				overflowX: 'hidden',
			}}
		>
			<div className='col'>
				<Overview />
			</div>
		</div>
	);
};